import { useEffect, useState } from 'react';

import { getOrderItems, getOrders, getSellerOrders } from 'api/order.api';
import {
  APIMarketplaceOrder,
  APIOrderItem,
  APISellerOrder,
} from 'api/orders.types';
import { useParams } from 'react-router-dom';
import { SpacedContainer } from 'revibe-ui';
import { ROUTES } from 'router';

import {
  orderItemStatusToString,
  orderStatusToString,
  sellerOrderStatusToString,
} from 'modules/orders/utils/orderStatusToString';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory, useQueryStringParameters } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';
import { useToast } from 'shared/hooks';
import { getDynamicRouteUrl } from 'shared/utils/routes';

import {
  OrderItemsTable,
  OrdersTable,
  SellerOrdersTable,
} from '../components/OrdersTable';

export const OrdersStatusPage = () => {
  const { status } = useParams<{ status: string; bySuborder: string }>();
  const query = useQueryStringParameters();
  const type = query.get('type') || 'group';
  const history = useHistory();
  const [orders, setOrders] = useState<APIMarketplaceOrder[]>([]);
  const [sellerOrders, setSellerOrders] = useState<APISellerOrder[]>([]);
  const [orderItems, setOrderItems] = useState<
    (APIOrderItem & { order: APISellerOrder })[]
  >([]);
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { errorToast } = useToast();
  const fetchOrders = async () => {
    startFetching();
    if (type === 'seller') {
      const { data, error } = await getSellerOrders(
        status !== 'All' ? status : undefined
      );
      if (data) {
        setSellerOrders(data);
      } else {
        errorToast(error);
      }
    } else if (type === 'item') {
      const { data, error } = await getOrderItems(
        status !== 'All' ? status : undefined
      );
      if (data) {
        setOrderItems(data);
      } else {
        errorToast(error);
      }
    } else {
      const { data, error } = await getOrders(
        status !== 'All' ? status : undefined
      );
      if (data) {
        setOrders(data);
      } else {
        errorToast(error);
      }
    }
    stopFetching();
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderActions = [
    {
      label: 'Order details',
      onClick: (order: APIMarketplaceOrder) =>
        history.push(
          getDynamicRouteUrl(ROUTES.ORDER, {
            id: order.id,
          })
        ),
    },
  ];
  const sellerOrderActions = [
    {
      label: 'Order details',
      onClick: (order: APISellerOrder) =>
        history.push(
          getDynamicRouteUrl(ROUTES.ORDER, {
            id: order.group,
          })
        ),
    },
  ];

  const getStatusFromType = () => {
    if (type === 'seller') {
      return `Seller order status: ${sellerOrderStatusToString(status)}`;
    }
    if (type === 'group') {
      return `User order status: ${orderStatusToString(status)}`;
    }
    if (type === 'item') {
      return `Product tracking status: ${orderItemStatusToString(status)}`;
    }
    return 'All';
  };

  return (
    <LoggedInPage
      hideOverflow
      title="Orders Status"
      breadcrumbs={[
        {
          label: 'Orders',
          to: ROUTES.ORDERS,
        },
        {
          label: status === 'All' ? status : getStatusFromType(),
        },
      ]}
    >
      <SpacedContainer>
        <PageHeading>
          {status === 'All' ? status : getStatusFromType()}
        </PageHeading>

        {type === 'seller' && (
          <SellerOrdersTable
            orders={sellerOrders}
            isLoading={isFetching}
            actions={sellerOrderActions}
          />
        )}
        {type === 'item' && (
          <OrderItemsTable
            items={orderItems}
            isLoading={isFetching}
            actions={sellerOrderActions}
          />
        )}
        {type === 'group' && (
          <OrdersTable
            orders={orders}
            isLoading={isFetching}
            actions={orderActions}
          />
        )}
      </SpacedContainer>
    </LoggedInPage>
  );
};
