import { useEffect, useState } from 'react';

import { getItems } from 'api/items.api';
import { APIItem, ItemFilters } from 'api/items.types';
import { useAuthentication } from 'auth/hooks';
import { Button, SpacedContainer, Spinner } from 'revibe-ui';
import { SHOP_ROUTES } from 'router';

import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';

import { useHistory, useToast } from 'shared/hooks';
import { convertObjectToQueryParams } from 'shared/utils';
import { useBoolean } from 'revibe-ui';

const PAGE_SIZE = 48;

type Props = {
  filters?: ItemFilters;
  maxPages?: number;
  resetToken?: string;
  pageSize?: number;
  onItemsLoaded?: (items: APIItem[], itemsCount: number) => void;
  renderItems: (items: APIItem[]) => JSX.Element;
};

export const ItemsGrid = ({
  filters = {},
  maxPages = 0,
  resetToken = '',
  pageSize = PAGE_SIZE,
  onItemsLoaded = () => {},
  renderItems,
}: Props) => {
  const history = useHistory();
  const { errorToast } = useToast();
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const { userID } = useAuthentication();
  const [items, setItems] = useState<APIItem[]>([]);
  const hasReachedMax = maxPages > 0 && page >= maxPages;
  const userFilters = {
    ...filters,
    [ITEM_FILTERS.USER]: userID,
    [ITEM_FILTERS.INCLUDE_UNRELEASED_COLLECTIONS]: true,
  };
  const fetchPage = async (isReset = false) => {
    startFetching();
    const { data, error } = await getItems(
      userFilters,
      isReset ? 1 : page + 1,
      pageSize
    );
    if (data) {
      setPage(data.currentPage);
      setHasMore(
        Boolean(
          data.res.length > 0 &&
            data.res.length === pageSize &&
            data.currentPage < data.totalPages
        )
      );
      const newItems = isReset ? data.res : [...items, ...data.res];
      setItems(newItems);
      onItemsLoaded(
        newItems,
        data.res.length > pageSize ? data.totalItems : data.res.length
      );
    } else {
      errorToast(error);
    }
    stopFetching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToken]);

  return (
    <SpacedContainer>
      {isFetching && !items.length ? (
        <div className="mx-auto">
          <Spinner />
        </div>
      ) : (
        renderItems(items)
      )}
      {hasMore && !hasReachedMax ? (
        <Button
          className="mx-auto"
          isLoading={isFetching}
          disabled={isFetching}
          onClick={() => fetchPage(false)}
        >
          Load more
        </Button>
      ) : null}
      {hasReachedMax && (
        <Button
          className="mx-auto max-w-[360px]"
          isLoading={isFetching}
          disabled={isFetching}
          onClick={() => {
            const url = `${SHOP_ROUTES.ITEMS}?${convertObjectToQueryParams(
              filters
            )}`;
            history.pushFresh(url);
          }}
        >
          See all
        </Button>
      )}
    </SpacedContainer>
  );
};
