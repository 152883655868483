import { APISellerOrder } from 'api/orders.types';
import { SpacedContainer } from 'revibe-ui';

import { formatPrice } from 'modules/catalog/utils/formatPrice';

import { PageHeading } from 'shared/components/PageHeading';
import { useCountries } from 'shared/hooks';

import { OrderSellerStatusManager } from './OrderSellerStatusManager';
import { OrderItem } from './OrderItem';

type Props = {
  sellerOrder: APISellerOrder;
  marketplaceOrderStatus: string;
  onUpdate: () => void;
};

export const SellerOrder = ({ sellerOrder, onUpdate }: Props) => {
  const { getCountryByCode } = useCountries();

  return (
    <div className="bg-gray-50 p-4">
      <SpacedContainer>
        <PageHeading>{sellerOrder.seller.name}</PageHeading>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <OrderSellerStatusManager order={sellerOrder} onUpdate={onUpdate} />
          <div className="rounded-md bg-white p-4 sm:p-6 lg:p-8">
            <h1 className="text-purple mb-6 text-lg font-bold">
              SELLER INFORMATION
            </h1>
            <SpacedContainer spacing="small">
              <p className="font-bold">
                Email:{' '}
                <span className="text-purple font-normal">
                  {sellerOrder.seller.email}
                </span>
              </p>
              <p className="font-bold">
                Shipping from:{' '}
                <span className="text-purple font-normal">
                  {getCountryByCode(sellerOrder.seller.country)}
                </span>
              </p>
              <p className="font-bold">
                Order subtotal:{' '}
                <span className="text-purple font-normal">
                  {formatPrice(sellerOrder.subtotal)} &euro;
                </span>
              </p>
            </SpacedContainer>
          </div>
        </div>
        <h2 className="text-purple mt-6 text-lg font-bold">Products</h2>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {sellerOrder.items.map((item) => (
            <OrderItem
              key={`${item.id} ${item.size}`}
              item={item}
              order={sellerOrder}
              onUpdate={onUpdate}
            />
          ))}
        </div>
      </SpacedContainer>
    </div>
  );
};
