import { PaginationResponse } from './pagination.types';
import {
  APIItemSelection,
  ItemSelectionPhoto,
  ItemSelectionPhotoUpdate,
} from './selections.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getItemSelection = async (selectionID: string) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/selections/${selectionID}`,
    method: 'GET',
    reshaper: (res: APIItemSelection) => res,
  });
};

export const getItemSelections = async () => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/selections`,
    method: 'GET',
    reshaper: (res: PaginationResponse<APIItemSelection>) => res.res,
  });
};

export const createItemSelection = async (
  selection: Partial<APIItemSelection>
) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/selections`,
    body: selection,
    method: 'POST',
    reshaper: (res: APIItemSelection) => res,
  });
};

export const editItemSelection = async (
  selectionID: string,
  selection: Partial<APIItemSelection>,
  items: { id: string; position: number }[] | undefined
) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/selections/${selectionID}`,
    body: {
      ...selection,
      items,
    },
    method: 'PUT',
    reshaper: (res: APIItemSelection) => res,
  });
};

export const addItemSelectionPhotos = async (
  selectionID: string,
  photos: File[]
) => {
  const data = new FormData();
  photos.forEach((f) => data.append('files', f));
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/selections/${selectionID}/photos`,
    method: 'POST',
    body: data,
    rawBody: true,
    reshaper: (res: ItemSelectionPhoto[]) => res,
  });
};

export const updateItemSelectionPhoto = async (
  selectionID: string,
  photoID: string,
  update: ItemSelectionPhotoUpdate
) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/selections/${selectionID}/photos/${photoID}`,
    method: 'PUT',
    body: update,
    reshaper: (res: any) => res,
  });

export const deleteItemSelectionPhoto = async (
  selectionID: string,
  photoID: string
) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/selections/${selectionID}/photos/${photoID}`,
    method: 'DELETE',
    reshaper: (res: any) => res,
  });
