import { useState } from 'react';

import { APIItem } from 'api/items.types';
import { editItemSelection } from 'api/selections.api';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { Button, SpacedContainer } from 'revibe-ui';

import { ItemPreview } from 'modules/catalog/components/ItemPreview';

import { useHistory, useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

type Props = {
  selectionID: string;
  items: APIItem[];
};

export const ItemsSelectionDraggableGrid = ({
  selectionID,
  items: itms,
}: Props) => {
  const { toast } = useToast();
  const [isUpdating, startUpdating, stopUpdating] = useBoolean();
  const [items, setItems] = useState<APIItem[]>(itms);
  const history = useHistory();
  const reorder = (list: APIItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const newOrderedItems = reorder(items, source.index, destination.index);

    setItems(newOrderedItems);
  };
  const handleSave = async () => {
    startUpdating();

    await editItemSelection(
      selectionID,
      {},
      items.map((i, index) => ({ id: i.id, position: index }))
    );
    toast(`Items order saved`);
    stopUpdating();
    history.go(0);
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={() => console.log('drag start')}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <SpacedContainer>
              <Button
                type="button"
                onClick={handleSave}
                disabled={isUpdating}
                isLoading={isUpdating}
              >
                Save configuration
              </Button>
              <SpacedContainer>
                {items.map((item, index) => (
                  <Draggable
                    key={String(item.id)}
                    draggableId={String(item.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ItemPreview
                          index={index}
                          hideRating
                          item={item}
                          onItemIndexChanged={(oi, ni) =>
                            onDragEnd({
                              destination: {
                                index: ni,
                                droppableId: String(item.id),
                              },
                              source: {
                                index: oi,
                                droppableId: String(item.id),
                              },
                            } as DropResult)
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </SpacedContainer>
            </SpacedContainer>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
