import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { cn } from '../helpers/classnames';
import { Button } from './Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './Dropdown';

interface Props {
  isLoading?: boolean;
  mainAction: {
    label: string;
    onClick: () => void;
  };
  secondaryActions: {
    label: string;
    onClick: () => void;
  }[];
}

export const MultiButton = ({
  mainAction,
  secondaryActions,
  isLoading = false,
}: Props) => {
  return (
    <div className="relative inline-flex rounded-full">
      <Button
        variant="subtle"
        className={cn(secondaryActions.length > 0 && 'rounded-r-none')}
        onClick={mainAction.onClick}
        isLoading={isLoading}
      >
        {mainAction.label}
      </Button>
      {secondaryActions.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              variant="subtle"
              className="relative inline-flex h-10 w-fit items-center justify-center rounded-l-none border-l border-slate-50 bg-slate-100 py-2 pr-4 pl-3 text-sm font-medium text-slate-900 transition-colors hover:bg-slate-200 focus:z-10 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=open]:bg-slate-100"
            >
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {secondaryActions.map((secondaryAction) => (
              <DropdownMenuItem
                key={secondaryAction.label}
                onClick={secondaryAction.onClick}
              >
                {secondaryAction.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
