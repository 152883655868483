import { useState } from 'react';

import { updateItemPhoto } from 'api/items.api';
import { APIItem, ItemPhoto, ItemPhotoUpdate } from 'api/items.types';
import { useAuthentication } from 'auth/hooks';
import { useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import {
  Button,
  FormControl,
  Image,
  Drawer,
  MultiButton,
  Separator,
  SpacedContainer,
  Tag,
} from 'revibe-ui';
import { ROUTES } from 'routes';

import { DownloadIcon } from '@heroicons/react/outline';

import { ItemsGrid, NoItems } from 'modules/catalog/components';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';
import { SellerSelector } from 'modules/sellers/components/SellerSelector';
import { useUser } from 'modules/users/hooks';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory, useQueryStringParameters } from 'shared/hooks';
import { useToken } from 'shared/hooks/useToken';
import { convertObjectToQueryParams } from 'shared/utils';
import { downloadImage } from 'shared/utils/downloadImage';
import { ObjectType } from 'shared/utils/types';
import { urlToFile } from 'shared/utils/urlToFile';

import { CategorySelector } from '../components/CategorySelector';
import { ItemPhotoPreview } from '../components/ItemPhotoPreview';

const removeBgAndDownload = async (photo: ItemPhoto, itemName: string) => {
  const photoName = `${itemName} - ${photo.order}`;

  toast.promise(
    async () => {
      const file = await urlToFile(photo.link, photoName);
      const formData = new FormData();
      formData.append('image_file', file);

      const response = await fetch('https://sdk.photoroom.com/v1/segment', {
        method: 'POST',
        headers: {
          'x-api-key': '5f298a42ce26255bd27bbd00272a4964d791e7b9',
        },
        body: formData,
      });

      const outputBlob = await response.blob();
      const src = URL.createObjectURL(outputBlob);
      return downloadImage(src, `${photoName} (no-bg)`);
    },
    {
      loading: `Removing background from ${photoName}`,
      success: `Success! Downloading`,
      error: `Error removing background from photo ${photoName}`,
    }
  );
};

export const ItemsPhotosPage = () => {
  const history = useHistory();
  const { userID } = useAuthentication();
  const [selectedItem, setSelectedItem] = useState<APIItem | null>(null);
  const query = useQueryStringParameters();
  const filtersToken = useLocation().search;
  let filters: ObjectType<any> = {};
  const [resetToken, toggleResetToken] = useToken();
  const { user } = useUser();
  for (const pair of query.entries()) {
    filters[pair[0]] = pair[1];
  }

  const handleItemSelected = (item: APIItem) => {
    setSelectedItem(item);
  };

  const updatePhoto = async (
    photoID: string,
    itemID: string,
    updateValues: ItemPhotoUpdate
  ) => {
    toast.promise(
      async () => {
        const { error } = await updateItemPhoto(itemID, photoID, updateValues);

        if (error) {
          throw new Error();
        }
        setSelectedItem(null);
        toggleResetToken();
      },
      {
        loading: `Updating photo`,
        success: `Photo updated`,
        error: `Error updating photo`,
      }
    );
  };

  const renderItems = (items: APIItem[]) =>
    items.length ? (
      <div className="grid grid-cols-1 gap-y-10 gap-x-4 md:grid-cols-2 lg:grid-cols-4">
        {items.map((item) => (
          <ItemPhotoPreview
            key={item.id}
            item={item}
            onClick={handleItemSelected}
          />
        ))}
      </div>
    ) : (
      <NoItems />
    );

  if (!userID) {
    return null;
  }

  return (
    <LoggedInPage title="Items">
      <SpacedContainer>
        <PageHeading>Photos management</PageHeading>
        <div className="w-full">
          <SpacedContainer className="relative">
            <SpacedContainer>
              <div className="mt-8 bg-gray-50 p-4">
                <SpacedContainer>
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <FormControl label="Seller">
                      <SellerSelector
                        initialSelectedSeller={
                          filters[ITEM_FILTERS.SELLER] || null
                        }
                        onChange={(seller) => {
                          const url = `${
                            ROUTES.ITEMS_PHOTOS
                          }?${convertObjectToQueryParams({
                            ...filters,
                            [ITEM_FILTERS.SELLER]: seller?.id,
                          })}`;
                          history.push(url);
                        }}
                      />
                    </FormControl>
                    <FormControl label="Category">
                      <CategorySelector
                        initialSelectedCategory={
                          filters[ITEM_FILTERS.CATEGORY] || null
                        }
                        onChange={(cat) => {
                          const url = `${
                            ROUTES.ITEMS_PHOTOS
                          }?${convertObjectToQueryParams({
                            ...filters,
                            [ITEM_FILTERS.CATEGORY]: cat?.slug,
                          })}`;
                          history.push(url);
                        }}
                      />
                    </FormControl>
                  </div>
                </SpacedContainer>
              </div>
              <div className="mt-8 grid gap-1">
                <ItemsGrid
                  filters={{
                    ...filters,
                    [ITEM_FILTERS.STATUS]:
                      Object.values(ITEM_STATUSES).join(','),
                    [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                    [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                  }}
                  renderItems={renderItems}
                  pageSize={24}
                  resetToken={filtersToken}
                  key={resetToken}
                />
              </div>
            </SpacedContainer>
          </SpacedContainer>
        </div>
      </SpacedContainer>
      <Drawer
        scrollable
        open={selectedItem !== null}
        onOpenChange={() => setSelectedItem(null)}
        position="bottom"
        size="full"
      >
        <SpacedContainer className="p-4">
          <PageHeading>{selectedItem?.name}</PageHeading>
          <SpacedContainer type="horizontal" centered fullWidth>
            <Button
              variant="subtle"
              onClick={() => {
                const url = `${
                  process.env.REACT_APP_SELLER_WEBSITE_URL
                }/admin-session?sellerID=${
                  selectedItem?.seller.id
                }&shouldForceLogin=true&sellerName=${
                  selectedItem?.seller.name
                }&adminEmail=${user?.email || ''}&redirect=${encodeURIComponent(
                  `${process.env.REACT_APP_SELLER_WEBSITE_URL}/items/${selectedItem?.id}`
                )}`;

                history.pushBlank(url);
              }}
            >
              Edit photos in seller app
            </Button>
            <Button
              variant="subtle"
              onClick={() => {
                selectedItem?.photos.forEach((photo) =>
                  downloadImage(
                    photo.link,
                    `${selectedItem.name} - ${photo.order || 0 + 1}`
                  )
                );
              }}
            >
              <DownloadIcon /> Download all images
            </Button>
            <Button
              variant="subtle"
              onClick={() => {
                selectedItem?.photos.forEach((photo) =>
                  removeBgAndDownload(photo, selectedItem.name)
                );
              }}
            >
              <DownloadIcon /> Download all images without background
            </Button>
            <div className="h-fit ">
              <img
                onClick={() => history.pushBlank('https://photoroom.com/')}
                className="h-12 cursor-pointer"
                alt="Photoroom"
                src="https://res.cloudinary.com/revibe/image/upload/v1655739276/Seller%20App/PhotoRoom_API_Attribution_Default_English_wacknz.svg"
              />
            </div>
          </SpacedContainer>
          <Separator />
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
            {selectedItem?.photos.map((photo, i) => (
              <div
                className="relative rounded-lg bg-gray-50 p-2"
                key={photo.id}
              >
                <div className="ml-auto w-fit pb-2">
                  <MultiButton
                    mainAction={{
                      label: 'Download image',
                      onClick: () =>
                        downloadImage(
                          photo.link,
                          `${selectedItem.name} - ${i + 1}`
                        ),
                    }}
                    secondaryActions={[
                      {
                        label: 'Download image without background',
                        onClick: () =>
                          removeBgAndDownload(photo, selectedItem.name),
                      },
                      {
                        label: photo.is_retouched
                          ? 'Mark image as NOT RETOUCHED'
                          : 'Mark image as RETOUCHED',
                        onClick: () =>
                          photo.is_retouched
                            ? updatePhoto(photo.id, selectedItem.id, {
                                is_retouched: false,
                              })
                            : updatePhoto(photo.id, selectedItem.id, {
                                is_retouched: true,
                              }),
                      },
                      {
                        label: 'Edit photo',
                        onClick: () => {
                          const url = `${
                            process.env.REACT_APP_SELLER_WEBSITE_URL
                          }/admin-session?sellerID=${
                            selectedItem.seller.id
                          }&shouldForceLogin=true&sellerName=${
                            selectedItem.seller.name
                          }&adminEmail=${
                            user?.email || ''
                          }&redirect=${encodeURIComponent(
                            `${process.env.REACT_APP_SELLER_WEBSITE_URL}/items/${selectedItem.id}`
                          )}`;

                          history.pushBlank(url);
                        },
                      },
                    ]}
                  />
                </div>
                <Image src={photo.medium_link} alt={selectedItem.name} />
                <SpacedContainer className="absolute left-0 top-12 p-4 ">
                  <SpacedContainer type="horizontal">
                    <Tag className="shadow-lg">{i + 1}</Tag>
                    {photo.is_retouched && (
                      <Tag className="shadow-lg">Retouched</Tag>
                    )}
                  </SpacedContainer>
                </SpacedContainer>
              </div>
            ))}
          </div>
        </SpacedContainer>
      </Drawer>
    </LoggedInPage>
  );
};
