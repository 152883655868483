import React, { useEffect, useMemo, useState } from 'react';

import { useBoolean } from '../../hooks';
import { SpacedContainer } from '../SpacedContainer';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableContainer } from './TableContainer';
import { TableHead } from './TableHead';
import { TableHeadCell } from './TableHeadCell';
import { TablePagination } from './TablePagination';
import { TableRow } from './TableRow';

type Props = {
  className?: string;
  columns: string[];
  data: React.ReactNode[][];
  fetchPage: (page: number) => Promise<any>;
  initialPage: number;
  pageSize: number;
  pagesCount: number;
  refetchToggle: boolean;
};

export const PaginatedTable = ({
  columns,
  data,
  pageSize,
  initialPage,
  pagesCount,
  refetchToggle,
  fetchPage,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [isFetchingPage, startFetchingPage, stopFetchingPage] = useBoolean();
  const handlePageFetch = async () => {
    startFetchingPage();
    await fetchPage(currentPage);
    stopFetchingPage();
  };
  useEffect(() => {
    handlePageFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(initialPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPage]);
  useEffect(() => {
    handlePageFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchToggle]);

  const placeHolderRows = useMemo(() => {
    const placeholders: React.ReactNode[][] = [];
    for (let i = 0; i < pageSize; i++) {
      placeholders.push(
        columns.map(() => (
          <div
            data-placeholder
            className="relative mb-2 h-5 w-40 overflow-hidden bg-gray-200"
          />
        ))
      );
    }
    return placeholders;
  }, [columns, pageSize]);

  return (
    <SpacedContainer>
      <TableContainer>
        <TableHead>
          <TableRow>
            {columns.map((c, i) => (
              <TableHeadCell key={c + i}>{c}</TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetchingPage
            ? placeHolderRows.map((r, i) => (
                <TableRow key={i}>
                  {r.map((e, i) => (
                    <TableCell key={i}>{e}</TableCell>
                  ))}
                </TableRow>
              ))
            : data.map((r, i) => (
                <TableRow key={i} divider="noborder">
                  {r.map((e, i) => (
                    <TableCell key={i}>{e}</TableCell>
                  ))}
                </TableRow>
              ))}
        </TableBody>
      </TableContainer>
      <TablePagination
        currentPage={currentPage}
        totalPages={pagesCount}
        onPageChange={setCurrentPage}
      />
    </SpacedContainer>
  );
};
