import { useEffect, useState } from 'react';

import { getItems } from 'api/items.api';
import { APIItem, ItemFilters } from 'api/items.types';
import { useAuthentication } from 'auth/hooks';
import {
  Button,
  Image,
  Input,
  SpacedContainer,
  Spinner,
  Table,
  Tag,
} from 'revibe-ui';

import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { getIsItemInStock } from 'modules/catalog/utils/itemStock';

import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

import { ItemPrice, NoItems } from '.';

type Props = {
  filters?: ItemFilters;
  resetToken?: string;
  onItemsLoaded?: (items: APIItem[], itemsCount: number) => void;
  onItemSelected?: (item: APIItem | null) => void;
  onItemDeselected?: (item: APIItem) => void;
  actionLabel?: string;
};

type StaticProps = {
  items: APIItem[];
  onItemsLoaded?: (items: APIItem[], itemsCount: number) => void;
  onItemSelected?: (item: APIItem | null) => void;
  onItemDeselected?: (item: APIItem) => void;
  actionLabel?: string;
};

export const ItemsTable = ({
  filters = {},
  resetToken = '',
  onItemsLoaded = () => {},
  onItemSelected,
  actionLabel,
}: Props) => {
  const { errorToast } = useToast();
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { userID } = useAuthentication();
  const [search, setSearch] = useState('');
  const [items, setItems] = useState<APIItem[]>([]);
  const userFilters = {
    ...filters,
    [ITEM_FILTERS.USER]: userID,
    [ITEM_FILTERS.INCLUDE_UNRELEASED_COLLECTIONS]: true,
    [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
    [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
  };
  const columns = ['', 'Name', 'Photo', 'Category', 'Sex', 'Price', ''];
  let rows: any[] = items.map((item) => [
    <Button
      variant="subtle"
      onClick={() => {
        onItemSelected && onItemSelected(item);
      }}
    >
      {actionLabel || 'Select'}
    </Button>,
    item.name,
    Array.isArray(item.photos) && item.photos.length > 0 ? (
      <Image w={32} src={item.photos[0].mini_link} alt={item.photos[0].id} />
    ) : null,
    item.category.slug,
    item.sex,
    <ItemPrice
      key={item.id}
      price={item.price}
      sellingPrice={item.selling_price}
    />,
    <SpacedContainer key={item.id} spacing="small">
      {!getIsItemInStock(item.stock) && <Tag color="red">Out of stock</Tag>}
      {item.available_for_preorder && <Tag>In made to order</Tag>}
      {Number(
        (
          ((item.price - (item.selling_price || item.price)) / item.price) *
          100
        ).toFixed(2)
      ) > 0 && <Tag>discount</Tag>}
    </SpacedContainer>,
  ]);

  if (!onItemSelected) {
    columns.shift();
    rows = rows.map((r) => r.slice(1));
  }
  const fetchPage = async (isReset = false) => {
    startFetching();
    const { data, error } = await getItems(userFilters);
    if (data) {
      const newItems = isReset ? data.res : [...items, ...data.res];
      setItems(newItems);
      onItemsLoaded(newItems, data.res.length);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToken]);

  return (
    <div>
      {isFetching ? (
        <Spinner />
      ) : items.length > 0 ? (
        <SpacedContainer>
          <Input
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Search"
          />
          <Table columns={columns} data={rows} />
        </SpacedContainer>
      ) : (
        <NoItems />
      )}
    </div>
  );
};

export const ItemsStaticTable = ({
  items,
  onItemSelected,
  actionLabel,
}: StaticProps) => {
  const [search, setSearch] = useState('');
  const columns = ['', 'Name', 'Photo', 'Category', 'Sex', 'Price', ''];
  let rows: any[] = items.map((item) => [
    <Button
      variant="subtle"
      onClick={() => {
        onItemSelected && onItemSelected(item);
      }}
    >
      {actionLabel || 'Select'}
    </Button>,
    item.name,
    Array.isArray(item.photos) && item.photos.length > 0 ? (
      <Image w={32} src={item.photos[0].mini_link} alt={item.photos[0].id} />
    ) : null,
    item.category.slug,
    item.sex,
    <ItemPrice
      key={item.id}
      price={item.price}
      sellingPrice={item.selling_price}
    />,
    <SpacedContainer key={item.id} spacing="small">
      {!getIsItemInStock(item.stock) && <Tag color="red">Out of stock</Tag>}
      {item.available_for_preorder && <Tag>In made to order</Tag>}
      {Number(
        (
          ((item.price - (item.selling_price || item.price)) / item.price) *
          100
        ).toFixed(2)
      ) > 0 && <Tag>discount</Tag>}
    </SpacedContainer>,
  ]);

  if (!onItemSelected) {
    columns.shift();
    rows = rows.map((r) => r.slice(1));
  }

  return (
    <div>
      {items.length > 0 ? (
        <SpacedContainer>
          <Input
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Search"
          />
          <Table columns={columns} data={rows} />
        </SpacedContainer>
      ) : (
        <NoItems />
      )}
    </div>
  );
};
