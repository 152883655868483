import { useCallback, useMemo } from 'react';

import { updateItem } from 'api/items.api';
import { APIItem } from 'api/items.types';
import { MultiButton } from 'revibe-ui';

import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

type Props = {
  status: string;
  items: APIItem[];
  onUpdate: (status: string) => void;
};

export const ItemStatusUpdater = ({ status, items, onUpdate }: Props) => {
  const [isUpdating, startUpdating, stopUpdating] = useBoolean();
  const { errorToast, toast } = useToast();
  const handleItemStatusApply = useCallback(
    async (newStatus: string, items: APIItem[]) => {
      startUpdating();
      await Promise.all(
        items.map(async (item) => {
          const { error } = await updateItem(item.id, {
            status: newStatus,
          });
          if (error) {
            errorToast(`item id ${item.id}: status update failed`);
          }
        })
      );
      toast(`Updated ${items.length} items`);
      onUpdate(newStatus);
      stopUpdating();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const mainAction = useMemo(() => {
    switch (status) {
      case ITEM_STATUSES.PENDING_APPROVAL:
        return {
          label: 'Approve',
          onClick: () => handleItemStatusApply(ITEM_STATUSES.APPROVED, items),
        };
      case ITEM_STATUSES.APPROVED:
        return {
          label: 'Request changes',
          onClick: () => handleItemStatusApply(ITEM_STATUSES.REJECTED, items),
        };
      case ITEM_STATUSES.REJECTED:
        return {
          label: 'Approve',
          onClick: () => handleItemStatusApply(ITEM_STATUSES.APPROVED, items),
        };
      case ITEM_STATUSES.ARCHIVED:
        return {
          label: 'Unarchive (back to pending approval)',
          onClick: () =>
            handleItemStatusApply(ITEM_STATUSES.PENDING_APPROVAL, items),
        };
      default:
        return {
          label: 'Approve',
          onClick: () => handleItemStatusApply(ITEM_STATUSES.APPROVED, items),
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, items]);

  const secondaryActions = useMemo(() => {
    switch (status) {
      case ITEM_STATUSES.PENDING_APPROVAL:
        return [
          {
            label: 'Request changes (Reject)',
            onClick: () => handleItemStatusApply(ITEM_STATUSES.REJECTED, items),
          },
          {
            label: 'Archive',
            onClick: () => handleItemStatusApply(ITEM_STATUSES.ARCHIVED, items),
          },
        ];
      case ITEM_STATUSES.APPROVED:
        return [
          {
            label: 'Send back to pending approval',
            onClick: () =>
              handleItemStatusApply(ITEM_STATUSES.PENDING_APPROVAL, items),
          },
          {
            label: 'Archive',
            onClick: () => handleItemStatusApply(ITEM_STATUSES.ARCHIVED, items),
          },
        ];
      case ITEM_STATUSES.REJECTED:
        return [
          {
            label: 'Send back to pending approval',
            onClick: () =>
              handleItemStatusApply(ITEM_STATUSES.PENDING_APPROVAL, items),
          },
          {
            label: 'Archive',
            onClick: () => handleItemStatusApply(ITEM_STATUSES.ARCHIVED, items),
          },
        ];
      case ITEM_STATUSES.ARCHIVED:
        return [];
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, items]);

  return (
    <MultiButton
      isLoading={isUpdating}
      mainAction={mainAction}
      secondaryActions={secondaryActions}
    />
  );
};
