import { useState } from 'react';

import { getSellers, useQuery } from 'revibe-api';
import { Input, MultiButton, SpacedContainer, Table, Tag } from 'revibe-ui';
import { ROUTES } from 'routes';

import { useUser } from 'modules/users/hooks';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { SectionHeading } from 'shared/components/SectionHeading';
import { useHistory } from 'shared/hooks';
import { useToast } from 'shared/hooks';

export const SellersPage = () => {
  const { errorToast } = useToast();
  const [{ res: sellers }, { isLoading }] = useQuery(
    'sellers',
    () => getSellers({ sortByOnboardingDate: true }),
    {
      defaultValue: { res: [], totalItems: 0, totalPages: 0, currentPage: 1 },
      onError: errorToast,
    }
  );

  const history = useHistory();
  const { user } = useUser();
  const [searchQuery, setSearchQuery] = useState('');
  const loginAsSeller = (sellerID: string, sellerName: string) => {
    history.pushBlank(
      `${
        process.env.REACT_APP_SELLER_WEBSITE_URL
      }/admin-session?sellerID=${sellerID}&sellerName=${sellerName}&adminEmail=${
        user?.email || ''
      }`
    );
  };
  const selectSeller = (sellerID: string) => {
    history.pushFresh(`sellers/${sellerID}`);
  };

  const rows = sellers
    .filter((seller) => {
      if (!searchQuery) {
        return true;
      }

      return (
        seller.name
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase()) ||
        seller.email
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
      );
    })
    .map((seller) => [
      seller.name,
      seller.stripe_account_active ? (
        <Tag color="green">Complete</Tag>
      ) : (
        <Tag color="red">Incomplete</Tag>
      ),
      seller.account_created_on,
      <MultiButton
        mainAction={{
          label: 'Login as',
          onClick: () => loginAsSeller(seller.id, seller.name),
        }}
        secondaryActions={[
          {
            label: 'Manage translations',
            onClick: () => selectSeller(seller.id),
          },
          {
            label: 'Change seller hero image',
            onClick: () => selectSeller(seller.id),
          },
        ]}
      />,
    ]);

  return (
    <LoggedInPage
      hideOverflow
      title="Sellers"
      breadcrumbs={[
        {
          label: 'Sellers',
          to: ROUTES.SELLERS,
        },
      ]}
    >
      <SpacedContainer>
        <PageHeading>Sellers</PageHeading>
        <SectionHeading
          heading="Full list of Revibe sellers"
          subheading={`Once you identified the seller you're looking for, click on "Login as" to impersonate them and access their profile.`}
        />

        <Input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          placeholder="Search"
        />
        <Table
          isLoading={isLoading}
          data={rows}
          hasSortableHeadings
          maxRows={25}
          columns={['Name', 'Stripe setup', 'Account created on', 'Actions']}
        />
      </SpacedContainer>
    </LoggedInPage>
  );
};
