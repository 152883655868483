import { useEffect, useState } from 'react';

import { getSeller } from 'api/sellers.api';
import { APISeller } from 'api/sellers.types';
import { useParams } from 'react-router-dom';
import { SpacedContainer, Spinner } from 'revibe-ui';
import { ROUTES } from 'router';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useBoolean } from 'revibe-ui';
import { useToast } from 'shared/hooks';

import { SellerPhotoManager } from '../components/SellerPhotoManager';
import { SellerTranslations } from '../components/SellerTranslations';

export const SellerPage = () => {
  const [seller, setSeller] = useState<APISeller | null>(null);
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { errorToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const fetchSeller = async () => {
    if (!id) {
      return errorToast();
    }
    startFetching();
    const { data, error } = await getSeller(id);
    if (data) {
      setSeller(data);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchSeller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <LoggedInPage title="Seller">
        <SpacedContainer>
          <Spinner />
        </SpacedContainer>
      </LoggedInPage>
    );
  }

  if (!seller) {
    return null;
  }

  return (
    <LoggedInPage
      title={seller.name}
      breadcrumbs={[
        {
          label: 'Sellers',
          to: ROUTES.SELLERS,
        },
        {
          label: seller.name,
        },
      ]}
    >
      <SpacedContainer>
        <PageHeading>{seller.name}</PageHeading>
        <div className="max-w-3xl bg-gray-50 p-4">
          <SellerTranslations seller={seller} />
        </div>
        <div className="max-w-3xl bg-gray-50 p-4">
          <SellerPhotoManager seller={seller} />
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
