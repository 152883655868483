import { useEffect, useState } from 'react';

import { getOrder, updateOrderStatus } from 'api/order.api';
import { APIMarketplaceOrder } from 'api/orders.types';
import { useParams } from 'react-router-dom';
import { Button, SpacedContainer, Spinner } from 'revibe-ui';
import { ROUTES } from 'router';

import { formatPrice } from 'modules/catalog/utils/formatPrice';
import { ORDER_STATUS } from 'modules/orders/utils/orderStatus';
import { UserAddress } from 'modules/users/components/UserAddress';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useBoolean } from 'revibe-ui';
import { useToast } from 'shared/hooks';

import { SellerOrder } from '../components/SellerOrder';
import { OrderGroupStatusManager } from '../components/OrderGroupStatusManager';

export const OrderPage = () => {
  const [order, setOrder] = useState<APIMarketplaceOrder | null>(null);
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const [isUpdatingStatus, startUpdatingStatus, stopUpdatingStatus] =
    useBoolean();
  const { errorToast, toast } = useToast();
  const handleOrderStatusUpdate = async (newStatus: string) => {
    startUpdatingStatus();
    const { error } = await updateOrderStatus(id, newStatus);
    stopUpdatingStatus();
    if (error) {
      errorToast(error);
    } else {
      toast('Order status updated');
      fetchOrder();
    }
  };
  const { id } = useParams<{ id: string }>();
  const fetchOrder = async () => {
    if (!id) {
      return errorToast();
    }
    startFetching();
    const { data, error } = await getOrder(id);
    if (data) {
      setOrder(data);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <LoggedInPage title="Order">
        <SpacedContainer>
          <Spinner />
        </SpacedContainer>
      </LoggedInPage>
    );
  }

  if (!order) {
    return null;
  }

  return (
    <LoggedInPage
      title={`Order ${order.order_id}`}
      breadcrumbs={[
        {
          label: 'Orders',
          to: ROUTES.ORDERS,
        },
        {
          label: order?.order_id,
        },
      ]}
    >
      <SpacedContainer>
        <PageHeading>{order?.order_id}</PageHeading>

        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <OrderGroupStatusManager
            onUpdate={fetchOrder}
            order={order}
            id={id}
          />
          <div className="bg-gray-50 p-4">
            <h1 className="text-purple mb-6 text-lg font-bold">
              ORDER INFORMATION
            </h1>
            <SpacedContainer spacing="small">
              <p className="font-bold">
                Order date:{' '}
                <span className="text-purple font-normal">
                  {order.creation_date}
                </span>
              </p>
              <p className="font-bold">
                Order subtotal:{' '}
                <span className="text-purple font-normal">
                  {formatPrice(order.subtotal)} &euro;
                </span>
              </p>
              {order.subtotal_post_discount !== order.subtotal && (
                <p className="font-bold">
                  Order subtotal after discount:{' '}
                  <span className="text-purple font-normal">
                    {formatPrice(order.subtotal_post_discount)} &euro; (
                    {order.promo?.name})
                  </span>
                </p>
              )}
              <p className="font-bold">
                Order delivery fees:{' '}
                <span className="text-purple font-normal">
                  {formatPrice(order.delivery_fees)} &euro;
                </span>
              </p>
              <p className="font-bold">
                Number of sellers in the order:{' '}
                <span className="text-purple font-normal">
                  {order.orders.length}
                </span>
              </p>
              <p className="font-bold">
                Delivery type:{' '}
                <span className="text-purple font-normal">
                  {(order.delivery_type || 'standard').toUpperCase()}
                </span>
              </p>
            </SpacedContainer>
          </div>
          <UserAddress
            address={order.shipping_address}
            customerEmail={order.user.email}
            customerName={order.shipping_address.customer_name}
          />
        </div>
        {order.orders.map((sellerOrder) => (
          <SellerOrder
            key={sellerOrder.id}
            sellerOrder={sellerOrder}
            marketplaceOrderStatus={order.status}
            onUpdate={fetchOrder}
          />
        ))}
        <h1>Danger zone</h1>
        <Button
          variant="error"
          disabled={isUpdatingStatus}
          isLoading={isUpdatingStatus}
          onClick={() => handleOrderStatusUpdate(ORDER_STATUS.CANCELED)}
        >
          Cancel order
        </Button>
      </SpacedContainer>
    </LoggedInPage>
  );
};
