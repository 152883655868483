import React from 'react';

import { cn } from '../../helpers/classnames';

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {}

export const TableContainer = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn(
          'overflow-auto rounded-lg border border-slate-200',
          className
        )}
      >
        <table className="w-full tabular-nums" ref={ref} {...props}>
          {children}
        </table>
      </div>
    );
  }
);
TableContainer.displayName = 'TableContainer';
