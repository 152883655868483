import { useForm } from 'react-hook-form';
import { Category, updateCategoryTranslations, useMutation } from 'revibe-api';
import { SupportedLocale } from 'revibe-api/src/i18n/locales';
import {
  Button,
  FormControl,
  Input,
  SpacedContainer,
  Textarea,
} from 'revibe-ui';

import { useToast } from 'shared/hooks';

const CategoryTranslationForm = ({
  categoryID,
  translation,
}: {
  categoryID: number;
  translation: {
    name: string;
    description: string;
    locale: SupportedLocale;
  };
}) => {
  const { errorToast, toast } = useToast();
  const [updateTranslations, { isLoading }] = useMutation(
    `update-translations-${translation.locale}-${categoryID}`,
    updateCategoryTranslations,
    {
      onError: errorToast,
      onSuccess: () =>
        toast(`${translation.locale.toUpperCase()} Translations updated`),
    }
  );
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      name: translation.name,
      description: translation.description,
    },
  });

  return (
    <form
      className="rounded-md bg-gray-100 p-4"
      onSubmit={handleSubmit((values) => {
        updateTranslations({
          categoryID: categoryID,
          translations: [{ ...values, locale: translation.locale }],
        });
      })}
    >
      <SpacedContainer>
        <p className="text-lg font-semibold">
          {translation.locale.toUpperCase()} Translations
        </p>
        <FormControl label="Name">
          <Input {...register('name')} />
        </FormControl>
        <FormControl label="Description">
          <Textarea {...register('description')} />
        </FormControl>
        <Button
          type="submit"
          disabled={!formState.isDirty}
          isLoading={isLoading}
        >
          Save changes
        </Button>
      </SpacedContainer>
    </form>
  );
};

export const CategoryTranslations = ({ category }: { category: Category }) => {
  return (
    <SpacedContainer>
      {category.translations.map((translation) => (
        <CategoryTranslationForm
          categoryID={category.id}
          translation={translation}
          key={translation.locale}
        />
      ))}
    </SpacedContainer>
  );
};
