import { updateSellerOrderStatus } from 'api/order.api';
import { APISellerOrder } from 'api/orders.types';
import { Button, SpacedContainer, Tag } from 'revibe-ui';

import { SELLER_ORDER_STATUS } from 'modules/orders/utils/orderStatus';
import { sellerOrderStatusToString } from 'modules/orders/utils/orderStatusToString';

import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

const ORDER_STATIC_STATUSES = [
  SELLER_ORDER_STATUS.IN_REVIEW,
  SELLER_ORDER_STATUS.PAID_OUT,
  SELLER_ORDER_STATUS.PENDING_PAYOUT,
  SELLER_ORDER_STATUS.CANCELED,
];

type Props = { order: APISellerOrder; onUpdate: () => void };

export const OrderSellerStatusManager = ({ onUpdate, order }: Props) => {
  const [isUpdatingStatus, startUpdatingStatus, stopUpdatingStatus] =
    useBoolean();
  const { errorToast, toast } = useToast();
  const notConfirmedItemsCount = order.items.reduce(
    (count, item) =>
      item.availability_confirmed === false ? count + 1 : count,
    0
  );
  const confirmedItemsCount = order.items.reduce(
    (count, item) => (item.availability_confirmed === true ? count + 1 : count),
    0
  );
  const handleOrderStatusUpdate = async (newStatus: string) => {
    if (typeof newStatus !== 'string') {
      return;
    }
    startUpdatingStatus();
    const { error } = await updateSellerOrderStatus(
      order.seller.id,
      order.id,
      newStatus
    );
    stopUpdatingStatus();
    if (error) {
      errorToast(error);
    } else {
      toast('Order status updated');
      onUpdate();
    }
  };
  const renderStatusActions = (status: string) => {
    if (status === SELLER_ORDER_STATUS.PENDING_PAYOUT) {
      return (
        <Button
          className="w-full"
          disabled={isUpdatingStatus}
          isLoading={isUpdatingStatus}
          onClick={() => handleOrderStatusUpdate(SELLER_ORDER_STATUS.PAID_OUT)}
        >
          Mark as paid out
        </Button>
      );
    }
    return null;
  };

  const renderHeading = () => {
    if (ORDER_STATIC_STATUSES.includes(order.status)) {
      return (
        <h1 className="text-purple">
          {sellerOrderStatusToString(order.status)}
        </h1>
      );
    }
    if (order.status === SELLER_ORDER_STATUS.REVIEW_COMPLETED) {
      return <h1 className="text-purple">Shipping ready for configuration</h1>;
    }
    return (
      <>
        <h1 className="text-purple">Delivery in progress</h1>
        <p>Some products included in the order are still out for delivery.</p>
      </>
    );
  };

  const renderAvailability = () => {
    if (confirmedItemsCount === order.items.length) {
      return (
        <>
          <Tag>Confirmed</Tag>
          <p>
            All products included in the order have been 'confirmed' by{' '}
            {order.seller.name}.
          </p>
        </>
      );
    }
    if (notConfirmedItemsCount === order.items.length) {
      return (
        <>
          <Tag color="red">Products not available</Tag>
          <p>
            All products included in the order have been 'marked as not
            available' by {order.seller.name}.
          </p>
        </>
      );
    }
    return (
      <>
        <Tag color="yellow">Partial confirmation</Tag>
        <p>
          Pending review:{' '}
          {order.items.length - (confirmedItemsCount + notConfirmedItemsCount)}
        </p>
        <p>Availability confirmed: {confirmedItemsCount}</p>
        <p>Not available: {notConfirmedItemsCount}</p>
      </>
    );
  };

  return (
    <>
      <div className="rounded-md bg-white p-4 sm:p-6 lg:p-8">
        <h1 className="text-purple mb-6 text-lg font-bold">ORDER STATUS</h1>
        <SpacedContainer spacing="small">
          {renderHeading()}
          {order.status === SELLER_ORDER_STATUS.CANCELED && (
            <p>The order was canceled.</p>
          )}
          {order.status === SELLER_ORDER_STATUS.IN_REVIEW && (
            <p>
              Some of the products included in the order are stiill waiting to
              be 'confirmed' or 'rejected' by {order.seller.name}.
            </p>
          )}
          {order.status === SELLER_ORDER_STATUS.PENDING_PAYOUT && (
            <p>
              All the products were delivered to the customer, we now have to
              payout {order.seller.name} from Stripe.
            </p>
          )}
          {order.status === SELLER_ORDER_STATUS.PAID_OUT && (
            <p>
              All the products were delivered to the customer, and{' '}
              {order.seller.name} was paid using Stripe.
            </p>
          )}
          {renderStatusActions(order.status)}
        </SpacedContainer>
      </div>
      <div className="rounded-md bg-white p-4 sm:p-6 lg:p-8">
        <h1 className="text-purple mb-6 text-lg font-bold uppercase">
          Products availability review
        </h1>

        <SpacedContainer>{renderAvailability()}</SpacedContainer>
      </div>
    </>
  );
};
// type={
//   confirmedItemsCount + notConfirmedItemsCount === order.items.length &&
//   confirmedItemsCount > 0 &&
//   notConfirmedItemsCount > 0
//     ? 'warning'
//     : notConfirmedItemsCount === order.items.length
//     ? 'error'
//     : undefined
// }
