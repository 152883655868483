import { useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { Spinner } from 'revibe-ui';
import { ROUTES } from 'router';

import { Page } from 'shared/components';
import { useQueryStringParameters } from 'shared/hooks';

import { useAuthentication } from '../hooks';

export const RedirectLoginPage = () => {
  const query = useQueryStringParameters();
  const token = query.get('token');
  const id = query.get('id');
  const { forceLogin } = useAuthentication();

  useEffect(() => {
    if (token && id) {
      forceLogin(token, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  if (!token || !id) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <Page title="Login">
      <Spinner />
    </Page>
  );
};
