export const serializeQueryString = (options: { [key: string]: any }) => {
  const str = [];
  for (const p in options)
    if (
      options.hasOwnProperty(p) &&
      options[p] !== undefined &&
      options[p] !== ''
    ) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(options[p]));
    }

  return str.join('&');
};

export const generateRouteWithOptions = (
  route: string,
  {
    params = {},
    query = {},
  }: { params?: { [key: string]: string }; query?: { [key: string]: any } } = {
    params: {},
    query: {},
  }
) => {
  let dynamicRoute = route;

  for (const key in params) {
    dynamicRoute = dynamicRoute.replace(`:${key}`, params[key]);
  }

  return `${dynamicRoute}?${serializeQueryString(query)}`;
};
