import { convertObjectToQueryParams } from 'shared/utils';

import {
  APIItem,
  APIItemUpdate,
  ItemFilters,
  ItemPhotoUpdate,
  ItemStock,
} from './items.types';
import { PaginationResponse } from './pagination.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const updateItem = async (
  itemID: string,
  item: APIItemUpdate,
  stock?: ItemStock
) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}`,
    body: { ...item, stock },
    method: 'PUT',
    reshaper: (res: APIItem) => res,
  });

export const updateItemPhoto = async (
  itemID: string,
  photoID: string,
  update: ItemPhotoUpdate
) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/photos/${photoID}`,
    body: update,
    method: 'PUT',
    reshaper: (res: APIItem) => res,
  });

export const addItemToList = async (itemID: string, list: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/lists/${list}`,
    body: {},
    method: 'POST',
    reshaper: (res: APIItem) => res,
  });

export const removeItemFromList = async (itemID: string, list: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/lists/${list}`,
    body: {},
    method: 'DELETE',
    reshaper: (res: APIItem) => res,
  });

export const getItems = async (filters: ItemFilters, page = 1, limit = 0) => {
  let url = `${REVIBE_API_URL}/items?page=${page}&limit=${limit}${convertObjectToQueryParams(
    filters
  )}`;

  return await apiCall({
    endpoint: url,
    method: 'GET',
    reshaper: (res: PaginationResponse<APIItem>) => res,
  });
};

export const getItem = async (itemID: string, userID?: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}?userid=${userID}`,
    method: 'GET',
    reshaper: (res: APIItem) => res,
  });

export const addItemToFavorites = async (userID: string, itemID: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/users/${userID}/items/${itemID}`,
    method: 'POST',
    reshaper: (res: any) => res,
  });

export const removeItemFromFavorites = async (userID: string, itemID: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/users/${userID}/items/${itemID}`,
    method: 'DELETE',
    reshaper: (res: any) => res,
  });
