import { ROUTES } from 'routes';

import {
  ChartPieIcon,
  CursorClickIcon,
  HomeIcon,
  TruckIcon,
  UsersIcon,
  ViewListIcon,
} from '@heroicons/react/outline';

export type AccessLevels = 'all' | 'start' | 'scale';

export type SubEntry = {
  label: string;
  id: string;
  to: string;
  getIsActive: (path: string) => boolean;
};

export type Entry = {
  label: string;
  id: string;
  icon: ({ className, onClick }: any) => JSX.Element;
  to: string | null;
  getIsActive: (path: string) => boolean;
  subentries: SubEntry[];
};

export const MENU_ENTRIES: Entry[] = [
  {
    label: 'Home',
    id: 'home',
    icon: HomeIcon,
    to: ROUTES.HOME,
    getIsActive: (path: string) => path === ROUTES.HOME,
    subentries: [],
  },
  {
    label: 'Sellers',
    id: 'sellers',
    icon: UsersIcon,
    to: null,
    getIsActive: () => false,
    subentries: [
      {
        label: 'Sellers list',
        id: 'sellers-list',
        to: ROUTES.SELLERS,
        getIsActive: (path: string) => path === ROUTES.SELLERS,
      },
    ],
  },
  {
    label: 'Catalog',
    id: 'catalog',
    icon: ViewListIcon,
    to: null,
    getIsActive: () => false,
    subentries: [
      {
        label: 'Products',
        id: 'all-items',
        getIsActive: (path: string) =>
          path.includes(ROUTES.ITEMS) && !path.includes(ROUTES.ITEMS_PHOTOS),
        to: ROUTES.ITEMS,
      },
      {
        label: 'Photos',
        id: 'photos',
        getIsActive: (path: string) => path.includes(ROUTES.ITEMS_PHOTOS),
        to: ROUTES.ITEMS_PHOTOS,
      },
      {
        label: 'Categories',
        id: 'categories',
        getIsActive: (path: string) => path.includes(ROUTES.CATEGORIES),
        to: ROUTES.CATEGORIES,
      },
      {
        label: 'Collections',
        id: 'collections',
        getIsActive: (path: string) => path.includes(ROUTES.COLLECTIONS),
        to: ROUTES.COLLECTIONS,
      },
      {
        label: 'Editorial selections',
        id: 'Selections',
        getIsActive: (path: string) => path.includes(ROUTES.SELECTIONS),
        to: ROUTES.SELECTIONS,
      },
    ],
  },
  {
    label: 'Orders',
    id: 'orders',
    icon: TruckIcon,
    to: null,
    getIsActive: () => false,
    subentries: [
      {
        label: 'Marketplace carts',
        id: 'marketplace-carts',
        getIsActive: (path: string) => path.includes(ROUTES.CARTS),
        to: ROUTES.CARTS,
      },
      {
        label: 'Marketplace orders',
        id: 'marketplace-orders',
        getIsActive: (path: string) => path.includes(ROUTES.ORDERS),
        to: ROUTES.ORDERS,
      },
      {
        label: 'Shipping fees',
        id: 'shipping',
        getIsActive: (path: string) => path.includes(ROUTES.SHIPPING_FEES),
        to: ROUTES.SHIPPING_FEES,
      },
    ],
  },
  {
    label: 'Promo codes',
    id: 'promotions',
    icon: CursorClickIcon,
    getIsActive: (path: string) => path.includes(ROUTES.MKT_PROMOTIONS),
    to: ROUTES.MKT_PROMOTIONS,
    subentries: [],
  },
  {
    label: 'Stats',
    id: 'stats',
    icon: ChartPieIcon,
    to: ROUTES.STATS,
    getIsActive: (path: string) => path === ROUTES.STATS,
    subentries: [],
  },
];
