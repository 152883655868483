import { AuthResponse } from '../types/auth.types';
import { apiCall, ApiCallConfig } from '../utils/apiCall';

export interface LoginWithCredentials extends ApiCallConfig {
  email: string;
  password: string;
}

export interface LoginWithFacebook extends ApiCallConfig {
  email: string;
}

export const loginSellerWithCredentials = async ({
  email,
  password,
  ...params
}: LoginWithCredentials) =>
  apiCall({
    ...params,
    endpoint: `business/auth/credentials`,
    method: 'POST',
    body: { email, password },
    reshaper: (data: AuthResponse) => data,
  });

export const loginAdminWithCredentials = async ({
  email,
  password,
  ...params
}: LoginWithCredentials) =>
  apiCall({
    ...params,
    endpoint: `admin/auth/credentials`,
    method: 'POST',
    body: { email, password },
    reshaper: (data: AuthResponse) => data,
  });
