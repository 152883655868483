import {
  APIMarketplaceOrder,
  APIOrderCounters,
  APIOrderItem,
  APIOrderItemUpdate,
  APISellerOrder,
} from './orders.types';
import { PaginationResponse } from './pagination.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getOrderCounters = async () =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/orders/counters`,
    method: 'GET',
    reshaper: (data: APIOrderCounters) => data,
  });

export const getOrders = async (status?: string) =>
  apiCall({
    endpoint: status
      ? `${REVIBE_API_URL}/orders?status=${status}`
      : `${REVIBE_API_URL}/orders`,
    method: 'GET',
    reshaper: (data: PaginationResponse<APIMarketplaceOrder>) => data.res,
  });

export const getSellerOrders = async (status?: string) =>
  apiCall({
    endpoint: status
      ? `${REVIBE_API_URL}/seller-orders?status=${status}`
      : `${REVIBE_API_URL}/seller-orders`,
    method: 'GET',
    reshaper: (data: PaginationResponse<APISellerOrder>) => data.res,
  });

export const getOrderItems = async (status?: string) =>
  apiCall({
    endpoint: status
      ? `${REVIBE_API_URL}/order-items?status=${status}`
      : `${REVIBE_API_URL}/order-items`,
    method: 'GET',
    reshaper: (
      data: PaginationResponse<APIOrderItem & { order: APISellerOrder }>
    ) => data.res,
  });

export const getOrder = async (orderID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/orders/${orderID}`,
    method: 'GET',
    reshaper: (data: APIMarketplaceOrder) => data,
  });

export const getUserOrders = async (userID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/users/${userID}/orders`,
    method: 'GET',
    reshaper: (data: PaginationResponse<APIMarketplaceOrder>) => data.res,
  });

export const updateOrderStatus = async (orderID: string, status: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/orders/${orderID}`,
    method: 'PUT',
    body: { status },
    reshaper: (data: APIMarketplaceOrder) => data,
  });

export const updateOrderItem = async (
  orderItemID: string,
  update: APIOrderItemUpdate
) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/orders/items/${orderItemID}`,
    method: 'PUT',
    body: update,
    reshaper: (data: APIMarketplaceOrder) => data,
  });

export const updateSellerOrderStatus = async (
  sellerID: string,
  orderID: string,
  status: string
) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/orders/${orderID}`,
    method: 'PUT',
    body: { status },
    reshaper: (data: APIMarketplaceOrder) => data,
  });
