import { useState } from 'react';

export const useBoolean = (
  state = false
): [boolean, () => void, () => void, () => void] => {
  const [isTrue, setIsTrue] = useState(state);
  const setTrue = () => setIsTrue(true);
  const setFalse = () => setIsTrue(false);
  const toggle = () => setIsTrue(!isTrue);

  return [isTrue, setTrue, setFalse, toggle];
};
