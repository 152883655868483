import { toast as sonnerToast } from 'sonner';

import { ObjectType } from 'shared/utils/types';

const NOTIFICATIONS = {
  error: {
    'error-heading': 'There was an error',
    'internal-error': 'internal error',
    'not-found': 'Resource not found',
    'user-not-found': 'user not found',
    'incorrect-password': 'incorrect password',
    'invalid-password': 'please enter a valid password',
    'invalid-email': 'please enter a valid email',
    'invalid-first-name': 'please enter a first name',
    'invalid-last-name': 'please enter a last name',
    'passwords-mismatch': "passwords don't match",
    'policy-not-accepted': 'please accept our terms of service',
    unknown: 'unknown error',
    'select-size': 'select a size for the item',
    'select-country': 'select a country from the list',
    unauthorized: 'unauthorized',
    'promo-already-added': 'promo already added',
    'already-registered': 'already registered',
    'promo-inactive': 'the promotion is not active anymore',
    'cf-no-values': 'a select custom field must have at least one value',
    'cf-no-label': 'please add a label',
    'cf-no-name': 'please add a name',
  } as ObjectType<string>,
  info: {
    'profile-updated': 'profile updated',
    'logged-in': 'logged in',
    'email-sent': 'email sent',
    'item-added': 'item added',
    'item-added-text': 'the item was successfully added to your catalog',
    'item-updated': 'item updated',
    'item-updated-text': 'the item was successfully updated',
    'address-deleted': 'address deleted',
    copied: 'copied',
    'updated-successfully': 'updated successfully!',
    'added-successfully': 'added successfully!',
  } as ObjectType<string>,
};

export const useToast = () => {
  const tuError = (key: string) => NOTIFICATIONS.error[key] || key;
  const tuInfo = (key: string) => NOTIFICATIONS.info[key] || key;
  const errorToast = (text?: string | null) => {
    sonnerToast.error(tuError(text || 'unknown'));
  };
  const successToast = (text?: string | null) => {
    sonnerToast.success(tuInfo(text || 'unknown'));
  };
  const toast = (text: string) => {
    sonnerToast(tuInfo(text));
  };

  return {
    errorToast,
    successToast,
    promiseToast: sonnerToast.promise,
    toast,
  };
};
