import { useEffect, useState } from 'react';

import { getItems, updateItem } from 'api/items.api';
import { APIItem, ItemFilters } from 'api/items.types';
import { useAuthentication } from 'auth/hooks';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {
  Button,
  SpacedContainer,
  Spinner,
  Tabs,
  TabsList,
  TabsTrigger,
} from 'revibe-ui';

import { ItemPreview } from 'modules/catalog/components/ItemPreview';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';

import { useHistory, useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

type Props = {
  filters?: ItemFilters;
  onItemsLoaded?: (items: APIItem[]) => void;
};

export const ItemsDraggableGrid = ({
  filters = {},
  onItemsLoaded = () => {},
}: Props) => {
  const { errorToast, toast } = useToast();
  const [hasMadeChangesToItems, setHasMadeChanges] = useBoolean();
  const [isUpdating, startUpdating, stopUpdating] = useBoolean();
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const [sex, setSex] = useState<'male' | 'female' | undefined>(undefined);
  const { userID } = useAuthentication();
  const history = useHistory();
  const [items, setItems] = useState<APIItem[]>([]);
  const userFilters = {
    ...filters,
    [ITEM_FILTERS.USER]: userID,
    [ITEM_FILTERS.INCLUDE_UNRELEASED_COLLECTIONS]: true,
    [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
  };
  const fetchItems = async () => {
    startFetching();
    const { data, error } = await getItems(userFilters);
    if (data) {
      setItems(data.res);
      onItemsLoaded(data.res);
    } else {
      errorToast(error);
    }
    stopFetching();
  };
  const reorder = (list: APIItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const newOrderedItems = reorder(items, source.index, destination.index);

    setItems(newOrderedItems);
  };
  const handleSave = async () => {
    startUpdating();
    await Promise.all(
      items.map(async (item, i) => {
        await updateItem(item.id, {
          position: i,
        });
      })
    );
    toast(`Items order saved`);
    stopUpdating();
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={() => console.log('drag start')}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="min-h-[512px] w-full"
          >
            {isFetching && !items.length ? (
              <Spinner />
            ) : (
              <SpacedContainer fullWidth>
                <SpacedContainer
                  type="horizontal"
                  centered
                  className="sticky top-16 z-10 mt-8 w-full min-w-full items-start justify-between rounded-md border border-gray-200 bg-white p-4 lg:top-12"
                >
                  <Tabs defaultValue={String(sex)}>
                    <TabsList>
                      <TabsTrigger
                        onClick={() => setSex('female')}
                        value="female"
                      >
                        Woman
                      </TabsTrigger>
                      <TabsTrigger onClick={() => setSex('male')} value="male">
                        Man
                      </TabsTrigger>
                      <TabsTrigger
                        onClick={() => setSex(undefined)}
                        value="undefined"
                      >
                        All
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>

                  {hasMadeChangesToItems && (
                    <Button variant="subtle" onClick={() => history.go(0)}>
                      Reload to see changes to lists
                    </Button>
                  )}
                  <Button
                    onClick={handleSave}
                    disabled={isUpdating}
                    isLoading={isUpdating}
                  >
                    Save configuration
                  </Button>
                </SpacedContainer>
                <div className="flex flex-col gap-1">
                  {items.map((item, index) => (
                    <Draggable
                      isDragDisabled={
                        sex && item.sex !== 'unisex' && sex !== item.sex
                      }
                      key={String(item.id)}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ItemPreview
                            isDragDisabled={
                              sex && item.sex !== 'unisex' && sex !== item.sex
                            }
                            index={index}
                            item={item}
                            manageSex
                            hideRating
                            onItemChange={setHasMadeChanges}
                            onItemIndexChanged={(oi, ni) =>
                              onDragEnd({
                                destination: {
                                  index: ni,
                                  droppableId: String(item.id),
                                },
                                source: {
                                  index: oi,
                                  droppableId: String(item.id),
                                },
                              } as DropResult)
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              </SpacedContainer>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
