import { PaginationResponse } from './pagination.types';
import { APISeller, APISellerUpdate } from './sellers.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getSellers = async () =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers?sortByOnboardingDate=true`,
    method: 'GET',
    reshaper: (data: PaginationResponse<APISeller>) => data.res,
  });

export const getActiveSellers = async () =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/active?includeSellersWithoutApprovedItems=true`,
    method: 'GET',
    reshaper: (data: APISeller[]) => data,
  });

export const getSeller = async (sellerID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}`,
    method: 'GET',
    reshaper: (data: APISeller) => data,
  });

export const updateSeller = async (
  sellerID: string | number,
  seller: APISellerUpdate
) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}`,
    method: 'PUT',
    body: seller,
    reshaper: (data: APISeller) => data,
  });
};

export const updateSellerMainPhoto = async (
  id: string | number,
  logo: File
) => {
  const data = new FormData();
  data.append('files', logo);
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${id}/main-photo`,
    method: 'POST',
    body: data,
    reshaper: (data: any) => data.res,
    rawBody: true,
  });
};
