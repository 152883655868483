import { NoData } from 'shared/components';

import { OrdersIcon } from 'ui/icons';

export const NoOrders = () => {
  return (
    <NoData
      icon={<OrdersIcon />}
      heading="There are no orders"
      text="Once we will receive orders they will appear here."
    />
  );
};
