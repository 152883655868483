import { useAuthentication } from 'auth/hooks';
import { SpacedContainer } from 'revibe-ui';
import { ROUTES } from 'routes';

import { ExclamationIcon } from '@heroicons/react/outline';

import { ItemsDraggableGrid } from 'modules/catalog/components';
import { HowOrderingModal } from 'modules/catalog/components/HowOrderingModal';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useQueryStringParameters } from 'shared/hooks';
import { convertObjectToQueryParams } from 'shared/utils';
import { ObjectType } from 'shared/utils/types';

export const ItemsCategoryPage = () => {
  const { userID } = useAuthentication();
  const query = useQueryStringParameters();
  let filters: ObjectType<any> = {
    [ITEM_FILTERS.STATUS]: ITEM_STATUSES.PENDING_APPROVAL,
  };

  for (const pair of query.entries()) {
    filters[pair[0]] = pair[1];
  }

  if (!userID) {
    return null;
  }

  return (
    <LoggedInPage
      title="Items"
      breadcrumbs={[
        {
          label: 'Catalog',
          to: `${ROUTES.ITEMS}?${convertObjectToQueryParams({
            [ITEM_FILTERS.STATUS]: ITEM_STATUSES.APPROVED,
          })}`,
        },
        {
          label: filters[ITEM_FILTERS.CATEGORY],
        },
      ]}
    >
      <SpacedContainer>
        <div className="w-full">
          <SpacedContainer className="relative">
            <SpacedContainer>
              <PageHeading>Category management</PageHeading>

              <HowOrderingModal />
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Note on category lists
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        Only approved items are displayed for category ordering.
                      </p>
                      <p>
                        Items &apos;out of stock&apos; will not be included in
                        the list displayed to the user (even if they are present
                        here).
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <ItemsDraggableGrid
                filters={{
                  ...filters,
                  [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                  [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                  [ITEM_FILTERS.STATUS]: ITEM_STATUSES.APPROVED,
                }}
              />
            </SpacedContainer>
          </SpacedContainer>
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
