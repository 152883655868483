import { useState } from 'react';

import { Category, updateCategoryPhoto, useMutation } from 'revibe-api';
import {
  Button,
  FilesUploader,
  FormControl,
  Image,
  SpacedContainer,
} from 'revibe-ui';

import { useToast } from 'shared/hooks';
import { useCategories } from 'shared/hooks/useCategories';

export const CategoryPhoto = ({
  category,
  type,
  onUpload,
}: {
  category: Category;
  type: string;
  onUpload: () => void;
}) => {
  const [isChangingPhoto, setIsChangingPhoto] = useState(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const { errorToast, toast } = useToast();
  const { refresh } = useCategories();
  const categoryPhoto = category.photos.find(
    (photo) => photo.type === type
  )?.link;
  const [uploadPhoto, { isLoading: isUploadingLogo }] = useMutation(
    `photo-category-${category.slug}`,
    updateCategoryPhoto,
    {
      onError: (err) => errorToast(err),
      onSuccess: () => {
        setIsChangingPhoto(false);
        toast('Photo uploaded!');
        onUpload();
        refresh();
      },
    }
  );

  if (isChangingPhoto) {
    return (
      <SpacedContainer>
        <FilesUploader
          accept={{
            'image/png': [],
            'image/jpeg': [],
            'image/avif': [],
            'image/webp': [],
          }}
          onUpdate={(files) => {
            setPhoto(files[0]);
          }}
        />
        <SpacedContainer>
          <Button
            className="w-full"
            type="button"
            disabled={isUploadingLogo || photo === null}
            isLoading={isUploadingLogo}
            onClick={async () => {
              if (!photo) {
                return;
              }
              await uploadPhoto({ id: category.id, photo, type });
            }}
          >
            Upload photo
          </Button>
          <Button
            variant="subtle"
            className="w-full"
            type="button"
            onClick={() => {
              setPhoto(null);
              setIsChangingPhoto(false);
            }}
          >
            Cancel
          </Button>
        </SpacedContainer>
      </SpacedContainer>
    );
  }

  if (!categoryPhoto) {
    return (
      <SpacedContainer>
        <div className="h-full w-full rounded-md bg-purple-100" />
        <Button
          variant="subtle"
          className="w-full"
          onClick={() => setIsChangingPhoto(true)}
        >
          Add a photo
        </Button>
      </SpacedContainer>
    );
  }

  return (
    <SpacedContainer>
      <Image objectFit="cover" src={categoryPhoto} alt={category.slug} />
      <Button
        variant="subtle"
        className="w-full"
        onClick={() => setIsChangingPhoto(true)}
      >
        Change photo
      </Button>
    </SpacedContainer>
  );
};

export const CategoryPhotoManager = ({
  category,
  onUpload,
}: {
  category: Category;
  onUpload: () => void;
}) => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <FormControl label="Unisex">
        <CategoryPhoto onUpload={onUpload} type="unisex" category={category} />
      </FormControl>{' '}
      <FormControl label="Men">
        <CategoryPhoto onUpload={onUpload} type="male" category={category} />{' '}
      </FormControl>
      <FormControl label="Women">
        <CategoryPhoto onUpload={onUpload} type="female" category={category} />{' '}
      </FormControl>
    </div>
  );
};
