import Select, { ClassNamesConfig, GroupBase, SingleValue } from 'react-select';

import { cn } from '../../../helpers/classnames';

export type MultiSelectOption = SingleValue<Option>;

type SelectProps = {
  onChange: (value: SingleValue<Option>) => void;
  value: MultiSelectOption;
  values: Option[];
} & Partial<{
  label: string;
  className: string;
  disabled: boolean;
  placeholder: string;
}>;

type Option = {
  label: string;
  value: any;
  isDisabled?: boolean;
};

const CLASSNAMES: ClassNamesConfig<Option, false, GroupBase<Option>> = {
  control: (state) =>
    cn(
      'shadow-none bg-white border cursor-pointer transition w-full',
      state.isFocused ? 'border-gray-400' : 'border-gray-300'
    ),
  container: () => 'w-full text-sm',
  placeholder: () => 'text-sm text-gray-500',
  valueContainer: () => 'px-3 py-2.5 gap-2',
  indicatorsContainer: () => 'px-4 py-2 cursor-pointer',
  multiValue: () => 'rounded-lg px-2 py-0.5 bg-gray-600 items-center',
  multiValueLabel: () => 'text-gray-100 px-1',
  multiValueRemove: () =>
    'stroke-white text-white text-sm rounded-lg hover:bg-red-900 ml-1 h-4 w-4 [&>svg]:-mr-1',
  menuList: () => 'shadow-md top-2 bg-white border border-gray-300 gap-1 py-2',
  option: (state) =>
    cn(
      'hover:bg-gray-50 cursor-pointer px-4 py-2',
      state.isDisabled ? 'text-gray-500 bg-gray-50 cursor-notallowed' : ''
    ),
  input: () =>
    '[&>input]:outline-none [&>input]:border-none [&>input]:shadow-none [&>input]:focus:ring-transparent [&>input]:border-transparent [&>input]:focus:border-transparent [&>input]:focus:ring-0',
};

export const MultiSelect = ({
  label,
  values = [],
  value,
  disabled,
  placeholder,
  onChange,
}: SelectProps) => {
  return (
    <Select
      options={values}
      classNames={CLASSNAMES}
      unstyled
      value={value}
      defaultValue={value || null}
      name={label}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};
