import React from 'react';

import { cn } from '../../helpers/classnames';

interface TableProps extends React.TableHTMLAttributes<HTMLTableCellElement> {}

export const TableCell = React.forwardRef<HTMLTableCellElement, TableProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <td
        className={cn('px-4 py-3 text-sm tabular-nums', className)}
        ref={ref}
        {...props}
      >
        {children}
      </td>
    );
  }
);
TableCell.displayName = 'TableCell';
