import { APIStats } from './stats.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getStats = async () =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/admin/analytics/marketplace`,
    method: 'GET',
    reshaper: (data: APIStats) => data,
  });
