import { APIUserAddress } from 'api/addresses.types';
import { SpacedContainer } from 'revibe-ui';

import { useCountries } from 'shared/hooks';

type Props = {
  address: APIUserAddress;
  customerName: string;
  customerEmail: string;
};

export const UserAddress = ({
  address,
  customerName,
  customerEmail,
}: Props) => {
  const { getCountryByCode } = useCountries();
  return (
    <div className="bg-gray-50 p-4">
      <h1 className="text-purple mb-6 text-lg font-bold">
        CUSTOMER INFORMATION
      </h1>
      <SpacedContainer>
        <SpacedContainer spacing="small">
          <p className="font-semibold">
            Name: <span className="text-purple">{customerName}</span>
          </p>
          <p className="font-semibold">
            Email: <span className="text-purple">{customerEmail}</span>
          </p>
          {address.phone && (
            <p className="font-semibold">
              Phone number: <span className="text-purple">{address.phone}</span>
            </p>
          )}
        </SpacedContainer>
        <SpacedContainer spacing="small">
          <p className="font-semibold">Shipping Address:</p>
          <p className="m-0 text-sm text-gray-500">
            {address.address} &#183; {address.postal_code}, {address.city},{' '}
            {getCountryByCode(address.country)}
          </p>
          {address.additional_info && (
            <p className="m-0 text-sm text-gray-500">
              Additional information: {address.additional_info}
            </p>
          )}
        </SpacedContainer>
      </SpacedContainer>
    </div>
  );
};
