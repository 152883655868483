import { useState } from 'react';

import countryList from 'country-list';
import {
  DeliveryCountry,
  useMarketplaceDeliveryCountries,
  useMarketplaceDeliveryCountryDelete,
} from 'revibe-api';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Drawer,
  SpacedContainer,
  Table,
} from 'revibe-ui';
import { useBoolean } from 'revibe-ui';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

import { formatPrice } from 'modules/catalog/utils/formatPrice';

import { LoggedInPage } from 'shared/components';
import { SectionHeading } from 'shared/components/SectionHeading';
import { useToast } from 'shared/hooks';
import { yesNo } from 'shared/utils/yesNo';

import { ShippingFeeCountryForm } from '../components/ShippingFeeCountryForm';

export const ShippingFeesPage = () => {
  const { errorToast, successToast } = useToast();

  const [isCreateDrawerOpen, openCreateDrawer, closeCreateDrawer] =
    useBoolean();

  const [deliveryCountries, { isFetching, refetch }] =
    useMarketplaceDeliveryCountries({
      defaultValue: [],
      onError: errorToast,
    });

  const [selectedCountry, setSelectedCountry] =
    useState<DeliveryCountry | null>(null);

  const [deleteCountry] = useMarketplaceDeliveryCountryDelete({
    onSuccess: () => {
      refetch();
      successToast('Country deleted');
    },
  });

  return (
    <LoggedInPage
      hideOverflow
      title="Shipping fees"
      breadcrumbs={[
        {
          label: 'Operations',
        },
        {
          label: 'Shipping fees',
        },
      ]}
    >
      <SpacedContainer>
        <SectionHeading
          heading="List of shipping fees by country"
          action={{
            label: 'Add a country',
            onClick: openCreateDrawer,
          }}
        />

        <Table
          isLoading={isFetching}
          maxRows={25}
          columns={[
            'Country',
            'Country Code',
            'Standard Fee',
            'Express Fee',
            'Free Delivery Applicable',
            '',
          ]}
          data={deliveryCountries.map((deliveryCountry) => {
            return [
              countryList.getName(deliveryCountry.country_code) || 'Unknown',
              deliveryCountry.country_code,
              `${formatPrice(deliveryCountry.standard)} €`,
              `${formatPrice(deliveryCountry.express)} €`,
              yesNo(deliveryCountry.free_delivery_threshold),
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="cursor-pointer rounded-full bg-gray-50 p-1 shadow-sm transition hover:shadow-md ">
                    <EllipsisVerticalIcon className="h-4 w-4 text-gray-700" />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => setSelectedCountry(deliveryCountry)}
                  >
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      deleteCountry({
                        params: { countryCode: deliveryCountry.country_code },
                      })
                    }
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>,
            ];
          })}
        />
      </SpacedContainer>
      <Drawer
        scrollable
        position="right"
        open={isCreateDrawerOpen}
        onOpenChange={closeCreateDrawer}
        className="p-4"
      >
        <ShippingFeeCountryForm
          onSubmit={async () => {
            await refetch();
            closeCreateDrawer();
          }}
        />
      </Drawer>
      <Drawer
        scrollable
        position="right"
        open={selectedCountry !== null}
        onOpenChange={() => setSelectedCountry(null)}
        className="p-4"
      >
        {selectedCountry && (
          <ShippingFeeCountryForm
            country={selectedCountry}
            onSubmit={async () => {
              await refetch();
              closeCreateDrawer();
            }}
          />
        )}
      </Drawer>
    </LoggedInPage>
  );
};
