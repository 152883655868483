import { MutationOptions, useMutation } from '../../../../../hooks/useMutation';
import { apiCall } from '../../../../../utils/apiCall';
import { generateRouteWithOptions } from '../../../../../utils/params';
import { DELETE, PUT } from './country.schema';

const ROUTE = 'marketplace/delivery-fees/countries/:countryCode';

export const useMarketplaceDeliveryCountryUpdate = (
  options: MutationOptions<{ updated: boolean }>
) =>
  useMutation(
    'delete-marketplace-delivery-country',
    async ({ params, body }: { params: PUT['params']; body: PUT['body'] }) =>
      apiCall({
        endpoint: generateRouteWithOptions(ROUTE, {
          params,
        }),
        method: 'PUT',
        body,
        reshaper: (data: { updated: boolean }) => data,
      }),
    options
  );

export const useMarketplaceDeliveryCountryDelete = (
  options: MutationOptions<{ deleted: boolean }>
) =>
  useMutation(
    'delete-marketplace-delivery-country',
    async ({ params }: { params: DELETE['params'] }) =>
      apiCall({
        endpoint: generateRouteWithOptions(ROUTE, {
          params,
        }),
        method: 'DELETE',
        reshaper: (data: { deleted: boolean }) => data,
      }),
    options
  );
