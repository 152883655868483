import { toast } from 'sonner';

export function downloadImage(imageSrc: string, name: string) {
  toast.promise(
    async () => {
      const image = await fetch(imageSrc);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    {
      loading: `Downloading ${name}`,
      success: `Photo ${name} downloaded`,
      error: `Error downloading photo ${name}`,
    }
  );
}
