import { useEffect, useState } from 'react';

import { Category } from 'revibe-api';
import { MultiSelect, MultiSelectOption } from 'revibe-ui';

import { useCategories } from 'shared/hooks/useCategories';

import { getCategoryGroupLabelFromID } from '../utils/categories';

type CategorySelectorProps = {
  onChange: (category: Category | null) => void;
  initialSelectedCategory: string | null;
};

const getSelectValuesFromGroup = (categories: Category[], group: string) => {
  return [
    {
      label: getCategoryGroupLabelFromID(group),
      value: group,
      isDisabled: true,
    },
    ...categories
      .filter((categoryGroup) => categoryGroup.group === group)
      .map((category) => ({
        label: category.name,
        value: category.id,
        isDisabled: false,
      })),
  ];
};

export const CategorySelector = ({
  onChange,
  initialSelectedCategory,
}: CategorySelectorProps) => {
  const { categories } = useCategories();
  const [value, setValue] = useState<MultiSelectOption>(null);
  const values = [
    ...getSelectValuesFromGroup(categories, 'clothing'),
    ...getSelectValuesFromGroup(categories, 'accessories-more'),
  ];
  useEffect(() => {
    if (initialSelectedCategory !== null) {
      const category = categories.find(
        // eslint-disable-next-line eqeqeq
        (data) => data.slug == initialSelectedCategory
      );

      if (category) {
        setValue({ value: category.id, label: category.name });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, initialSelectedCategory]);

  return (
    <MultiSelect
      values={values}
      value={value}
      onChange={(value) => {
        const category = categories.find((c) => c.id === value?.value);

        setValue(value);
        onChange(category || null);
      }}
      placeholder="Category"
    />
  );
};
