import { Suspense } from 'react';

import {
  AdminRoute,
  LoginPage,
  RedirectLoginPage,
  UnauthorizedPage,
} from 'auth/components';
import { Page404 } from 'auth/pages/Page404';
import { Route, Switch } from 'react-router-dom';
import { Spinner } from 'revibe-ui';

import { StatsPage } from 'modules/analytics/pages/StatsPage';
import { CollectionPage } from 'modules/catalog/collections/pages/CollectionPage';
import { CollectionsPage } from 'modules/catalog/collections/pages/CollectionsPage';
import { CategoriesPage } from 'modules/catalog/pages/CategoriesPage';
import { ItemsCategoryPage } from 'modules/catalog/pages/ItemsCategoryPage';
import { ItemsPage } from 'modules/catalog/pages/ItemsPage';
import { ItemsPhotosPage } from 'modules/catalog/pages/ItemsPhotosPage';
import { NewSelectionPage } from 'modules/catalog/selections/pages/NewSelectionPage';
import { SelectionPage } from 'modules/catalog/selections/pages/SelectionPage';
import { SelectionsPage } from 'modules/catalog/selections/pages/SelectionsPage';
import { HomePage } from 'modules/home/pages/HomePage';
import { PromotionsPage } from 'modules/marketing/pages/PromotionsPage';
import { OrderPage } from 'modules/orders/pages/OrderPage';
import { OrdersPage } from 'modules/orders/pages/OrdersPage';
import { OrdersStatusPage } from 'modules/orders/pages/OrdersStatusPage';
import { ShippingFeesPage } from 'modules/orders/pages/ShippingFeesPage';
import { SellerPage } from 'modules/sellers/pages/SellerPage';
import { SellersPage } from 'modules/sellers/pages/SellersPage';

import { Page } from 'shared/components';

import { AUTH_ROUTES, ROUTES, SHOP_ROUTES } from './routes';
import { CartsPage } from 'modules/orders/pages/CartsPage';

export { SHOP_ROUTES, AUTH_ROUTES, ROUTES };

export const RevibeRouter = () => {
  return (
    <Suspense
      fallback={
        <Page title="Loading">
          <Spinner />
        </Page>
      }
    >
      <Switch>
        <Route path={AUTH_ROUTES.LOGIN} exact component={LoginPage} />
        <Route
          path={AUTH_ROUTES.REDIRECT_LOGIN}
          exact
          component={RedirectLoginPage}
        />
        <AdminRoute path={ROUTES.CATEGORIES} exact Component={CategoriesPage} />
        <AdminRoute path={ROUTES.ORDER} exact Component={OrderPage} />
        <AdminRoute path={ROUTES.ORDERS} exact Component={OrdersPage} />
        <AdminRoute path={ROUTES.CARTS} exact Component={CartsPage} />
        <AdminRoute path={ROUTES.STATS} exact Component={StatsPage} />
        <AdminRoute
          path={ROUTES.ORDERS_BY_STATUS}
          exact
          Component={OrdersStatusPage}
        />
        <AdminRoute path={ROUTES.SELLERS} exact Component={SellersPage} />
        <AdminRoute path={ROUTES.SELLER} exact Component={SellerPage} />
        <AdminRoute
          path={ROUTES.MKT_PROMOTIONS}
          exact
          Component={PromotionsPage}
        />
        <AdminRoute
          path={ROUTES.ITEMS_PHOTOS}
          exact
          Component={ItemsPhotosPage}
        />
        <AdminRoute
          path={ROUTES.ITEMS_CATEGORY}
          exact
          Component={ItemsCategoryPage}
        />
        <AdminRoute path={ROUTES.ITEMS} exact Component={ItemsPage} />
        <AdminRoute path={ROUTES.COLLECTION} exact Component={CollectionPage} />
        <AdminRoute
          path={ROUTES.COLLECTIONS}
          exact
          Component={CollectionsPage}
        />
        <AdminRoute
          path={ROUTES.SELECTION_NEW}
          exact
          Component={NewSelectionPage}
        />
        <AdminRoute path={ROUTES.SELECTION} exact Component={SelectionPage} />
        <AdminRoute path={ROUTES.SELECTIONS} exact Component={SelectionsPage} />
        <AdminRoute
          path={ROUTES.SHIPPING_FEES}
          exact
          Component={ShippingFeesPage}
        />
        <AdminRoute path={ROUTES.HOME} exact Component={HomePage} />
        <Route
          path={AUTH_ROUTES.UNAUTHORIZED}
          exact
          component={UnauthorizedPage}
        />
        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
};
