import { APIItem } from 'api/items.types';
import { Carousel } from 'flowbite-react';
import { Image, SpacedContainer, Tag } from 'revibe-ui';

import { ITEM_STATUSES } from '../utils/itemStatus';

interface Props {
  item: APIItem;
  onClick: (item: APIItem) => void;
}
export const ItemPhotoPreview = ({ item, onClick }: Props) => {
  const numberOfPhotos = item.photos.length;
  const numberOfPhotosRetouched = item.photos.filter(
    (photo) => photo.is_retouched
  ).length;

  return (
    <div className="relative flex flex-col">
      <Carousel slide={false}>
        {item.photos.map((photo) => (
          <Image
            onClick={() => onClick(item)}
            key={photo.id}
            src={photo.medium_link}
            alt={item.name}
          />
        ))}
      </Carousel>
      <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
        {item.name}
      </p>
      <p className="pointer-events-none block text-sm font-medium text-gray-500">
        {item.seller.name}
      </p>

      <SpacedContainer type="horizontal" className="absolute top-0 left-0 p-4 ">
        <Tag
          className="shadow-lg"
          color={item.status === ITEM_STATUSES.APPROVED ? 'green' : 'yellow'}
        >
          {item.status}
        </Tag>
        <Tag className="shadow-lg">
          {numberOfPhotosRetouched} / {numberOfPhotos} retouched
        </Tag>
        {numberOfPhotosRetouched === 0 && (
          <Tag color="red" className="shadow-lg">
            No photos retouched
          </Tag>
        )}
      </SpacedContainer>
    </div>
  );
};
