import { SpacedContainer } from 'revibe-ui';

import { Page } from 'shared/components';

export const UnauthorizedPage = () => {
  return (
    <Page title="Not authorized">
      <SpacedContainer centered>
        <h1>401</h1>
        <div className="UnauthorizedPage">You can&apos;t access this page</div>
      </SpacedContainer>
    </Page>
  );
};
