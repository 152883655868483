import React from 'react';

import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '../../helpers/classnames';

const tableRowVariants = cva('', {
  variants: {
    divider: {
      border: 'child:border-r last:child:border-r-0',
      noborder: '',
    },
  },
  defaultVariants: {
    divider: 'border',
  },
});

interface TableProps
  extends React.TableHTMLAttributes<HTMLTableRowElement>,
    VariantProps<typeof tableRowVariants> {}

export const TableRow = React.forwardRef<HTMLTableRowElement, TableProps>(
  ({ className, children, divider = 'border', ...props }, ref) => {
    return (
      <tr
        className={cn(tableRowVariants({ divider }), className)}
        ref={ref}
        {...props}
      >
        {children}
      </tr>
    );
  }
);
TableRow.displayName = 'TableRow';
