import { ReactComponent as AddSvg } from './add.svg';
import { ReactComponent as CollectionsSvg } from './collections.svg';
import { ReactComponent as CloseSvg } from './cross.svg';
import { ReactComponent as ItemsSvg } from './items.svg';
import { ReactComponent as LogoSvg } from './logo.svg';
import { ReactComponent as OrdersSvg } from './orders.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const Logo = ({ className, onClick }: Props) => (
  <LogoSvg className={className} onClick={onClick} />
);

export const AddIcon = ({ className, onClick }: Props) => (
  <AddSvg className={className} onClick={onClick} />
);

export const CloseIcon = ({ className, onClick }: Props) => (
  <CloseSvg className={className} onClick={onClick} />
);

export const ItemsIcon = ({ className, onClick }: Props) => (
  <ItemsSvg className={className} onClick={onClick} />
);

export const OrdersIcon = ({ className, onClick }: Props) => (
  <OrdersSvg className={className} onClick={onClick} />
);

export const CollectionsIcon = ({ className, onClick }: Props) => (
  <CollectionsSvg className={className} onClick={onClick} />
);
