import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

import {
  ITEM_ORDER_STATUS,
  ORDER_STATUS,
  SELLER_ORDER_STATUS,
} from './orderStatus';

export const orderStatusToString = (orderStatus: string) => {
  switch (orderStatus) {
    case ORDER_STATUS.IN_REVIEW:
      return 'In review';
    case ORDER_STATUS.REVIEW_COMPLETED:
      return 'Delivery and payout';
    case ORDER_STATUS.CANCELED:
      return 'Canceled';
    case ORDER_STATUS.COMPLETED:
      return 'Completed';
    default:
      return 'Unknown';
  }
};
export const sellerOrderStatusToString = (orderStatus: string) => {
  switch (orderStatus) {
    case SELLER_ORDER_STATUS.IN_REVIEW:
      return 'Products availability review';
    case SELLER_ORDER_STATUS.REVIEW_COMPLETED:
      return 'Products availability review completed';
    case SELLER_ORDER_STATUS.DELIVERY_IN_PROGRESS:
      return 'Delivery in progress';
    case SELLER_ORDER_STATUS.CANCELED:
      return 'Canceled';
    case SELLER_ORDER_STATUS.PENDING_PAYOUT:
      return 'Stripe payout pending';
    case SELLER_ORDER_STATUS.PAID_OUT:
      return 'Paid out from Stripe';
    default:
      return 'Unknown';
  }
};
export const orderItemStatusToString = (orderStatus: string) => {
  switch (orderStatus) {
    case ITEM_ORDER_STATUS.IN_REVIEW:
      return 'Availability review';
    case ITEM_ORDER_STATUS.CANCELED:
      return 'Canceled';
    case ITEM_ORDER_STATUS.TO_REIMBURSE:
      return 'Pending reimbursment';
    case ITEM_ORDER_STATUS.REIMBURSED:
      return 'Reimbursed';
    case ITEM_ORDER_STATUS.TO_PRODUCE:
      return 'To produce';
    case ITEM_ORDER_STATUS.TO_SHIP:
      return 'To ship';
    case ITEM_ORDER_STATUS.SHIPPED:
      return 'Shipped';
    case ITEM_ORDER_STATUS.DELIVERED:
      return 'Delivered';
    default:
      return 'Unknown';
  }
};

export const itemStatusToString = (itemStatus: string) => {
  switch (itemStatus) {
    case ITEM_STATUSES.PENDING_APPROVAL:
      return 'To approve';
    case ITEM_STATUSES.APPROVED:
      return 'Approved';
    case ITEM_STATUSES.REJECTED:
      return 'Rejected';
    case ITEM_STATUSES.ARCHIVED:
      return 'Archived';
    default:
      return 'Unknown';
  }
};
