import { useState } from 'react';

import { Seller, getSellers, useQuery } from 'revibe-api';
import { MultiSelect, MultiSelectOption } from 'revibe-ui';

import { useToast } from 'shared/hooks';

type SellerSelectorProps = {
  onChange: (seller: Seller | null) => void;
  initialSelectedSeller: string | null;
};

export const SellerSelector = ({
  onChange,
  initialSelectedSeller,
}: SellerSelectorProps) => {
  const [value, setValue] = useState<MultiSelectOption>(null);
  const { errorToast } = useToast();
  const [{ res: sellers }] = useQuery(
    'sellers',
    async () => await getSellers({ sortByOnboardingDate: false }),
    {
      defaultValue: { res: [], totalItems: 0, totalPages: 0, currentPage: 1 },
      onError: errorToast,
      onSuccess: ({ res }) => {
        if (initialSelectedSeller !== null) {
          const seller = res.find(
            (s) => s.id.toString() === initialSelectedSeller
          );
          if (seller) {
            setValue({ value: seller.id, label: seller.name });
          }
        }
      },
    }
  );

  return (
    <MultiSelect
      values={sellers.map((seller) => ({
        label: seller.name,
        value: seller.id,
      }))}
      value={value}
      onChange={(value) => {
        setValue(value);
        const seller = sellers.find((c) => c.id === value?.value);
        onChange(seller || null);
      }}
      placeholder="Select a seller"
    />
  );
};
