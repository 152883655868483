import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Spinner } from 'revibe-ui';
import { AUTH_ROUTES } from 'router';

import { useAuthentication } from '../hooks';

type Props = RouteProps & {
  Component: React.ComponentType<any>;
};

export const AdminRoute = (adminRouteProps: Props) => {
  const { isLoggedIn, isLoading } = useAuthentication();
  return (
    <Route
      {...adminRouteProps}
      render={(props) =>
        isLoading ? (
          <Spinner />
        ) : isLoggedIn ? (
          <adminRouteProps.Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: AUTH_ROUTES.LOGIN,
            }}
          />
        )
      }
    />
  );
};
