export const arrayRemove = <T>(array: T[], i: number) => {
  if (i > -1 && i < array.length) {
    return [...array.slice(0, i), ...array.slice(i + 1)];
  }

  return array;
};
export const arrayMove = <T>(array: T[], from: number, to: number) => {
  while (from < 0) {
    from += array.length;
  }
  while (to < 0) {
    to += array.length;
  }

  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};
