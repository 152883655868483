import { useMemo } from 'react';

import countryList from 'country-list';
import { Controller, useForm } from 'react-hook-form';
import {
  DeliveryCountry,
  useMarketplaceDeliveryCountryCreate,
  useMarketplaceDeliveryCountryUpdate,
} from 'revibe-api';
import {
  Button,
  Checkbox,
  MultiSelect,
  FormControl,
  Input,
  Label,
  MultiInfoField,
  SpacedContainer,
} from 'revibe-ui';

import { useToast } from 'shared/hooks';

interface ShippingFeeCountryFormProps {
  country?: DeliveryCountry;
  onSubmit: () => void;
}

export const ShippingFeeCountryForm = ({
  country,
  onSubmit,
}: ShippingFeeCountryFormProps) => {
  const { errorToast, successToast } = useToast();
  const options = useMemo(
    () => countryList.getData().sort((a, b) => a.name.localeCompare(b.name)),
    []
  );

  const [createCountryDeliveryFees, { isLoading: isCreationLoading }] =
    useMarketplaceDeliveryCountryCreate({
      onError: errorToast,
      onSuccess: onSubmit,
    });

  const [updateCountryDeliveryFees, { isLoading: isUpdateLoading }] =
    useMarketplaceDeliveryCountryUpdate({
      onError: errorToast,
      onSuccess: onSubmit,
    });

  const isLoading = isCreationLoading || isUpdateLoading;

  const { register, watch, setValue, control, handleSubmit } = useForm({
    defaultValues: country || {
      country_code: '',
      standard: 0,
      express: 0,
      free_delivery_threshold: false,
    },
  });
  const countryCode = watch('country_code');

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        if (country) {
          await updateCountryDeliveryFees({
            body: values,
            params: {
              countryCode: country.country_code,
            },
          });
          successToast('Country configuration updated');
        } else {
          await createCountryDeliveryFees({
            body: values,
          });
          successToast('Country configuration created');
        }
      })}
    >
      <SpacedContainer>
        <h1 className="pt-4 pb-8 text-lg font-semibold">
          Add a new country delivery fees configuration
        </h1>
        <FormControl label="Country">
          <MultiSelect
            disabled={country !== undefined}
            onChange={(value) => {
              value && setValue('country_code', value.value);
            }}
            value={{
              label: countryList.getName(countryCode) || 'Unknown',
              value: country,
            }}
            values={options.map((option) => ({
              label: option.name,
              value: option.code,
            }))}
          />
        </FormControl>
        <FormControl label="Standard shipping fee">
          <MultiInfoField position="post" info="€" className="w-full">
            <Input
              step="0.01"
              containerClassName="w-full"
              {...register('standard', {
                validate: (value) => value > 0,
                valueAsNumber: true,
                required: true,
                pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              })}
              type="number"
            />
          </MultiInfoField>
        </FormControl>
        <FormControl label="Express shipping fee">
          <MultiInfoField position="post" info="€" className="w-full">
            <Input
              step="0.01"
              containerClassName="w-full"
              {...register('express', {
                validate: (value) => value > 0,
                valueAsNumber: true,
                required: true,
                pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              })}
              type="number"
            />
          </MultiInfoField>
        </FormControl>
        <div className="flex items-center space-x-2">
          <Controller
            name="free_delivery_threshold"
            control={control}
            render={({ field }) => (
              <Checkbox
                id="free_delivery_threshold"
                value={undefined}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            )}
          />
          <Label htmlFor="free_delivery_threshold">
            The customer gets free delivery if the order is above 150€
          </Label>
        </div>
        <Button isLoading={isLoading} type="submit">
          {country ? 'Update' : 'Create'}
        </Button>
      </SpacedContainer>
    </form>
  );
};
