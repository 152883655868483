import { APIItemSelection } from 'api/selections.types';

export const ITEMS_SELECTION_VALIDATION_ERRORS = {
  MISSING_NAME: 'Missing selection name',
  MISSING_DESCRIPTION: 'Missiing selection description',
};

export const validateItemsSelection = (
  collectionData: Partial<APIItemSelection>
) => {
  const errors = [];

  if (!collectionData.name) {
    errors.push(ITEMS_SELECTION_VALIDATION_ERRORS.MISSING_NAME);
  }

  return errors;
};
