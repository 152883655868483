import { useEffect, useState } from 'react';

import {
  addItemSelectionPhotos,
  editItemSelection,
  getItemSelection,
} from 'api/selections.api';
import {
  APIItemSelection,
  APIItemSelectionTranslation,
} from 'api/selections.types';
import { arrayRemove } from 'revibe-ui';
import { Formik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  Input,
  Drawer,
  SpacedContainer,
  Spinner,
  Tag,
  Textarea,
} from 'revibe-ui';
import { ROUTES } from 'routes';

import {
  ItemsSelectionDraggableGrid,
  ItemsTable,
  NoItems,
} from 'modules/catalog/components';
import { ItemsStaticTable } from 'modules/catalog/components/ItemsTable';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';
import { SellerSelector } from 'modules/sellers/components/SellerSelector';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory, useToast, useQueryStringParameters } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';
import { convertObjectToQueryParams } from 'shared/utils';
import { ObjectType } from 'shared/utils/types';

import { SelectionPhotosConfigurator } from '../components/SelectionPhotosConfigurator';
import { SelectionPhotosOrder } from '../components/SelectionPhotosOrder';

export const SelectionPage = () => {
  const { id: itemSelectionID } = useParams<{ id: string }>();
  const history = useHistory();
  const token = useLocation().search;
  const { errorToast, toast } = useToast();
  const [itemSelection, setItemSelection] = useState<APIItemSelection | null>(
    null
  );
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const fetchItemSelection = async () => {
    startFetching();
    const { data, error } = await getItemSelection(itemSelectionID);
    if (data) {
      setItemSelection(data);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchItemSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemSelectionEditRequest = async (
    itemSelectionData: Partial<
      APIItemSelection & {
        en:
          | APIItemSelectionTranslation
          | {
              description: string;
              title: string;
            };
        fr:
          | APIItemSelectionTranslation
          | {
              description: string;
              title: string;
            };
        it:
          | APIItemSelectionTranslation
          | {
              description: string;
              title: string;
            };
      }
    >,
    items: { id: string; position: number }[] | undefined,
    stopFetching: () => void
  ) => {
    const { data, error } = await editItemSelection(
      itemSelectionID,
      itemSelectionData,
      items
    );
    if (data) {
      toast('Selection updated');
      stopFetching();
    } else {
      stopFetching();
      errorToast(error);
    }
  };
  const [isOrderDrawerOpen, openOrderDrawer, closeOrderDrawer] = useBoolean();
  const [photos, setPhotos] = useState<File[]>([]);
  const [
    isFetchingPhotosUpload,
    startFetchingPhotosUpload,
    stopFetchingPhotosUpload,
  ] = useBoolean();
  const query = useQueryStringParameters();
  let filters: ObjectType<any> = {};

  for (const pair of query.entries()) {
    filters[pair[0]] = pair[1];
  }
  const handleItemSelectionPhotoUpload = async () => {
    if (!itemSelectionID) {
      return errorToast();
    }
    startFetchingPhotosUpload();
    const { data, error } = await addItemSelectionPhotos(
      itemSelectionID,
      photos
    );
    if (data) {
      stopFetchingPhotosUpload();
      fetchItemSelection();
      toast('itemSelection-updated-text');
    } else {
      stopFetchingPhotosUpload();
      errorToast(error);
    }
  };

  if (isFetching || !itemSelection) {
    return (
      <LoggedInPage title="Item Selection">
        <SpacedContainer centered>
          <Spinner />
        </SpacedContainer>
      </LoggedInPage>
    );
  }

  return (
    <LoggedInPage
      hideOverflow
      title="Item Selection"
      breadcrumbs={[
        {
          label: 'Selections',
          to: ROUTES.SELECTIONS,
        },
        {
          label: itemSelection.name,
        },
      ]}
    >
      <SpacedContainer>
        <Formik
          initialValues={{
            name: itemSelection.name,
            published: itemSelection.published,
            items: itemSelection.items,
            photos: itemSelection.photos,
            en: itemSelection.translations.find(
              (t) => t.localization === 'en'
            ) || {
              description: '',
              title: '',
            },
            fr: itemSelection.translations.find(
              (t) => t.localization === 'fr'
            ) || {
              description: '',
              title: '',
            },
            it: itemSelection.translations.find(
              (t) => t.localization === 'it'
            ) || {
              description: '',
              title: '',
            },
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await handleItemSelectionEditRequest(
              {
                translations: [
                  { localization: 'en', ...values.en },
                  { localization: 'fr', ...values.fr },
                  { localization: 'it', ...values.it },
                ],
              },
              undefined,
              () => actions.setSubmitting(false)
            );
          }}
        >
          {({
            setFieldValue,
            isSubmitting,
            setSubmitting,
            values,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <SpacedContainer>
                <PageHeading>{values.name}</PageHeading>
                <SpacedContainer type="horizontal" centered className="w-fit">
                  <Button
                    variant="subtle"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.pushBlank(
                        `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}/shop/selections/${itemSelection.slug}?adminView=true`
                      );
                    }}
                  >
                    Preview
                  </Button>
                  {values.published ? (
                    <>
                      <Button
                        type="button"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={async () => {
                          setSubmitting(true);
                          setFieldValue('published', false);
                          await handleItemSelectionEditRequest(
                            { ...values, published: false },
                            undefined,
                            () => setSubmitting(false)
                          );
                        }}
                      >
                        Unpublish
                      </Button>
                      <Tag>Published</Tag>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={async () => {
                          setSubmitting(true);
                          setFieldValue('published', true);
                          await handleItemSelectionEditRequest(
                            { ...values, published: true },
                            undefined,
                            () => setSubmitting(false)
                          );
                        }}
                      >
                        Publish
                      </Button>
                      <Tag color="red">In draft</Tag>
                    </>
                  )}
                </SpacedContainer>
                <SpacedContainer type="fluid">
                  <div className="bg-gray-50 p-4">
                    <SpacedContainer>
                      <SpacedContainer>
                        <h2>Translations</h2>
                        <SpacedContainer type="horizontal">
                          <SpacedContainer>
                            <FormControl label="EN title 🇬🇧">
                              <Input
                                value={values.en.title || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'en.title',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                            <FormControl label="EN Description 🇬🇧">
                              <Textarea
                                value={values.en.description || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'en.description',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                          </SpacedContainer>
                          <SpacedContainer>
                            <FormControl label="FR title 🇫🇷">
                              <Input
                                value={values.fr.title || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'fr.title',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                            <FormControl label="FR Description 🇫🇷">
                              <Textarea
                                value={values.fr.description || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'fr.description',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                          </SpacedContainer>
                          <SpacedContainer>
                            <FormControl label="IT title 🇮🇹">
                              <Input
                                value={values.it.title || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'it.title',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                            <FormControl label="IT Description 🇮🇹">
                              <Textarea
                                value={values.it.description || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'it.description',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                          </SpacedContainer>
                        </SpacedContainer>
                      </SpacedContainer>
                      <Button
                        type="submit"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Save changes
                      </Button>
                    </SpacedContainer>
                  </div>
                  <div className="bg-gray-50 p-4">
                    <SpacedContainer>
                      <h2>
                        Manage selection photos ({itemSelection?.photos?.length}
                        /5)
                      </h2>
                      <p>Manage the photos order</p>
                      <SelectionPhotosOrder
                        itemSelectionID={itemSelectionID}
                        photos={itemSelection.photos}
                        onDelete={fetchItemSelection}
                      />
                      <SelectionPhotosConfigurator
                        values={photos}
                        onValueChanged={(values) => {
                          setPhotos(values);
                        }}
                        existingPhotosCount={itemSelection?.photos?.length}
                      />
                      <Button
                        onClick={handleItemSelectionPhotoUpload}
                        type="button"
                        isLoading={isFetchingPhotosUpload}
                        disabled={photos.length < 1 || isFetchingPhotosUpload}
                      >
                        Add photos
                      </Button>
                    </SpacedContainer>
                  </div>
                </SpacedContainer>
                <SpacedContainer>
                  <div className="bg-gray-50 p-4">
                    <SpacedContainer>
                      <h1>Items Added</h1>
                      <Button
                        onClick={async () => {
                          await editItemSelection(
                            itemSelection.id,
                            {},
                            values.items.map((i, index) => ({
                              id: i.id,
                              position: index,
                            }))
                          );
                          history.go(0);
                        }}
                      >
                        Save list
                      </Button>
                      <Button variant="subtle" onClick={openOrderDrawer}>
                        Change order of items
                      </Button>
                      {values.items.length ? (
                        <ItemsStaticTable
                          items={values.items}
                          actionLabel="Remove"
                          onItemSelected={(item) => {
                            if (!item) {
                              return;
                            }
                            const index = values.items.findIndex(
                              (i) => i.id === item.id
                            );
                            setFieldValue(
                              'items',
                              arrayRemove(values.items, index)
                            );
                          }}
                        />
                      ) : (
                        <NoItems />
                      )}
                    </SpacedContainer>
                  </div>
                  <div className="bg-gray-50 p-4">
                    <SpacedContainer>
                      <SpacedContainer>
                        <h1>Items to add</h1>

                        <FormControl label="Seller">
                          <SellerSelector
                            initialSelectedSeller={
                              filters[ITEM_FILTERS.SELLER] || null
                            }
                            onChange={(seller) => {
                              const url = `${
                                itemSelection.id
                              }?${convertObjectToQueryParams({
                                ...filters,
                                [ITEM_FILTERS.SELLER]: seller?.id,
                                [ITEM_FILTERS.SELLER_NAME]: seller?.name,
                              })}`;
                              history.push(url);
                            }}
                          />
                        </FormControl>
                      </SpacedContainer>
                      {filters[ITEM_FILTERS.SELLER] ? (
                        <ItemsTable
                          resetToken={token + values.items.length}
                          onItemSelected={(item) => {
                            if (item) {
                              setFieldValue('items', [...values.items, item]);
                            }
                          }}
                          filters={{
                            [ITEM_FILTERS.SELLER]: filters[ITEM_FILTERS.SELLER],
                            [ITEM_FILTERS.STATUS]: [
                              ITEM_STATUSES.APPROVED,
                              ITEM_STATUSES.PENDING_APPROVAL,
                              ITEM_STATUSES.REJECTED,
                            ].join(','),
                            [ITEM_FILTERS.EXCLUDE]: values.items
                              .map((i) => i.id)
                              .join(','),
                          }}
                          actionLabel="Add"
                        />
                      ) : (
                        <p>Select a seller first</p>
                      )}
                    </SpacedContainer>
                  </div>
                </SpacedContainer>
              </SpacedContainer>
              <Drawer
                scrollable
                open={isOrderDrawerOpen}
                onOpenChange={closeOrderDrawer}
              >
                <SpacedContainer>
                  <p>
                    If you close the Drawer without saving the configuration any
                    change to the order will be lost.
                  </p>
                  <ItemsSelectionDraggableGrid
                    selectionID={itemSelection.id}
                    items={values.items}
                  />
                </SpacedContainer>
              </Drawer>
            </form>
          )}
        </Formik>
      </SpacedContainer>
    </LoggedInPage>
  );
};
