import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  children: React.ReactNode;
}

export const InfoBanner = ({ children }: Props) => {
  return (
    <div className="max-w-5xl rounded-md bg-purple-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-purple-800"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-purple-800">{children}</p>
        </div>
      </div>
    </div>
  );
};

export const WarningBanner = ({ children }: Props) => {
  return (
    <div className="max-w-5xl rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-yellow-800"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-yellow-800">{children}</p>
        </div>
      </div>
    </div>
  );
};

export const ErrorBanner = ({ children }: Props) => {
  return (
    <div className="max-w-5xl rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-red-800">{children}</p>
        </div>
      </div>
    </div>
  );
};
