import { getCategories, useQuery } from 'revibe-api';

export const useCategories = () => {
  const [categories, { isFetching, refetch }] = useQuery(
    'categories',
    async () => await getCategories({ translations: true }),
    {
      defaultValue: [],
    }
  );

  return {
    categories,
    isLoading: isFetching,
    refresh: refetch,
  };
};
