import { Collection } from '../types/collections.types';
import { SellerOrder } from '../types/orders.types';
import { PaginationResponse } from '../types/pagination.types';
import { Seller, SellerCreation } from '../types/sellers.types';
import { apiCall } from '../utils/apiCall';
import { PaginationParams } from '../utils/pagination';
import { convertObjectToQueryParams } from '../utils/routes';

export const getSellers = async (
  options: {
    sortByOnboardingDate?: boolean;
  } & PaginationParams
) =>
  apiCall({
    endpoint: `sellers?${convertObjectToQueryParams(options)}`,
    method: 'GET',
    reshaper: (data: PaginationResponse<Seller>) => data,
  });

export const getActiveSellers = async (options: {
  includeSellersWithoutApprovedItems?: boolean;
}) =>
  apiCall({
    endpoint: `sellers/active?${convertObjectToQueryParams(options)}`,
    method: 'GET',
    reshaper: (data: Seller[]) => data,
  });

export const getSeller = async (sellerID: string) =>
  apiCall({
    endpoint: `sellers/${sellerID}?isInternal=true`,
    method: 'GET',
    reshaper: (data: Seller) => data,
  });

export const getSellerCollections = async (sellerID: string) =>
  apiCall({
    endpoint: `sellers/${sellerID}/collections`,
    method: 'GET',
    reshaper: (res: PaginationResponse<Collection>) => res.res,
  });

export const getSellerOrders = async (sellerID: string) =>
  apiCall({
    endpoint: `sellers/${sellerID}/orders`,
    method: 'GET',
    reshaper: (res: PaginationResponse<SellerOrder>) => res,
  });

export const getSellerOrder = async (sellerID: string, orderID: string) =>
  apiCall({
    endpoint: `sellers/${sellerID}/orders/${orderID}`,
    method: 'GET',
    reshaper: (res: SellerOrder) => res,
  });

export const checkSellerExistance = async (email: string) =>
  apiCall({
    endpoint: `sellers/check/${email}`,
    method: 'GET',
    reshaper: (data: { exists: boolean }) => data,
  });

export const updateSellerLogo = async (
  sellerID: string | number,
  logo: File
) => {
  const data = new FormData();
  data.append('files', logo);
  return await apiCall({
    endpoint: `sellers/${sellerID}/logo`,
    method: 'POST',
    body: data,
    reshaper: (data: any) => data.res,
    rawBody: true,
  });
};

export const createNewSellerAccount = async (seller: SellerCreation) => {
  return await apiCall({
    endpoint: `sellers`,
    method: 'POST',
    body: seller,
    reshaper: (data: Seller) => data,
  });
};

export const refreshSellerOnboarding = async (id: string) => {
  return await apiCall({
    endpoint: `sellers/stripe/onboard/${id}`,
    method: 'GET',
    reshaper: (data: { url: string }) => data,
  });
};
