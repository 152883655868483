export const AUTH_ROUTES = {
  LOGIN: '/login',
  REDIRECT_LOGIN: '/redirect-login',
  SIGNUP: '/signup',
  SIGNUP_CONFIRMATION: '/signup-confirmation',
  UNAUTHORIZED: '/unauthorized',
  RESET_PASSWORD: '/reset-password',
};

export const ROUTES = {
  HOME: '/',
  STATS: '/stats',
  SELLERS: '/sellers',
  SELLER: '/sellers/:id',
  USERS: '/users',
  ITEMS: '/items',
  ITEMS_CATEGORY: '/items/category',
  ITEMS_PHOTOS: '/items/photos',
  MKT: '/marketing',
  CATEGORIES: '/categories',
  CARTS: '/carts',
  ORDERS: '/orders',
  ORDERS_BY_STATUS: '/orders/status/:status',
  ORDER: '/orders/:id',
  COLLECTIONS: '/collections',
  COLLECTION: '/collections/:id',
  SELECTIONS: '/selections',
  SELECTION_NEW: '/selections/new-selection',
  SELECTION: '/selections/:id',
  SHIPPING_FEES: '/shipping-fees',
  MKT_PROMOTIONS: '/marketing/promotions',
};

export const SHOP_ROUTES = {
  ITEM: '/shop/item',
  ITEMS: '/shop/items',
};
