import { useState } from 'react';

import {
  APIMarketplaceOrder,
  APIOrderItem,
  APISellerOrder,
} from 'api/orders.types';
import {
  Button,
  FormControl,
  Input,
  MultiButton,
  SpacedContainer,
  Table,
} from 'revibe-ui';

import { NoOrders } from 'modules/orders/components/NoOrders';

type OrderAction = {
  label: string;
  onClick: (order: APIMarketplaceOrder) => void;
  isLoading?: boolean;
};
type SellerOrderAction = {
  label: string;
  onClick: (order: APISellerOrder) => void;
  isLoading?: boolean;
};
type OrderItemAction = {
  label: string;
  onClick: (order: APISellerOrder) => void;
  isLoading?: boolean;
};

export const OrdersTable = ({
  orders,
  isLoading,
  actions,
}: {
  orders: APIMarketplaceOrder[];
  isLoading: boolean;
  actions: OrderAction[];
}) => {
  const [orderID, setOrderID] = useState('');
  const [customerName, setCustomerName] = useState('');
  const filterByOrderID = (order: APIMarketplaceOrder) => {
    if (!orderID) {
      return true;
    }

    return order.order_id.toLowerCase().includes(orderID.toLowerCase());
  };
  const filterByCustomerName = (order: APIMarketplaceOrder) => {
    if (!customerName) {
      return true;
    }

    return order.shipping_address.customer_name
      .toLowerCase()
      .includes(customerName.toLowerCase());
  };

  const rows = orders
    .filter((order) => {
      return filterByCustomerName(order) && filterByOrderID(order);
    })
    .map((order) => [
      order.order_id,
      order.creation_date,
      order.shipping_address.customer_name,
      Array.isArray(order.orders) ? order.orders.length : 1,
      actions.length === 1 ? (
        <Button
          variant="subtle"
          className="w-full"
          isLoading={actions[0].isLoading}
          disabled={actions[0].isLoading}
          onClick={() => actions[0].onClick(order)}
        >
          {actions[0].label}
        </Button>
      ) : (
        <MultiButton
          mainAction={{
            label: actions[0].label,
            onClick: () => actions[0].onClick(order),
          }}
          secondaryActions={actions.map((action) => ({
            label: action.label,
            onClick: () => action.onClick(order),
          }))}
        />
      ),
    ]);

  if (!isLoading && orders.length === 0) {
    return <NoOrders />;
  }

  return (
    <SpacedContainer>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <FormControl label="Order ID">
          <Input
            placeholder="Order ID"
            value={orderID}
            onChange={(e) => setOrderID(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl label="Customer">
          <Input
            placeholder="Customer name"
            value={customerName}
            onChange={(e) => setCustomerName(e.currentTarget.value)}
          />
        </FormControl>
      </div>
      <Table
        isLoading={isLoading}
        maxRows={15}
        columns={[
          'Order ID',
          'Order Date',
          'Customer',
          '# of Seller orders',
          'Actions',
        ]}
        data={rows}
      />
    </SpacedContainer>
  );
};

export const SellerOrdersTable = ({
  orders,
  isLoading,
  actions,
}: {
  orders: APISellerOrder[];
  isLoading: boolean;
  actions: SellerOrderAction[];
}) => {
  const [orderID, setOrderID] = useState('');
  const [seller, setSeller] = useState('');
  const [customerName, setCustomerName] = useState('');
  const filterByOrderID = (order: APISellerOrder) => {
    if (!orderID) {
      return true;
    }

    return order.order_id.toLowerCase().includes(orderID.toLowerCase());
  };
  const filterBySellerName = (order: APISellerOrder) => {
    if (!seller) {
      return true;
    }

    return order.seller.name.toLowerCase().includes(seller.toLowerCase());
  };
  const filterByCustomerName = (order: APISellerOrder) => {
    if (!customerName) {
      return true;
    }

    return order.shipping_address.customer_name
      .toLowerCase()
      .includes(customerName.toLowerCase());
  };
  const rows = orders
    .filter((order) => {
      return (
        filterBySellerName(order) &&
        filterByOrderID(order) &&
        filterByCustomerName(order)
      );
    })
    .map((order) => [
      order.order_id,
      order.creation_date,
      order.shipping_address.customer_name,
      order.seller.name,
      actions.length === 1 ? (
        <Button
          variant="subtle"
          className="w-full"
          isLoading={actions[0].isLoading}
          disabled={actions[0].isLoading}
          onClick={() => actions[0].onClick(order)}
        >
          {actions[0].label}
        </Button>
      ) : (
        <MultiButton
          mainAction={{
            label: actions[0].label,
            onClick: () => actions[0].onClick(order),
          }}
          secondaryActions={actions.map((action) => ({
            label: action.label,
            onClick: () => action.onClick(order),
          }))}
        />
      ),
    ]);

  if (!isLoading && orders.length === 0) {
    return <NoOrders />;
  }

  return (
    <SpacedContainer>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <FormControl label="Order ID">
          <Input
            placeholder="Order ID"
            value={orderID}
            onChange={(e) => setOrderID(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl label="Seller">
          <Input
            placeholder="Seller name"
            value={seller}
            onChange={(e) => setSeller(e.currentTarget.value)}
          />
        </FormControl>{' '}
        <FormControl label="Customer">
          <Input
            placeholder="Customer name"
            value={customerName}
            onChange={(e) => setCustomerName(e.currentTarget.value)}
          />
        </FormControl>
      </div>
      <Table
        isLoading={isLoading}
        maxRows={15}
        columns={[
          'Group Order ID',
          'Order Date',
          'Customer',
          'Seller',
          'Actions',
        ]}
        data={rows}
      />
    </SpacedContainer>
  );
};

export const OrderItemsTable = ({
  items,
  isLoading,
  actions,
}: {
  items: (APIOrderItem & { order: APISellerOrder })[];
  isLoading: boolean;
  actions: OrderItemAction[];
}) => {
  const rows = items.map((item) => [
    item.order.order_id,
    item.order.creation_date,
    item.order.shipping_address.customer_name,
    item.name,
    item.seller.name,
    actions.length === 1 ? (
      <Button
        variant="subtle"
        className="w-full"
        isLoading={actions[0].isLoading}
        disabled={actions[0].isLoading}
        onClick={() => actions[0].onClick(item.order)}
      >
        {actions[0].label}
      </Button>
    ) : (
      <MultiButton
        mainAction={{
          label: actions[0].label,
          onClick: () => actions[0].onClick(item.order),
        }}
        secondaryActions={actions.map((action) => ({
          label: action.label,
          onClick: () => action.onClick(item.order),
        }))}
      />
    ),
  ]);

  if (!isLoading && items.length === 0) {
    return <NoOrders />;
  }

  return (
    <Table
      columns={[
        'Group Order ID',
        'Order Date',
        'Customer',
        'Product name',
        'Seller',
        'Actions',
      ]}
      data={rows}
      isLoading={isLoading}
      maxRows={15}
    />
  );
};
