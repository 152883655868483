import { CategoryTranslationsEdit } from '../types/categories.types';
import { apiCall } from '../utils/apiCall';

export const updateCategoryTranslations = async ({
  categoryID,
  translations,
}: {
  categoryID: number;
  translations: CategoryTranslationsEdit;
}) =>
  apiCall({
    endpoint: `categories/${categoryID}/translations`,
    method: 'PUT',
    body: translations,
    reshaper: (data: { updated: boolean }) => data,
  });

export const updateCategoryPhoto = async ({
  id,
  photo,
  type,
}: {
  id: number;
  photo: File;
  type: string;
}) => {
  const data = new FormData();
  data.append('files', photo);
  return await apiCall({
    endpoint: `categories/${id}/photos/${type}`,
    method: 'POST',
    body: data,
    reshaper: (data: any) => data,
    rawBody: true,
  });
};
