import { getUser } from 'api/users.api';
import { AdminUser } from 'api/users.types';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type State = {
  loading: 'idle' | 'pending';
  user: AdminUser | null;
  currentRequestId: any;
  error: any;
};

const initialState: State = {
  loading: 'idle',
  user: null,
  currentRequestId: undefined,
  error: undefined,
};

export const fetchUserById = createAsyncThunk(
  'user/fetchByIdStatus',
  async (
    { userId, refresh }: { userId: string; refresh: boolean },
    { getState, rejectWithValue, requestId }
  ) => {
    const { currentRequestId, loading } = (
      getState() as {
        user: State;
      }
    ).user;
    if ((!refresh && loading !== 'pending') || requestId !== currentRequestId) {
      return;
    }
    const { data, error } = await getUser(userId);
    if (error) {
      return rejectWithValue(error);
    }
    return data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserById.pending as any]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchUserById.fulfilled as any]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.user = action.payload;
        state.currentRequestId = undefined;
      }
    },
    [fetchUserById.rejected as any]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const userReducer = userSlice.reducer;
export const selectUser = (state: { user: State }) => state.user.user;
export const selectIsLoadingUser = (state: { user: State }) =>
  state.user.loading === 'pending';
