import { useEffect, useState } from 'react';

import { getItemSelections } from 'api/selections.api';
import { APIItemSelection } from 'api/selections.types';
import { Button, SpacedContainer, Table, Tag } from 'revibe-ui';
import { ROUTES } from 'routes';

import { LoggedInPage, NoData } from 'shared/components';
import { SectionHeading } from 'shared/components/SectionHeading';
import { useHistory, useToast } from 'shared/hooks';
import { getDynamicRouteUrl } from 'shared/utils';
import { useBoolean } from 'revibe-ui';

import { CollectionsIcon } from 'ui/icons';

export const SelectionsPage = () => {
  const [selections, setItemSelections] = useState<APIItemSelection[]>([]);
  const { errorToast } = useToast();
  const [isFetching, startFetching, stopFetching] = useBoolean(true);
  const history = useHistory();

  const fetchItemSelections = async () => {
    startFetching();
    const { data, error } = await getItemSelections();
    if (data) {
      setItemSelections(data);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchItemSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoggedInPage hideOverflow title="Item Selections">
      {!isFetching && selections.length === 0 ? (
        <NoData
          icon={<CollectionsIcon />}
          heading="No selections yet"
          text="Create the first one"
          closeButton={
            <Button onClick={() => history.push(ROUTES.SELECTION_NEW)}>
              Create
            </Button>
          }
        />
      ) : (
        <SpacedContainer>
          <SectionHeading
            heading="Editorial selections"
            subheading={
              <>
                Custom selections that can be referenced accross the
                marketplace. Note: a selection and its items are visible only if
                the selection is <strong>published</strong>
              </>
            }
            action={{
              label: 'Create new selection',
              onClick: () => history.push(ROUTES.SELECTION_NEW),
            }}
          />

          <Table
            isLoading={isFetching}
            columns={['Name', 'Status', '']}
            hasSortableHeadings
            data={selections.map((selection) => [
              selection.name,
              <Tag color={selection.published ? 'green' : 'red'}>
                {selection.published ? 'PUBLISHED' : 'NOT PUBLISHED'}
              </Tag>,
              <Button
                variant="subtle"
                onClick={() =>
                  history.push(
                    getDynamicRouteUrl(ROUTES.SELECTION, {
                      id: selection.id,
                    })
                  )
                }
              >
                Manage
              </Button>,
            ])}
          />
        </SpacedContainer>
      )}
    </LoggedInPage>
  );
};
