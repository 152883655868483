import { useState } from 'react';

import { Category } from 'revibe-api';
import { Button, Drawer, SpacedContainer, Table } from 'revibe-ui';

import { LoggedInPage } from 'shared/components';
import { SectionHeading } from 'shared/components/SectionHeading';
import { useCategories } from 'shared/hooks/useCategories';

import { CategoryDetails } from '../components/CategoryDetails';
import { getCategoryGroupLabelFromID } from '../utils/categories';

export const CategoriesPage = () => {
  const { categories } = useCategories();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );

  return (
    <LoggedInPage
      hideOverflow
      title="Categories"
      breadcrumbs={[{ label: 'Product categories' }]}
    >
      <SpacedContainer>
        <SectionHeading
          heading="Categories"
          subheading="Manage our product categories. You can change their name, description as well as their related photos and transaltions."
        />

        <Table
          columns={['Name', 'Group', '']}
          hasSortableHeadings
          data={categories.map((cat) => [
            cat.name,
            getCategoryGroupLabelFromID(cat.group),
            <Button onClick={() => setSelectedCategory(cat)}>Edit</Button>,
          ])}
        />
        <Drawer
          scrollable
          open={selectedCategory !== null}
          onOpenChange={() => setSelectedCategory(null)}
          position="right"
        >
          {selectedCategory && (
            <CategoryDetails
              onUpdate={() => setSelectedCategory(null)}
              category={selectedCategory}
            />
          )}
        </Drawer>
      </SpacedContainer>
    </LoggedInPage>
  );
};
