import { cn } from 'revibe-ui';
import { SpacedContainer } from 'revibe-ui';
import { ROUTES } from 'routes';

import {
  CashIcon,
  ClipboardListIcon,
  CollectionIcon,
  DatabaseIcon,
  PhotographIcon,
  ShoppingBagIcon,
  StarIcon,
  UsersIcon,
  ViewListIcon,
} from '@heroicons/react/outline';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';

const catalogActions = [
  {
    title: 'Products',
    text: 'Change products approval status, select top products and order categories listings',
    href: ROUTES.ITEMS,
    icon: ClipboardListIcon,
  },
  {
    title: 'Sellers',
    text: 'All Revibe sellers, login as one of them and edit their informations',
    href: ROUTES.SELLERS,
    icon: UsersIcon,
  },
  {
    title: 'Photos',
    text: 'Download, edit and order all the catalog photos from a single place',
    href: ROUTES.ITEMS_PHOTOS,
    icon: PhotographIcon,
  },
  {
    title: 'Categories',
    text: 'Manage categories translations and photos',
    href: ROUTES.CATEGORIES,
    icon: ViewListIcon,
  },
  {
    title: 'Collections & Drops',
    text: 'Have a quick look and translate sellers products collections',
    href: ROUTES.COLLECTIONS,
    icon: CollectionIcon,
  },
  {
    title: 'Editorial Selections',
    text: 'Create and manage custom product selections that can be displayed across the website',
    href: ROUTES.SELECTIONS,
    icon: StarIcon,
  },
];

const ordersActions = [
  {
    title: 'Orders',
    text: "Operation's heaven: update orders status and details",
    href: ROUTES.ORDERS,
    icon: ShoppingBagIcon,
  },
  {
    title: 'Stats',
    text: 'Realtime (almost) data about main Revibe KPIs',
    href: ROUTES.STATS,
    icon: DatabaseIcon,
  },
];

const mktActions = [
  {
    title: 'Promotions',
    text: 'Promo codes and promo codes and promo codes',
    href: ROUTES.MKT_PROMOTIONS,
    icon: CashIcon,
  },
];

export const HomePage = () => {
  return (
    <LoggedInPage title="Homepage">
      <SpacedContainer>
        <PageHeading>Welcome to Revibe Admin Tools</PageHeading>
        <h2 className="mt-4 text-lg font-bold">Catalog Management</h2>
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 bg-gray-200 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {catalogActions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={cn(
                actionIdx === 0
                  ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                  : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === catalogActions.length - 2
                  ? 'sm:rounded-bl-lg'
                  : '',
                actionIdx === catalogActions.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
            >
              <div>
                <span
                  className={cn(
                    'text-purple',
                    'bg-purple-100',
                    'inline-flex rounded-lg p-3 ring-4 ring-white'
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  <a href={action.href} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </a>
                </h3>
                <p className="mt-2 text-sm text-gray-500">{action.text}</p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
        <h2 className="mt-4 text-lg font-bold">Operations</h2>
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 bg-gray-200 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {ordersActions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={cn(
                actionIdx === 0
                  ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                  : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === ordersActions.length - 2
                  ? 'sm:rounded-bl-lg'
                  : '',
                actionIdx === ordersActions.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
            >
              <div>
                <span
                  className={cn(
                    'text-purple',
                    'bg-purple-100',
                    'inline-flex rounded-lg p-3 ring-4 ring-white'
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  <a href={action.href} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </a>
                </h3>
                <p className="mt-2 text-sm text-gray-500">{action.text}</p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
        <h2 className="mt-4 text-lg font-bold">Marketing</h2>
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 bg-gray-200 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {mktActions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={cn(
                actionIdx === 0
                  ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                  : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === mktActions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === mktActions.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
            >
              <div>
                <span
                  className={cn(
                    'text-purple',
                    'bg-purple-100',
                    'inline-flex rounded-lg p-3 ring-4 ring-white'
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  <a href={action.href} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </a>
                </h3>
                <p className="mt-2 text-sm text-gray-500">{action.text}</p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
