import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

type PasswordViewProps = {
  hidePassword: () => void;
  isPasswordShown: boolean;
  showPassword: () => void;
};

export const PasswordView = ({
  hidePassword,
  showPassword,
  isPasswordShown,
}: PasswordViewProps) => {
  if (isPasswordShown) {
    return (
      <div onClick={hidePassword}>
        <EyeSlashIcon
          className="h-5 w-5 cursor-pointer text-gray-400"
          aria-hidden="true"
        />
      </div>
    );
  }

  return (
    <div onClick={showPassword}>
      <EyeIcon
        className="h-5 w-5 cursor-pointer text-gray-400"
        aria-hidden="true"
      />
    </div>
  );
};
