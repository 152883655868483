import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { cn } from '../../helpers/classnames';
import { useBoolean } from '../../hooks';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { PasswordView } from './PasswordView';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: React.ReactNode;
  containerClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, containerClassName, error, type, ...props }, ref) => {
    const [isPasswordShown, showPassword, hidePassword] = useBoolean(false);

    return (
      <div className={cn('relative w-full', containerClassName)}>
        <input
          onWheel={(e) => e.currentTarget.blur()}
          type={isPasswordShown ? 'text' : type}
          className={cn(
            'flex h-10 w-full border border-slate-300 bg-transparent bg-white py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-1 focus:ring-slate-300 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
            error !== undefined &&
              'border-red-300 text-red-500 focus:border-red-500 focus:outline-none focus:ring-red-500',
            className
          )}
          ref={ref}
          {...props}
        />
        <div className="absolute inset-y-0 right-0 flex items-center space-x-2 pr-3">
          {error !== undefined && (
            <Popover>
              <PopoverTrigger>
                <div>
                  <ExclamationCircleIcon className="h-5 w-5 cursor-pointer text-red-500" />
                  <span className="sr-only">Open popover</span>
                </div>
              </PopoverTrigger>
              <PopoverContent className="border-red-500 text-sm text-slate-800">
                {error}
              </PopoverContent>
            </Popover>
          )}
          {type === 'password' && (
            <PasswordView
              isPasswordShown={isPasswordShown}
              hidePassword={hidePassword}
              showPassword={showPassword}
            />
          )}
        </div>
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
