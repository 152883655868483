import React from 'react';

import { cn } from '../../helpers/classnames';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: any;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <textarea
        rows={5}
        aria-invalid={!!error}
        className={cn(
          'flex h-20 w-full border border-slate-300 bg-white py-2 px-3 text-sm placeholder:text-slate-400 focus:border-slate-300 focus:outline-none focus:ring-1 focus:ring-slate-300 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
          className,
          !!error &&
            'border-red-300 text-red-900 focus:border-red-500 focus:outline-none'
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
