import { APIItemsCollection } from './collections.types';
import { PaginationResponse } from './pagination.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getCollection = async (collectionID: string) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/collections/${collectionID}`,
    method: 'GET',
    reshaper: (res: APIItemsCollection) => res,
  });
};

export const getCollections = async () => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/collections`,
    method: 'GET',
    reshaper: (res: PaginationResponse<APIItemsCollection>) => res.res,
  });
};

export const editItemCollection = async (
  sellerID: string,
  collectionID: string,
  collection: Partial<APIItemsCollection>
) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/collections/${collectionID}`,
    body: collection,
    method: 'PUT',
    reshaper: (res: APIItemsCollection) => res,
  });
};
