import { createItemSelection } from 'api/selections.api';
import { APIItemSelection } from 'api/selections.types';
import { Formik } from 'formik';
import { Button, FormControl, Input, SpacedContainer } from 'revibe-ui';
import { ROUTES } from 'routes';

import { validateItemsSelection } from 'modules/catalog/utils/itemValidator';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory, useToast } from 'shared/hooks';
import { getDynamicRouteUrl } from 'shared/utils';

export const NewSelectionPage = () => {
  const history = useHistory();
  const { errorToast, toast } = useToast();

  const handleItemSelectionCreationRequest = async (
    itemSelectionData: Partial<APIItemSelection>,
    stopFetching: () => void
  ) => {
    const errors = validateItemsSelection(itemSelectionData);
    if (errors.length !== 0) {
      stopFetching();
      window.scrollTo(0, 0);
      return;
    }
    return addItemSelection(itemSelectionData, stopFetching);
  };
  const addItemSelection = async (
    itemSelectionData: Partial<APIItemSelection>,
    stopFetching: () => void
  ) => {
    const { data, error } = await createItemSelection(itemSelectionData);
    if (data) {
      toast('Selection created');
      stopFetching();
      history.push(
        getDynamicRouteUrl(ROUTES.SELECTION, {
          id: data.id,
        })
      );
    } else {
      stopFetching();
      errorToast(error);
    }
  };
  return (
    <LoggedInPage
      title="Add new selection"
      breadcrumbs={[
        {
          label: 'Selections',
          to: ROUTES.SELECTIONS,
        },
        {
          label: 'New selection',
        },
      ]}
    >
      <SpacedContainer>
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await handleItemSelectionCreationRequest(
              {
                name: values.name,
              },
              () => actions.setSubmitting(false)
            );
          }}
        >
          {({ setFieldValue, isSubmitting, values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <SpacedContainer>
                <PageHeading>Create a selection</PageHeading>
                <div className="max-w-3xl bg-gray-50 p-4">
                  <FormControl label="Name *">
                    <Input
                      value={values.name}
                      placeholder="Streetwear"
                      onChange={(e) => {
                        setFieldValue('name', e.currentTarget.value);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Create
                </Button>
              </SpacedContainer>
            </form>
          )}
        </Formik>
      </SpacedContainer>
    </LoggedInPage>
  );
};
