import { SpacedContainer } from 'revibe-ui';

import { ItemsIcon } from 'ui/icons';

export const NoItems = () => {
  return (
    <div className="flex w-full flex-col items-center">
      <SpacedContainer centered>
        <ItemsIcon />
        <h1 className="text-lg">There are no items</h1>
      </SpacedContainer>
    </div>
  );
};
