import { useEffect } from 'react';

import { useAuthentication } from 'auth/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserById,
  selectIsLoadingUser,
  selectUser,
} from 'redux/features/user';

export const useUser = () => {
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoadingUser);
  const { userID } = useAuthentication();
  const dispatch = useDispatch();

  const fetchUser = async (refresh = false) => {
    if (!userID) {
      return;
    }
    dispatch(fetchUserById({ userId: userID, refresh }));
    // userContext.fetchUser(id, refresh);
  };
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    userID,
    isLoading,
    refresh: async () => await fetchUser(true),
  };
};
