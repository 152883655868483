import { useState } from 'react';

import { addGlobalPromotion } from 'api/marketing.api';
import {
  Button,
  FormControl,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SpacedContainer,
} from 'revibe-ui';

import { useBoolean } from 'revibe-ui';
import { useToast } from 'shared/hooks';

export const AddGlobalPromotionSection = () => {
  const [newPromotionName, setNewPromotionName] = useState('');
  const [newPromotionType, setNewPromotionType] =
    useState<string>('percentage');
  const [newPromotionAmount, setNewPromotionAmount] = useState(0);
  const [newPromotionCode, setNewPromotionCode] = useState('');
  const [isAddingPromotion, startAddingPromotion, stopAddingPromotion] =
    useBoolean();
  const { errorToast, toast } = useToast();
  const handleAddUsersToPromotion = async () => {
    if (!newPromotionAmount) {
      return errorToast('Invalid promotion amount');
    }
    if (!newPromotionName) {
      return errorToast('Invalid promotion name');
    }
    if (!newPromotionCode) {
      return errorToast('Invalid promotion code');
    }
    if (newPromotionType !== 'percentage' && newPromotionType !== 'cash') {
      return errorToast('Invalid promotion type');
    }
    startAddingPromotion();
    const { error } = await addGlobalPromotion({
      name: newPromotionName,
      type: newPromotionType,
      amount: newPromotionAmount,
      code: newPromotionCode,
      is_active: true,
    });
    if (error) {
      errorToast(error);
    } else {
      stopAddingPromotion();
      setNewPromotionCode('');
      setNewPromotionAmount(0);
      setNewPromotionType('percentage');
      setNewPromotionName('');
      toast('promotion added');
    }
  };

  return (
    <div className="bg-gray-50 p-4">
      <SpacedContainer>
        <h1>Setup a new discount code</h1>
        <p>
          A discount code is available to every user who adds it either to its
          wallet or on checkout.
        </p>
        <SpacedContainer type="fluid">
          <SpacedContainer>
            <FormControl
              label="Promotion name"
              sublabel="A name to identify the promotion, it can be an existing promotion name or a new one"
            >
              <Input
                value={newPromotionName}
                onChange={(e) => setNewPromotionName(e.currentTarget.value)}
              />
            </FormControl>
            <FormControl
              label="Promotion code"
              sublabel="The code for the promotion, something like 'DISCOUNT10'"
            >
              <Input
                value={newPromotionCode}
                onChange={(e) => setNewPromotionCode(e.currentTarget.value)}
              />
            </FormControl>
            <FormControl
              label="Promotion type"
              sublabel="A promotion can be either a percentage discount or a cash coupon"
            >
              <Select
                value={newPromotionType}
                onValueChange={(value) => value && setNewPromotionType(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a type of promotion" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="percentage">Percentage</SelectItem>
                  <SelectItem value="cash">Cash</SelectItem>
                </SelectContent>
              </Select>
            </FormControl>
            <FormControl
              label="Promotion amount"
              sublabel="The amount of the promotion. This value is based on the type of the promotion"
            >
              <Input
                value={
                  newPromotionAmount > 0
                    ? newPromotionAmount.toString().replace(/^0/, '')
                    : undefined
                }
                type="number"
                pattern="[0-9]+([\.,][0-9]+)?"
                step={0.01}
                placeholder="15"
                onChange={(e) => {
                  if (e.currentTarget.validity.valid) {
                    setNewPromotionAmount(Number(e.currentTarget.value));
                  }
                }}
              />
            </FormControl>
          </SpacedContainer>
        </SpacedContainer>
        <Button
          disabled={isAddingPromotion}
          isLoading={isAddingPromotion}
          onClick={handleAddUsersToPromotion}
        >
          Create discount code
        </Button>
      </SpacedContainer>
    </div>
  );
};
