import React from 'react';

import { Label } from './Label';

type Props = {
  children: React.ReactNode;
  className?: string;
  htmlFor?: string;
  label?: React.ReactNode;
  sublabel?: React.ReactNode;
};

export const FormControl = ({
  label,
  sublabel,
  children,
  htmlFor,
  className = '',
}: Props) => {
  return (
    <div className={className}>
      {label && (
        <Label
          htmlFor={htmlFor}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </Label>
      )}
      <div id={htmlFor} className="mt-1">
        {children}
      </div>
      {sublabel && <p className="mt-2 text-sm text-gray-500">{sublabel}</p>}
    </div>
  );
};
