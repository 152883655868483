import { getCollections, useQuery } from 'revibe-api';
import { Button, Link, SpacedContainer, Table, Tag } from 'revibe-ui';
import { ROUTES } from 'routes';

import { useUser } from 'modules/users/hooks';

import { LoggedInPage, NoData } from 'shared/components';
import { SectionHeading } from 'shared/components/SectionHeading';
import { useHistory } from 'shared/hooks';
import { getDynamicRouteUrl } from 'shared/utils';

import { CollectionsIcon } from 'ui/icons';

export const CollectionsPage = () => {
  const history = useHistory();
  const { user } = useUser();
  const [collections, { isLoading }] = useQuery('collections', getCollections, {
    defaultValue: [],
  });

  return (
    <LoggedInPage hideOverflow title="Collections">
      {!isLoading && collections.length === 0 ? (
        <NoData
          icon={<CollectionsIcon />}
          heading="No brand has created a collection yet"
          text="When brands will start to create collections they will appear here"
        />
      ) : (
        <SpacedContainer>
          <SectionHeading
            heading="Collections"
            subheading={
              <>
                Note: a collection and its items are visible only if the
                collection is both <strong>published</strong> and{' '}
                <strong>released</strong>.
              </>
            }
          />

          <Table
            isLoading={isLoading}
            hasSortableHeadings
            maxRows={25}
            columns={['Name', 'Status', 'Seller', 'Release Date', '']}
            data={collections.map((collection) => [
              collection.name,
              <SpacedContainer key={collection.id} type="horizontal" centered>
                <Tag color={collection.is_published ? 'purple' : 'red'}>
                  {collection.is_published ? 'PUBLISHED' : 'NOT PUBLISHED'}
                </Tag>
                <Tag color={collection.is_released ? 'purple' : 'red'}>
                  {collection.is_released ? 'RELEASED' : 'NOT RELEASED'}
                </Tag>
              </SpacedContainer>,
              <Link
                key={collection.id}
                target="_blank"
                to={`${
                  process.env.REACT_APP_SELLER_WEBSITE_URL
                }/admin-session?sellerID=${collection.seller.id}&sellerName=${
                  collection.seller.name
                }&adminEmail=${user?.email || ''}`}
              >
                {collection.seller.name}
              </Link>,
              collection.date,
              <Button
                key={collection.id}
                onClick={() =>
                  history.push(
                    getDynamicRouteUrl(ROUTES.COLLECTION, {
                      id: collection.id,
                    })
                  )
                }
              >
                Manage
              </Button>,
            ])}
          />
        </SpacedContainer>
      )}
    </LoggedInPage>
  );
};
