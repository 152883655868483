import React from 'react';

import { cn } from 'revibe-ui';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, Drawer } from 'revibe-ui';
import { ROUTES } from 'routes';

import { MenuIcon } from '@heroicons/react/outline';

import { useHistory } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

import { Logo } from 'ui/icons';

import { LoggedInNavBar } from './LoggedInNavBar';

type Props = {
  title: string;
  children: React.ReactNode;
  breadcrumbs?: {
    label: string;
    to?: string;
  }[];
  hideOverflow?: boolean;
};

export const LoggedInPage = ({
  title,
  children,
  hideOverflow = false,
  breadcrumbs,
}: Props) => {
  const [isSidebarOpen, openSidebar, closeSidebar] = useBoolean();
  const history = useHistory();
  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];

  return (
    <>
      <Helmet title={`${title} | Revibe admin`} meta={meta} />
      <div className="min-h-full">
        <div className="sticky top-0 z-50 flex h-16 flex-shrink-0 items-center justify-between border-b border-gray-200 bg-gray-50 backdrop-blur-md lg:hidden">
          <button
            type="button"
            className="px-4 text-gray-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-purple-500 lg:hidden"
            onClick={openSidebar}
          >
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <Logo
            onClick={() => history.push(ROUTES.HOME)}
            className="box-border h-8 w-28 px-3"
          />
        </div>

        <div className="flex">
          <div className="hidden lg:inset-y-0 lg:block lg:w-64 lg:flex-col lg:items-start">
            <div className="w-min-64 flex h-screen w-64 flex-col lg:sticky lg:top-0 ">
              <div className="flex min-h-0 flex-1 flex-col bg-white ">
                <LoggedInNavBar />
              </div>
            </div>
          </div>
          <div
            className={cn(
              'min-h-full w-auto flex-auto',
              hideOverflow && 'overflow-x-hidden'
            )}
          >
            <div className="fixed z-20 w-full bg-white">
              <Breadcrumbs
                homeOnClick={() => history.push(ROUTES.HOME)}
                breadcrumbs={(breadcrumbs || []).map((breadcrumb) => ({
                  ...breadcrumb,
                  onClick: breadcrumb.to
                    ? () => breadcrumb.to && history.push(breadcrumb.to)
                    : undefined,
                }))}
              />
            </div>
            <div className="px-4 pt-20 pb-16 md:px-8">{children}</div>
          </div>
        </div>
      </div>
      <Drawer
        scrollable
        position="left"
        onOpenChange={closeSidebar}
        open={isSidebarOpen}
      >
        <LoggedInNavBar />
      </Drawer>
    </>
  );
};
