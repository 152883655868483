import { Helmet } from 'react-helmet';

import { NavBar } from 'shared/components';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const Page = ({ title, children }: Props) => {
  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];
  return (
    <div className="min-h-full">
      <Helmet title={`${title} | Revibe Admin`} meta={meta} />
      <div className="flex min-h-screen flex-col items-center justify-center">
        {children}
      </div>
      <div className="fixed top-0">
        <NavBar />
      </div>
    </div>
  );
};
