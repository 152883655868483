import { createTheme, lightThemePrimitives } from 'baseui';

const primitives = {
  corePurple: 'var(--purple)',
  coreGray: 'var(--gray)',
  coreLightPurple: 'var(--purple-100)',
  coreBlack: 'var(--black)',
  coreWhite: 'var(--white)',
  primaryFontFamily: 'Inter',
};

const overrides = {
  colors: {
    primaryA: primitives.coreBlack,
    positive: primitives.corePurple,
    buttonPrimaryFill: primitives.coreBlack,
    buttonPrimaryText: primitives.coreWhite,
    buttonPrimaryHover: primitives.corePurple,
    buttonPrimaryActive: primitives.corePurple,
    buttonSecondaryFill: '#E2E2E2',
    buttonSecondaryHover: '#CBCBCB',
    inputPlaceholder: primitives.coreGray,
    buttonTertiaryText: primitives.coreGray,
  },
};

export const theme = createTheme(
  { ...lightThemePrimitives, ...primitives },
  overrides
);
