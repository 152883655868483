import { useState } from 'react';
import React from 'react';

import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { SpacedContainer } from '../SpacedContainer';
import { Image } from './Image';

type Photo = { id: string | number; src: string };

type Props = {
  photos: Photo[];
  onUpdate: (photos: Photo[]) => void;
};

function SortableItem({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="h-full"
    >
      {children}
    </div>
  );
}

export const ImageOrder = ({ photos, onUpdate }: Props) => {
  const [items, setItems] = useState(photos.map((p) => p.src));

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id as string);
        const newIndex = items.indexOf(over.id as string);

        onUpdate(arrayMove(photos, oldIndex, newIndex));
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items}>
        <SpacedContainer fullWidth type="horizontal">
          {items.map((photo) => (
            <SortableItem id={photo} key={photo}>
              <Image w={128} src={photo} objectFit="contain" />
            </SortableItem>
          ))}
        </SpacedContainer>
      </SortableContext>
    </DndContext>
  );
};
