import { useContext } from 'react';

import { useHistory } from 'shared/hooks';
import { getDynamicUserRouteUrl } from 'shared/utils';

import { AuthContext } from './components';

export const useAuthentication = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const logout = async () => {
    await authContext.logout();
    history.push('/');
  };

  return {
    userID: authContext.userID,
    isLoading: authContext.isLoading,
    isLoggedIn: authContext.userID !== null,
    login: async (
      email: string,
      password: string,
      isPermanentLogin: boolean,
      from?: string
    ) => {
      if (from) {
        return authContext.login(
          email,
          password,
          isPermanentLogin,
          (id: string) => history.replace(getDynamicUserRouteUrl(from, id))
        );
      }
      return authContext.login(email, password, isPermanentLogin);
    },
    forceLogin: (token: string, id: string) => {
      authContext.forceLogin(token, id);
      history.replace(getDynamicUserRouteUrl('/', id));
    },
    logout,
  };
};
