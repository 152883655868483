import { Category } from 'revibe-api';
import {
  SpacedContainer,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'revibe-ui';

import { CategoryPhotoManager } from './CategoryPhotoManager';
import { CategoryTranslations } from './CategoryTranslations';

export const CategoryDetails = ({
  category,
  onUpdate,
}: {
  category: Category;
  onUpdate: () => void;
}) => {
  return (
    <SpacedContainer className="p-4">
      <h1 className="pb-8 text-2xl font-bold">{category.name}</h1>
      <Tabs defaultValue="photos">
        <TabsList>
          <TabsTrigger value="photos">Photos</TabsTrigger>
          <TabsTrigger value="translations">Translations</TabsTrigger>
        </TabsList>
        <TabsContent value="photos">
          <CategoryPhotoManager category={category} onUpload={onUpdate} />
        </TabsContent>
        <TabsContent value="translations">
          <CategoryTranslations category={category} />
        </TabsContent>
      </Tabs>
    </SpacedContainer>
  );
};
