import { AdminUser } from './users.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getUser = async (userID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/admin/users/${userID}`,
    method: 'GET',
    reshaper: (data: AdminUser) => data,
  });
