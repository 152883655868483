import { useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { arrayRemove } from '../../helpers/arrayRemove';
import { cn } from '../../helpers/classnames';
import { ErrorBanner } from '../Banner';
import { Link } from '../Link';
import { SpacedContainer } from '../SpacedContainer';

type Props = {
  onUpdate: (files: File[]) => void;
  isDisabled?: boolean;
  accept?: { [key: string]: string[] };
  maxFiles?: number;
  maxSize?: number;
};

export const FilesUploader = ({
  onUpdate,
  isDisabled = false,
  maxFiles,
  maxSize,
  accept = {},
}: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const acceptedFilesTypes = Object.keys(accept).join(', ');

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize: maxSize ? maxSize * 1000000 : undefined,
    disabled: isDisabled || (maxFiles ? files.length >= maxFiles : false),
    maxFiles,
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
      onUpdate([...files, ...acceptedFiles]);
    },
  });

  const hasFilesTooBig =
    fileRejections.findIndex(({ errors }) =>
      errors.find((error) => error.code === 'file-too-large')
    ) !== -1;

  const tooManyFiles =
    fileRejections.findIndex(({ errors }) =>
      errors.find((error) => error.code === 'too-many-files')
    ) !== -1;

  const removeImage = async (i: number) => {
    const newFiles = arrayRemove(files, i);

    setFiles(newFiles);
    onUpdate(newFiles);
  };

  return (
    <SpacedContainer>
      <section
        className={cn(
          isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
          'box-border flex justify-center rounded-md border border-dashed border-gray-200 px-6 pt-5 pb-6'
        )}
      >
        <div
          {...getRootProps({
            className: cn(
              isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
              'space-y-1 text-center dropzone w-full'
            ),
          })}
        >
          {files.length > 0 && (
            <div className="mb-4 flex h-fit w-full flex-col gap-4 border-b border-gray-200 pb-4 text-left">
              {files.map((file, i) => (
                <div className="relative" key={file.name + i}>
                  <p>{file.name}</p>
                  <span
                    className={cn(
                      'absolute -top-1 -right-1 inline-flex items-center justify-center p-1',
                      'bg-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
                    )}
                    onClick={(e) => {
                      removeImage(i);
                      e.stopPropagation();
                    }}
                  >
                    <XMarkIcon className="h-3 w-3 text-gray-500 hover:text-gray-700" />
                  </span>
                </div>
              ))}
            </div>
          )}
          {maxFiles && files.length >= maxFiles ? null : (
            <>
              <PlusCircleIcon
                className="mx-auto h-8 w-8 text-gray-400"
                aria-hidden="true"
              />
              <div className="mx-auto flex w-full justify-center text-sm ">
                <SpacedContainer
                  type="horizontal"
                  spacing="x-small"
                  centered
                  className="relative rounded-md bg-white font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                >
                  <input
                    {...getInputProps()}
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className={cn('dropzone w-full')}
                  />
                </SpacedContainer>
              </div>
              <div className="flex flex-col gap-1 text-xs font-light text-gray-400">
                {maxSize && <span>{`${maxSize}MB max`}</span>}
                <span>
                  {acceptedFilesTypes}
                  {maxFiles && ` [${files.length}/${maxFiles}]`}
                </span>
              </div>
            </>
          )}
        </div>
      </section>
      {hasFilesTooBig && (
        <ErrorBanner>
          File size is too big{' '}
          <Link to="https://www.reduceimages.com/" target="_blank">
            reduce images
          </Link>
        </ErrorBanner>
      )}
      {tooManyFiles && <ErrorBanner>Too many files</ErrorBanner>}
    </SpacedContainer>
  );
};
