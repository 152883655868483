import { Button, Drawer, SpacedContainer } from 'revibe-ui';

import { useBoolean } from 'revibe-ui';

export const HowOrderingModal = () => {
  const [isHowDrawerOpen, openHowDrawer, closeHowDrawer] = useBoolean();

  return (
    <>
      <Button variant="subtle" onClick={openHowDrawer}>
        How does ordering work?
      </Button>
      <Drawer
        scrollable
        size="full"
        position="bottom"
        open={isHowDrawerOpen}
        onOpenChange={closeHowDrawer}
      >
        <div className="grid w-full grid-cols-1 gap-4 overflow-scroll md:grid-cols-2 lg:grid-cols-3">
          <SpacedContainer centered fullWidth>
            <h1>Shop all and brand pages</h1>
            <ol>
              <li>Top products</li>
              <li>Star rating and if same rating newest on top</li>
            </ol>
            <p>Categories Drag &amp; Drop don&apos;t influence those listing</p>
            <img
              className="h-max-[90vh] h-full w-full object-contain"
              src="https://res.cloudinary.com/revibe/image/upload/v1645202946/backoffice/Frame_57_dnpdxk.jpg"
              alt="how shop all"
            />
          </SpacedContainer>
          <SpacedContainer centered fullWidth>
            <h1>Category pages</h1>
            <ol>
              <li>Products ordered by Drag &amp; Drop</li>
              <li>Top products</li>
              <li>Star rating and if same rating newest on top</li>
            </ol>
            <p>
              New products are appended at the bottom of the listing until a new
              drag &amp; drop configuration is saved.
            </p>
            <img
              className="h-max-[90vh] h-full w-full object-contain"
              src="https://res.cloudinary.com/revibe/image/upload/v1645202951/backoffice/Frame_58_lu7edx.jpg"
              alt="how shop all"
            />
          </SpacedContainer>
          <SpacedContainer centered fullWidth>
            <h1>Collections pages</h1>
            <p>COMING SOON</p>
          </SpacedContainer>
        </div>
      </Drawer>
    </>
  );
};
