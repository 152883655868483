import { useState } from 'react';

import { updateOrderItem } from 'api/order.api';
import {
  APIOrderItem,
  APIOrderItemUpdate,
  APISellerOrder,
} from 'api/orders.types';
import {
  Button,
  FormControl,
  Image,
  Input,
  Link,
  Drawer,
  SpacedContainer,
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'revibe-ui';

import { ExternalLinkIcon } from '@heroicons/react/outline';

import { formatPrice } from 'modules/catalog/utils/formatPrice';
import { getSizeLabelFromSize } from 'modules/catalog/utils/itemSize';
import {
  ITEM_ORDER_STATUS,
  SELLER_ORDER_STATUS,
} from 'modules/orders/utils/orderStatus';
import { orderItemStatusToString } from 'modules/orders/utils/orderStatusToString';

import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

type Props = {
  item: APIOrderItem;
  order: APISellerOrder;
  onUpdate: () => void;
};

export const OrderItem = ({ item, order, onUpdate }: Props) => {
  const { errorToast, toast } = useToast();
  const [isUpdating, startUpdating, stopUpdating] = useBoolean();
  const [trackingURL, setTrackingURL] = useState(item.tracking_url);
  const [trackingCode, setTrackingCode] = useState(item.tracking_code);
  const [forcedStatus, setForcedStatus] = useState(item.status);
  const [isShippingDrawerOpen, openShippingDrawer, closeShippingDrawer] =
    useBoolean();
  const handleItemUpdate = async (itemUpdate: APIOrderItemUpdate) => {
    startUpdating();
    const { error } = await updateOrderItem(item.id, itemUpdate);
    stopUpdating();
    if (error) {
      errorToast(error);
    } else {
      toast('Item order updated!');
      onUpdate();
    }
  };

  const renderTrackingInfo = () => {
    if (
      item.status !== ITEM_ORDER_STATUS.SHIPPED &&
      item.status !== ITEM_ORDER_STATUS.DELIVERED
    ) {
      return null;
    }

    return (
      <>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Tracking URL</dt>
          <dd className="mt-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 ">
            {item.tracking_url ? (
              <Link to={item.tracking_url} target="_blank">
                {item.tracking_url}
              </Link>
            ) : (
              'Not provided'
            )}
          </dd>
        </div>
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Tracking code</dt>
          <dd className="mt-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 ">
            {item.tracking_code || 'Not provided'}
          </dd>
        </div>
      </>
    );
  };

  const renderAvailabilityInfo = () => {
    if (item.availability_confirmed === null) {
      return (
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Availability</dt>
          <dd className="mt-1 grid grid-cols-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:grid-cols-2">
            <Button
              variant="subtle"
              isLoading={isUpdating}
              disabled={isUpdating}
              onClick={() =>
                handleItemUpdate({
                  availability_confirmed: true,
                  status: item.is_preorder
                    ? ITEM_ORDER_STATUS.TO_PRODUCE
                    : ITEM_ORDER_STATUS.TO_SHIP,
                })
              }
            >
              Confirm product availability
            </Button>
            <Button
              variant="error"
              isLoading={isUpdating}
              disabled={isUpdating}
              onClick={() =>
                handleItemUpdate({
                  availability_confirmed: false,
                })
              }
            >
              Product not available
            </Button>
          </dd>
        </div>
      );
    }
    return (
      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Availability</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          {item.availability_confirmed ? 'Confirmed' : 'Not available'}
        </dd>
      </div>
    );
  };

  const renderDeliveryInfo = () => {
    if (
      order.status === SELLER_ORDER_STATUS.CANCELED ||
      item.availability_confirmed === null
    ) {
      return null;
    }
    if (item.status === ITEM_ORDER_STATUS.TO_PRODUCE) {
      return (
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Delivery status</dt>
          <dd className="mt-1 grid grid-cols-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:grid-cols-2">
            To produce
            <Button
              variant="subtle"
              onClick={() =>
                handleItemUpdate({
                  status: ITEM_ORDER_STATUS.TO_SHIP,
                })
              }
            >
              Mark product as 'In production'
            </Button>
          </dd>
        </div>
      );
    }
    if (item.status === ITEM_ORDER_STATUS.TO_SHIP) {
      return (
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Delivery status</dt>
          <dd className="mt-1 grid grid-cols-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:grid-cols-2">
            To ship
            <Button variant="subtle" onClick={openShippingDrawer}>
              Mark product as 'Shipped'
            </Button>
          </dd>
        </div>
      );
    }
    if (item.status === ITEM_ORDER_STATUS.SHIPPED) {
      return (
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Delivery status</dt>
          <dd className="mt-1 grid grid-cols-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:grid-cols-2">
            Shipped
            <Button
              variant="subtle"
              isLoading={isUpdating}
              disabled={isUpdating}
              onClick={() =>
                handleItemUpdate({
                  status: ITEM_ORDER_STATUS.DELIVERED,
                })
              }
            >
              Mark product as 'Delivered'
            </Button>
          </dd>
        </div>
      );
    }
    if (item.status === ITEM_ORDER_STATUS.DELIVERED) {
      return (
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Delivery status</dt>
          <dd className="mt-1 grid grid-cols-1 gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 md:grid-cols-2">
            Delivered
          </dd>
        </div>
      );
    }
  };

  return (
    <div className="overflow-hidden border border-gray-200 bg-white sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {item.name}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          <Link
            target="_blank"
            to={`${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}/shop/item/${item.slug}`}
          >
            See product in Marketplace{' '}
            <ExternalLinkIcon className="mb-1 inline w-4" />
          </Link>
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Price</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span>
                {formatPrice((item.selling_price || item.price) * item.amount)}{' '}
                &euro;{' '}
                {item.amount > 1 && (
                  <span>
                    ({formatPrice(item.selling_price || item.price)} &euro; each
                  </span>
                )}
              </span>
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Size</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {getSizeLabelFromSize(item.size, item.category).toUpperCase()}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              In made to order
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {item.is_preorder ? 'YES' : 'NO'}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Amount</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {item.amount}
            </dd>
          </div>
          {item.colors && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Colors</dt>
              <dd className="mt-1 flex items-center gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {item.colors.split(',').map((color) => (
                  <span
                    key={color}
                    style={{
                      backgroundColor: color,
                      width: '12px',
                      height: '12px',
                      display: 'inline-block',
                    }}
                  />
                ))}
              </dd>
            </div>
          )}
          {item.user_info && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Customer's additional information
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {item.user_info}
              </dd>
            </div>
          )}

          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Product photos
            </dt>
            <dd className="mt-1 flex h-fit gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {item.photos.map((photo) => (
                <div key={photo.id} className="w-32">
                  <Image src={photo.small_link} alt={item.name} />
                </div>
              ))}
            </dd>
          </div>

          {renderAvailabilityInfo()}
          {renderDeliveryInfo()}
          {renderTrackingInfo()}

          <div className="bg-red-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Danger zone</dt>
            <dd className="mt-1 flex h-fit gap-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <Select
                value={forcedStatus}
                onValueChange={(value) => value && setForcedStatus(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a status" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(ITEM_ORDER_STATUS).map((status) => (
                    <SelectItem value={status}>
                      {orderItemStatusToString(status)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                variant="subtle"
                isLoading={isUpdating}
                disabled={isUpdating}
                onClick={() =>
                  handleItemUpdate({
                    status: forcedStatus,
                  })
                }
              >
                Set product status
              </Button>
            </dd>
          </div>
        </dl>
      </div>
      <Drawer
        scrollable
        open={isShippingDrawerOpen}
        onOpenChange={closeShippingDrawer}
      >
        <SpacedContainer>
          <p>Please provide the tracking information</p>
          <FormControl label="Tracking URL">
            {' '}
            <Input
              value={trackingURL || ''}
              onChange={(e) => {
                setTrackingURL(e.currentTarget.value);
              }}
              placeholder="Tracking URL"
            />
          </FormControl>
          <FormControl label="Tracking Code">
            {' '}
            <Input
              value={trackingCode || ''}
              onChange={(e) => {
                setTrackingCode(e.currentTarget.value);
              }}
              placeholder="Tracking Code"
            />
          </FormControl>
          <Button
            isLoading={isUpdating}
            disabled={!trackingCode || !trackingURL || isUpdating}
            onClick={async () => {
              await handleItemUpdate({
                status: ITEM_ORDER_STATUS.SHIPPED,
                tracking_url: trackingURL,
                tracking_code: trackingCode,
              });
              closeShippingDrawer();
            }}
          >
            Mark product as shipped
          </Button>
          <p>
            Marking the product as shipped will send an email to the customer
            with the provided tracking information.
          </p>
        </SpacedContainer>
      </Drawer>
    </div>
  );
};
