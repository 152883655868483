import { useState } from 'react';

import {
  deleteItemSelectionPhoto,
  updateItemSelectionPhoto,
} from 'api/selections.api';
import { ItemSelectionPhoto } from 'api/selections.types';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { Button, Image, Drawer, SpacedContainer } from 'revibe-ui';

import { useBoolean } from 'revibe-ui';

type Props = {
  itemSelectionID: string;
  photos: ItemSelectionPhoto[];
  onDelete: (item: ItemSelectionPhoto) => void;
};

export const SelectionPhotosOrder = ({
  itemSelectionID,
  photos,
  onDelete,
}: Props) => {
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const [hasMovedItems, setHasMovedItems, setHasNotMovedItems] = useBoolean();
  const [isDeleteDrawerOpen, openDeleteDrawer, closeDeleteDrawer] =
    useBoolean();
  const [indexToRemove, setIndexToRemove] = useState(0);
  const [itemsPreview, setItemsPreview] = useState(
    photos.map((photo) => (
      <div
        className="mx-1 flex h-[96px] w-[96px] items-center overflow-hidden"
        key={photo.id}
      >
        <span className="relative h-[96px] w-[96px]">
          <Image w={96} src={photo.small_link} alt="item" />
        </span>
      </div>
    ))
  );
  const [items, setItems] = useState(photos);
  const handleConfigUpdate = async () => {
    startFetching();
    await Promise.all(
      items.map(
        async (photo, i) =>
          await updateItemSelectionPhoto(itemSelectionID, photo.id, {
            order: i,
          })
      )
    );
    stopFetching();
    setHasNotMovedItems();
  };
  const handleDelete = async () => {
    startFetching();
    const itemPhoto = items[indexToRemove];
    if (!itemPhoto) {
      stopFetching();
      return;
    }
    const { error } = await deleteItemSelectionPhoto(
      itemSelectionID,
      itemPhoto.id
    );
    if (!error) {
      setItemsPreview(arrayRemove(itemsPreview, indexToRemove));
      closeDeleteDrawer();
      onDelete(itemPhoto);
    }
    stopFetching();
  };
  return (
    <SpacedContainer spacing="small">
      <List
        items={itemsPreview}
        removable
        onChange={({ oldIndex, newIndex }) => {
          if (newIndex === -1) {
            openDeleteDrawer();
            setIndexToRemove(oldIndex);
            return;
          }
          setHasMovedItems();
          setItemsPreview(arrayMove(itemsPreview, oldIndex, newIndex));
          setItems(arrayMove(items, oldIndex, newIndex));
        }}
      />
      <Button
        variant="subtle"
        isLoading={isFetching}
        disabled={!hasMovedItems || isFetching}
        onClick={handleConfigUpdate}
      >
        Update photos order
      </Button>
      <Drawer
        scrollable
        open={isDeleteDrawerOpen}
        onOpenChange={closeDeleteDrawer}
      >
        <SpacedContainer>
          <h1>Are you sure?</h1>
          <SpacedContainer type="horizontal">
            <Button
              variant="error"
              onClick={handleDelete}
              isLoading={isFetching}
              disabled={isFetching}
            >
              Delete
            </Button>
            <Button variant="subtle" onClick={closeDeleteDrawer}>
              Cancel
            </Button>
          </SpacedContainer>
        </SpacedContainer>
      </Drawer>
    </SpacedContainer>
  );
};
