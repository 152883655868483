import { Formik } from 'formik';
import { Redirect, useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  Label,
  SpacedContainer,
} from 'revibe-ui';
import { ROUTES } from 'router';

import { Page } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';

import { useAuthentication } from '../hooks';

export const LoginPage = () => {
  const location = useLocation();
  const { login, userID, isLoggedIn, isLoading } = useAuthentication();
  const { from }: any = location.state || {
    from: '/',
  };
  const handleLogin = async (
    email: string,
    password: string,
    isPermanentLogin: boolean
  ) => await login(email, password, isPermanentLogin, from);

  if (isLoggedIn && userID) {
    return <Redirect to={from || ROUTES.HOME} />;
  }

  return (
    <Page title="Login">
      <div className="w-full max-w-3xl p-4">
        <Formik
          initialValues={{
            email: '',
            password: '',
            isPermanentLogin: true,
          }}
          onSubmit={async (values) => {
            await handleLogin(
              values.email,
              values.password,
              values.isPermanentLogin
            );
          }}
        >
          {({ setFieldValue, values, handleSubmit }) => (
            <form className="w-full" onSubmit={handleSubmit}>
              <SpacedContainer centered className="w-full">
                <PageHeading>Login</PageHeading>
                <SpacedContainer className="w-full">
                  <FormControl label="Email">
                    <Input
                      value={values.email}
                      onChange={(e) =>
                        setFieldValue('email', e.currentTarget.value)
                      }
                    />
                  </FormControl>
                  <FormControl label="Password">
                    <Input
                      type="password"
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue('password', e.currentTarget.value)
                      }
                    />
                  </FormControl>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={values.isPermanentLogin}
                      onCheckedChange={() =>
                        setFieldValue(
                          'isPermanentLogin',
                          !values.isPermanentLogin
                        )
                      }
                      id="isPermanentLogin"
                    />
                    <Label htmlFor="isPermanentLogin">Stay connected</Label>
                  </div>
                  <SpacedContainer centered spacing="small">
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      className="w-full"
                    >
                      Login
                    </Button>
                  </SpacedContainer>
                </SpacedContainer>
              </SpacedContainer>
            </form>
          )}
        </Formik>
      </div>
    </Page>
  );
};
