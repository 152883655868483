import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';

import { cn } from '../../helpers/classnames';

export interface ImageProps {
  onClick?: () => void;
  src: string;
  ratio?: number | null;
  w?: number;
  noBg?: boolean;
  noBorder?: boolean;
  noRounded?: boolean;
  alt?: string;
  objectFit?: 'contain' | 'cover';
}

export const Image = ({
  src,
  w,
  onClick,
  ratio = 0.75,
  noBg = false,
  noBorder = false,
  noRounded = false,
  alt,
  objectFit = 'cover',
}: ImageProps) => {
  return (
    <div style={{ width: w ? `${w}px` : '100%' }}>
      <AspectRatioPrimitive.Root
        onClick={onClick}
        ratio={ratio || undefined}
        className={cn(
          noBg ? 'bg-transparent' : 'bg-gray-100',
          noRounded ? '' : 'rounded-lg',
          noBorder ? '' : 'border border-gray-200',
          'relative h-full w-full overflow-hidden'
        )}
      >
        <img
          src={src}
          alt={alt}
          className={cn(
            objectFit === 'contain' ? 'object-contain' : 'object-cover',
            'h-full w-full '
          )}
        />
      </AspectRatioPrimitive.Root>
    </div>
  );
};
