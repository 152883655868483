import { useEffect, useMemo, useState } from 'react';

import { getStats } from 'api/stats.api';
import { APIStats } from 'api/stats.types';
import moment from 'moment';
import {
  FormControl,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SpacedContainer,
  Table,
} from 'revibe-ui';

import { AreaChart as AAreaChart, Card, Subtitle, Title } from '@tremor/react';

import { formatPrice } from 'modules/catalog/utils/formatPrice';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

export const StatsPage = () => {
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const [stats, setStats] = useState<APIStats>([]);
  const { errorToast } = useToast();
  const [period, setPeriod] = useState<string>('All time');
  const fetchStats = async () => {
    startFetching();
    const { data, error } = await getStats();
    if (data) {
      setStats(
        data.filter((entry) => {
          const entryMonth = moment(entry.label, 'MM/YYYY');
          return (
            entry.id === -1 ||
            (entryMonth > moment('03/2021', 'MM/YYYY') &&
              entryMonth < moment(new Date(), 'MM/YYYY'))
          );
        })
      );
    } else {
      errorToast(error);
    }
    stopFetching();
  };
  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedStats = useMemo(() => {
    return stats.find((stat) => stat.label === period)?.stats;
  }, [period, stats]);

  return (
    <LoggedInPage
      hideOverflow
      title="Stats"
      breadcrumbs={[
        {
          label: 'Stats',
        },
      ]}
    >
      <SpacedContainer>
        <PageHeading>Stats</PageHeading>
        <div className="bg-gray-50 p-4">
          <div className="pb-5 ">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Filters
            </h3>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              [Stats are updated every 10 minutes]
            </p>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              Select a month to see its data breakdown.
            </p>
          </div>
          <Select
            value={period}
            onValueChange={(value) => value && setPeriod(value)}
          >
            <SelectTrigger className="max-w-xl">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="max-w-xl">
              {stats.map((stat) => (
                <SelectItem value={stat.label}>{stat.label}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="mb-8 grid grid-cols-1 gap-4 lg:grid-cols-2">
          <FormControl label="Orders">
            <Table
              isLoading={isFetching}
              columns={['Key', 'Value']}
              data={[
                [
                  'Customers Count',
                  selectedStats?.ordersStats.customersCount || '0',
                ],
                [
                  'New Customers Count',
                  selectedStats?.ordersStats.newCustomersCount || '0',
                ],
                ['Orders Count', selectedStats?.ordersStats.ordersCount || '0'],
                [
                  'Order Items Count',
                  selectedStats?.ordersStats.orderedItemsCount || '0',
                ],
                [
                  'Average cart size',
                  (selectedStats?.ordersStats.avgCartSize &&
                    `${formatPrice(
                      selectedStats?.ordersStats.avgCartSize
                    )} €`) ||
                    '0',
                ],
                [
                  'GMV',
                  (selectedStats?.ordersStats.merchandiseSalesRevenue &&
                    `${formatPrice(
                      selectedStats?.ordersStats.merchandiseSalesRevenue
                    )} €`) ||
                    '0',
                ],
              ]}
            />
          </FormControl>
          <FormControl label="Catalog">
            <Table
              isLoading={isFetching}
              columns={['Key', 'Value']}
              data={[
                [
                  'Sellers Count (Monthly added)',
                  selectedStats?.catalogStats.sellersCount || '0',
                ],
                [
                  'Sellers Cumulative Count',
                  selectedStats?.catalogStats.sellersCumulativeCount || '0',
                ],
                [
                  'Catalog Items Count (Monthly addded)',
                  selectedStats?.catalogStats.itemsCount || '0',
                ],
                [
                  'Catalog Items Cumulative Count',
                  selectedStats?.catalogStats.itemsCumulativeCount || '0',
                ],
              ]}
            />
          </FormControl>
          <FormControl label="Users">
            <Table
              isLoading={isFetching}
              columns={['Key', 'Value']}
              data={[
                [
                  'Registered users',
                  selectedStats?.userStats.usersCount || '0',
                ],
                ['Leads', selectedStats?.userStats.leadsCount || '0'],
              ]}
            />
          </FormControl>
        </div>
        <SpacedContainer>
          <Title>Orders and Revenues</Title>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <Card>
              <Title>Orders</Title>
              <Subtitle>The number of orders on Revibe over time.</Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    Orders: s.stats.ordersStats.ordersCount,
                  }))}
                categories={['Orders']}
                dataKey="label"
                height="h-72"
                colors={['violet']}
                marginTop="mt-4"
              />
            </Card>
            <Card>
              <Title>Ordered items</Title>
              <Subtitle>
                The number of items ordered on Revibe over time.
              </Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    Items: s.stats.ordersStats.orderedItemsCount,
                  }))}
                categories={['Items']}
                dataKey="label"
                height="h-72"
                colors={['violet']}
                marginTop="mt-4"
              />
            </Card>
            <Card>
              <Title>GMV</Title>
              <Subtitle>Gross revenues generated over time.</Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    GMV: Number(
                      formatPrice(
                        s.stats.ordersStats.merchandiseSalesRevenue || 0
                      )
                    ),
                  }))}
                categories={['GMV']}
                dataKey="label"
                height="h-72"
                colors={['violet']}
                marginTop="mt-4"
                valueFormatter={(value) => `${value} €`}
              />
            </Card>
            <Card>
              <Title>Average basket size</Title>
              <Subtitle>
                Average basket size over time (only for online orders).
              </Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    'Average Cart': Number(
                      formatPrice(s.stats.ordersStats.avgCartSize || 0)
                    ),
                  }))}
                categories={['Average Cart']}
                dataKey="label"
                height="h-72"
                colors={['violet']}
                marginTop="mt-4"
                valueFormatter={(value) => `${value} €`}
              />
            </Card>
            <Card>
              <Title>Sellers</Title>
              <Subtitle>Sellers onboarded over time.</Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    'Sellers month': s.stats.catalogStats.sellersCount,
                    'Sellers total':
                      s.stats.catalogStats.sellersCumulativeCount,
                  }))}
                categories={['Sellers total', 'Sellers month']}
                dataKey="label"
                height="h-72"
                colors={['violet', 'stone']}
                marginTop="mt-4"
              />
            </Card>
            <Card>
              <Title>Catalog</Title>
              <Subtitle>
                Catalog products available for purchase over time.
              </Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    'Products month': s.stats.catalogStats.itemsCount || 0,
                    'Products total':
                      s.stats.catalogStats.itemsCumulativeCount || 0,
                  }))}
                categories={['Products total', 'Products month']}
                dataKey="label"
                height="h-72"
                colors={['violet', 'stone']}
                marginTop="mt-4"
              />
            </Card>
            <Card>
              <Title>Users</Title>
              <Subtitle>
                Registered users and people who subscribed to the newsletter
                from the website over time.
              </Subtitle>
              <AAreaChart
                data={stats
                  .filter((s) => s.id !== -1)
                  .map((s) => ({
                    label: s.label,
                    'Users month': s.stats.userStats.usersCount || 0,
                    'Users total': s.stats.userStats.usersCumulativeCount || 0,
                    'Leads month': s.stats.userStats.leadsCount || 0,
                    'Leads total': s.stats.userStats.leadsCumulativeCount || 0,
                  }))}
                categories={[
                  'Users total',
                  'Users month',
                  'Leads total',
                  'Leads month',
                ]}
                dataKey="label"
                height="h-72"
                colors={['violet', 'stone', 'yellow', 'neutral']}
                marginTop="mt-4"
              />
            </Card>
          </div>
        </SpacedContainer>
      </SpacedContainer>
    </LoggedInPage>
  );
};
