import { updateItem } from 'api/items.api';
import { APIItem } from 'api/items.types';
import { Formik } from 'formik';
import {
  Button,
  FormControl,
  Image,
  SpacedContainer,
  Textarea,
} from 'revibe-ui';

import { PageHeading } from 'shared/components/PageHeading';
import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

type ItemPreviewProps = {
  item: APIItem;
};

export const ItemTranslationsPreview = ({ item }: ItemPreviewProps) => {
  const [isUpdating, startToUpdate, stopToUpdate] = useBoolean();
  const { errorToast, toast } = useToast();
  const itemPhotoSrc = item.photos.length && item.photos[0]?.mini_link;

  return (
    <Formik
      initialValues={{
        ...item,
        fr: item.translations.find((t) => t.localization === 'fr') || {
          description: '',
          maintenance_tips: '',
          sizes_info: '',
          materials: '',
        },
        it: item.translations.find((t) => t.localization === 'it') || {
          description: '',
          maintenance_tips: '',
          sizes_info: '',
          materials: '',
        },
      }}
      onSubmit={async (values) => {
        startToUpdate();
        const { error } = await updateItem(item.id, {
          description: values.description,
          translations: [
            {
              localization: 'fr',
              ...values.fr,
            },
            {
              localization: 'it',
              ...values.it,
            },
          ],
        });
        if (error) {
          errorToast(error);
        } else {
          toast('Item translations updated');
        }
        stopToUpdate();
      }}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <SpacedContainer>
              <div className="lg: grid grid-cols-1 gap-4 lg:grid-cols-2">
                <PageHeading>
                  {item.seller.name} - {item.name}
                </PageHeading>
                {itemPhotoSrc ? (
                  <div className="ml-auto h-32 w-32 rounded-md shadow-lg">
                    <Image alt={item.name} src={itemPhotoSrc} />
                  </div>
                ) : (
                  <div className="h-32 w-32 rounded-md">
                    <p>No Photo</p>
                  </div>
                )}
              </div>

              <h2>EN</h2>
              <SpacedContainer type="horizontal">
                <FormControl label="Description">
                  <Textarea
                    value={values.description || undefined}
                    onChange={(e) =>
                      setFieldValue('description', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Sizes info">
                  <Textarea
                    value={values.sizes_info || undefined}
                    onChange={(e) =>
                      setFieldValue('sizes_info', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Materials">
                  <Textarea
                    value={values.materials || undefined}
                    onChange={(e) =>
                      setFieldValue('materials', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Maintenance tips">
                  <Textarea
                    value={values.maintenance_tips || undefined}
                    onChange={(e) =>
                      setFieldValue('maintenance_tips', e.currentTarget.value)
                    }
                  />
                </FormControl>
              </SpacedContainer>
              <h2>FR 🇫🇷</h2>
              <SpacedContainer type="horizontal">
                <FormControl label="Description">
                  <Textarea
                    value={values.fr.description || undefined}
                    onChange={(e) =>
                      setFieldValue('fr.description', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Sizes info">
                  <Textarea
                    value={values.fr.sizes_info || undefined}
                    onChange={(e) =>
                      setFieldValue('fr.sizes_info', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Materials">
                  <Textarea
                    value={values.fr.materials || undefined}
                    onChange={(e) =>
                      setFieldValue('fr.materials', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Maintenance tips">
                  <Textarea
                    value={values.fr.maintenance_tips || undefined}
                    onChange={(e) =>
                      setFieldValue(
                        'fr.maintenance_tips',
                        e.currentTarget.value
                      )
                    }
                  />
                </FormControl>
              </SpacedContainer>
              <h2>IT 🇮🇹</h2>
              <SpacedContainer type="horizontal">
                <FormControl label="Description">
                  <Textarea
                    value={values.it.description || undefined}
                    onChange={(e) =>
                      setFieldValue('it.description', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Sizes info">
                  <Textarea
                    value={values.it.sizes_info || undefined}
                    onChange={(e) =>
                      setFieldValue('it.sizes_info', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Materials">
                  <Textarea
                    value={values.it.materials || undefined}
                    onChange={(e) =>
                      setFieldValue('it.materials', e.currentTarget.value)
                    }
                  />
                </FormControl>
                <FormControl label="Maintenance tips">
                  <Textarea
                    value={values.it.maintenance_tips || undefined}
                    onChange={(e) =>
                      setFieldValue(
                        'it.maintenance_tips',
                        e.currentTarget.value
                      )
                    }
                  />
                </FormControl>
              </SpacedContainer>
              <Button type="submit" isLoading={isUpdating}>
                Update item
              </Button>
            </SpacedContainer>
          </div>
        </form>
      )}
    </Formik>
  );
};
