import { ObjectType } from './types';

export const getDynamicUserRouteUrl = (route: string, userID: string | null) =>
  userID === null ? '/' : route.replace(':id', userID);

export const getDynamicRouteUrl = (
  route: string,
  dynamicParams: { [key: string]: string }
) => {
  let dynamicRoute = route;
  for (const key in dynamicParams) {
    dynamicRoute = dynamicRoute.replace(`:${key}`, dynamicParams[key]);
  }
  return dynamicRoute;
};

export const convertObjectToQueryParams = (obj: ObjectType<any>) => {
  let url = '';

  for (const key in obj) {
    if (
      (typeof obj[key] === 'string' ||
        typeof obj[key] === 'number' ||
        typeof obj[key] === 'boolean') &&
      obj[key] !== ''
    ) {
      url += `&${key}=${obj[key]}`;
    }
  }

  return url;
};
