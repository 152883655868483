import {
  InfoBanner,
  Link,
  SpacedContainer,
  Spinner,
  Table,
  Tag,
} from 'revibe-ui';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useToast } from 'shared/hooks';
import { AdminAPI, useQuery } from 'revibe-api';
import { formatPrice } from 'modules/catalog/utils/formatPrice';
import { ROUTES } from 'routes';
import { getDynamicRouteUrl } from 'revibe-api/src/utils/routes';
import { ExternalLinkIcon } from '@heroicons/react/outline';

export const CartsPage = () => {
  const { errorToast } = useToast();

  const [carts, { isFetching }] = useQuery('carts', AdminAPI.getCarts, {
    onError: (err) => errorToast(err || 'Error fetching carts'),
    defaultValue: [],
  });

  if (isFetching) {
    return (
      <LoggedInPage title="Carts">
        <Spinner />
      </LoggedInPage>
    );
  }

  return (
    <LoggedInPage
      title="Carts"
      hideOverflow
      breadcrumbs={[
        {
          label: 'Carts',
        },
      ]}
    >
      <SpacedContainer>
        <PageHeading>Carts</PageHeading>
        <InfoBanner>
          A cart is generated once a customer adds the first product to the
          cart. You can check the table below to track where the customers are
          in the checkout flow. Once a payment is completed, the cart is
          converted to an order.
        </InfoBanner>
        <Table
          data={carts.map((cart) => [
            <p className="whitespace-nowrap">{cart.created_at}</p>,
            cart.order_id ? (
              <Link
                to={`${getDynamicRouteUrl(ROUTES.ORDER, {
                  id: String(cart.order_id),
                })}`}
              >
                Inspect
              </Link>
            ) : (
              <p className="whitespace-nowrap italic text-gray-500">
                No order attached
              </p>
            ),
            cart.user === null || cart.delivery.address === null ? (
              <SpacedContainer>
                {cart.user === null && (
                  <Tag color={cart.user ? 'green' : 'red'}>
                    Customer information
                  </Tag>
                )}
                {cart.delivery.address === null && (
                  <Tag color="red">Delivery address</Tag>
                )}
              </SpacedContainer>
            ) : (
              <p className="italic text-gray-500">None</p>
            ),
            <p className="whitespace-nowrap">
              {formatPrice(cart.subtotal.total)} &euro;{' '}
              {cart.subtotal.total !== cart.subtotal.no_promo && (
                <span className="text-gray-400 line-through">
                  ({formatPrice(cart.subtotal.no_promo)} &euro;)
                </span>
              )}
            </p>,
            <SpacedContainer>
              {cart.items.map((item) => (
                <div
                  key={item.id}
                  className="flex w-full justify-between gap-2"
                >
                  <p className="whitespace-nowrap">
                    {item.details.seller.name} - {item.details.name}{' '}
                    <span className="italic text-gray-500">
                      ({item.amount}x)
                    </span>{' '}
                  </p>
                  <Link
                    target="_blank"
                    to={`${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}/shop/item/${item.details.slug}`}
                  >
                    <ExternalLinkIcon className="mb-1 inline w-4" />
                  </Link>
                </div>
              ))}
            </SpacedContainer>,
            cart.user ? (
              <p className="whitespace-nowrap">
                {cart.user.name} - {cart.user.email}
              </p>
            ) : (
              <p className="whitespace-nowrap italic text-gray-500">
                Guest user
              </p>
            ),
            cart.delivery.address ? (
              <p className="whitespace-nowrap">{`${cart.delivery.address.address}, ${cart.delivery.address.city} ${cart.delivery.address.postal_code}, ${cart.delivery.address.country}`}</p>
            ) : (
              <p className="whitespace-nowrap italic text-gray-500">
                No address
              </p>
            ),
            cart.promo ? (
              <p className="whitespace-nowrap">
                {cart.promo.name} - {cart.promo.discount_code}
              </p>
            ) : (
              <p className="whitespace-nowrap italic text-gray-500">
                No promo applied
              </p>
            ),
            <Link
              className="whitespace-nowrap"
              to={`https://dashboard.stripe.com/payments/${cart.payment_intent_id}`}
            >
              Go to Stripe
            </Link>,
          ])}
          columns={[
            'Created at',
            'Order',
            'Missing Information',
            'Subtotal',
            'Products',
            'User',
            'Delivery address',
            'Promo',
            'Payment',
          ]}
        />
      </SpacedContainer>
    </LoggedInPage>
  );
};
