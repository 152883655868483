import React, { useMemo } from 'react';

import { cn } from '../helpers/classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  color?: 'gray' | 'green' | 'yellow' | 'red' | 'blue' | 'purple';
  size?: 'small' | 'large';
};

export const Tag = ({
  color = 'purple',
  children,
  size = 'small',
  className,
}: Props) => {
  const sizeClasses = useMemo(() => {
    if (size === 'small') {
      return 'px-2.5 py-0.5 text-xs';
    }
    return 'px-3 py-0.5 text-sm';
  }, [size]);
  const colorClasses = useMemo(() => {
    switch (color) {
      case 'gray':
        return 'bg-gray-100 text-gray-800';
      case 'green':
        return 'bg-green-100 text-green-800';
      case 'red':
        return 'bg-red-100 text-red-800';
      case 'blue':
        return 'bg-blue-100 text-blue-800';
      case 'yellow':
        return 'bg-yellow-100 text-yellow-800';
      case 'purple':
      default:
        return 'bg-purple-100 text-purple';
    }
  }, [color]);

  return (
    <span
      className={cn(
        'inline-flex w-fit items-center whitespace-nowrap rounded-full font-medium',
        sizeClasses,
        colorClasses,
        className
      )}
    >
      {children}
    </span>
  );
};
