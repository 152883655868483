import { useEffect } from 'react';

import {
  UseMutateFunction,
  UseMutationOptions,
  UseMutationResult,
  useMutation as useMutationQueryLib,
} from '@tanstack/react-query';

import { APIResponse } from '../utils/apiCall';

export type MutationOptions<TRes> = {
  id?: string | number;
  onError?: (err: string | null) => void;
  onSuccess?: (err: TRes) => void;
} & Omit<
  UseMutationOptions<APIResponse<TRes>, any, any, any>,
  'mutationKey' | 'mutationFn' | 'onError' | 'onSuccess'
>;

export const useMutation = <TReq, TRes>(
  key: string,
  fetcher: (data: TReq) => Promise<APIResponse<TRes>>,
  { onError, onSuccess, ...options }: MutationOptions<TRes> = {}
): [
  UseMutateFunction<APIResponse<TRes>, string | null, TReq, unknown>,
  Omit<
    UseMutationResult<APIResponse<TRes>, string | null, TReq, unknown>,
    'mutate'
  >
] => {
  const { mutate, ...infos } = useMutationQueryLib<
    APIResponse<TRes>,
    string | null,
    TReq,
    unknown
  >([key], fetcher, options);

  useEffect(() => {
    if (infos.data?.data && onSuccess) {
      onSuccess(infos.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos.data]);

  useEffect(() => {
    if (!onError) {
      return;
    }
    if (infos.error) {
      onError(infos.error);
    }
    if (infos.data?.error) {
      onError(infos.data?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos.error, infos.data]);

  return [mutate, { ...infos }];
};
