import { APIMarketplaceOrder } from 'api/orders.types';
import { SpacedContainer } from 'revibe-ui';

import { ORDER_STATUS } from 'modules/orders/utils/orderStatus';
import { orderStatusToString } from 'modules/orders/utils/orderStatusToString';

type Props = { id: string; order: APIMarketplaceOrder; onUpdate: () => void };

export const OrderGroupStatusManager = ({ order }: Props) => {
  return (
    <div className="bg-gray-50 p-4">
      <h1 className="text-purple mb-6 text-lg font-bold">ORDER STATUS</h1>
      <SpacedContainer>
        <h1 className="text-purple">{orderStatusToString(order.status)}</h1>
        {order.status === ORDER_STATUS.COMPLETED && (
          <p>All good for this one. You can chill.</p>
        )}
        {order.status === ORDER_STATUS.REVIEW_COMPLETED && (
          <>
            <p>
              Every seller included in the order has finalized the{' '}
              <strong>Products availability review</strong>.
            </p>{' '}
            <p>
              The sub orders are either in delivery or awaiting a Stripe payout.
            </p>
          </>
        )}
        {order.status === ORDER_STATUS.IN_REVIEW && (
          <p>
            The customer completed the payment. Once every seller included in
            the order will finalize the{' '}
            <strong>Products availability review</strong>, the order state will
            update to confirmed.
          </p>
        )}

        {order.status === ORDER_STATUS.CANCELED && (
          <p>The order was canceled.</p>
        )}
      </SpacedContainer>
    </div>
  );
};
