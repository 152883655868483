import React from 'react';

import { cn } from '../helpers/classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  info: React.ReactNode;
  position: 'pre' | 'post';
}

export const MultiInfoField = ({
  info,
  position,
  children,
  className,
}: Props) => {
  return (
    <div className={cn('flex', className)}>
      {position === 'pre' && (
        <span className="inline-flex flex-1 items-center whitespace-nowrap border border-r-0 border-gray-300 bg-slate-50 px-3 text-gray-500 sm:text-sm">
          {info}
        </span>
      )}
      {children}
      {position === 'post' && (
        <span className="inline-flex items-center whitespace-nowrap border border-l-0 border-gray-300 bg-slate-50 px-3 text-gray-500 sm:text-sm">
          {info}
        </span>
      )}
    </div>
  );
};
