import React from 'react';

import { cn } from '../../helpers/classnames';

interface TableProps
  extends React.TableHTMLAttributes<HTMLTableSectionElement> {}

export const TableHead = React.forwardRef<HTMLTableSectionElement, TableProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <thead
        className={cn('border-b bg-slate-100 text-left', className)}
        ref={ref}
        {...props}
      >
        {children}
      </thead>
    );
  }
);
TableHead.displayName = 'TableHead';
