import { useState } from 'react';

import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { FileUploader } from 'baseui/file-uploader';
import { Image, Link, SpacedContainer } from 'revibe-ui';

import { ITEM_PHOTOS_ERRORS } from 'modules/catalog/utils/itemPhotos';

import { useToast } from 'shared/hooks';

type Props = {
  values: File[];
  onValueChanged: (values: File[]) => void;
  existingPhotosCount: number;
  validationError?: boolean;
  onResetError?: () => void;
};

export const SelectionPhotosConfigurator = ({
  values,
  onValueChanged,
  existingPhotosCount,
  validationError = false,
  onResetError = () => {},
}: Props) => {
  const [error, setError] = useState('');
  const { errorToast } = useToast();
  const [images, setImages] = useState<
    {
      name: string;
      src: string;
    }[]
  >([]);

  return (
    <SpacedContainer>
      {validationError && (
        <p className="border border-red-300 bg-red-100 p-2">
          Not enough photos
        </p>
      )}
      {error && (
        <p className="border border-red-300 bg-red-100 p-2">
          {error}
          {error === ITEM_PHOTOS_ERRORS.TOO_BIG && (
            <>
              <br />
              <br />
              <span>
                Photo size (MB) is too large, reduce them at{' '}
                <Link to="https://www.reduceimages.com/" target="_blank">
                  https://www.reduceimages.com/
                </Link>
              </span>
            </>
          )}
        </p>
      )}
      <List
        items={images.map((photo) => (
          <div
            className="mx-1 flex h-[96px] w-[96px] items-center overflow-hidden"
            key={photo.name}
          >
            <span className="relative h-[96px] w-[96px]">
              <Image src={photo.src} alt="item" />
            </span>
          </div>
        ))}
        removable
        onChange={({ oldIndex, newIndex }) => {
          if (newIndex === -1) {
            setImages(arrayRemove(images, oldIndex));
            onValueChanged(arrayRemove(values, oldIndex));
          } else {
            setImages(arrayMove(images, oldIndex, newIndex));
            onValueChanged(arrayMove(values, oldIndex, newIndex));
          }
        }}
      />
      {existingPhotosCount + images.length < 5 && (
        <FileUploader
          accept="image/png, image/jpeg"
          onDrop={(acceptedFiles) => {
            setError('');
            const filteredFilesBySize = acceptedFiles.filter((file) => {
              const sizeInMB = file.size / (1024 * 1024);
              return sizeInMB <= 1.5;
            });
            if (filteredFilesBySize.length < acceptedFiles.length) {
              setError(ITEM_PHOTOS_ERRORS.TOO_BIG);
            }
            if (
              values.length + filteredFilesBySize.length + existingPhotosCount >
              5
            ) {
              return setError(ITEM_PHOTOS_ERRORS.TOO_MANY);
            }
            onResetError();
            onValueChanged([...values, ...filteredFilesBySize]);
            filteredFilesBySize.forEach((file) => {
              const reader = new FileReader();
              reader.onabort = () => errorToast();
              reader.onerror = () => errorToast();
              reader.onload = (e) => {
                const src = e.target?.result as string;
                if (src) {
                  setImages((previousImages) => [
                    ...previousImages,
                    {
                      name: file.name,
                      src,
                    },
                  ]);
                }
              };
              reader.readAsDataURL(file);
            });
          }}
        />
      )}
    </SpacedContainer>
  );
};
