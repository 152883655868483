export const ORDER_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const SELLER_ORDER_STATUS = {
  CANCELED: 'CANCELED',
  IN_REVIEW: 'IN_REVIEW',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  PENDING_PAYOUT: 'PENDING_PAYOUT',
  PAID_OUT: 'PAID_OUT',
};

export const ITEM_ORDER_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  CANCELED: 'CANCELED',
  TO_PRODUCE: 'TO_PRODUCE',
  TO_SHIP: 'TO_SHIP',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  TO_REIMBURSE: 'TO_REIMBURSE',
  REIMBURSED: 'REIMBURSED',
};
