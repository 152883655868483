import { useState } from 'react';

import { updateSellerMainPhoto } from 'api/sellers.api';
import { APISeller } from 'api/sellers.types';
import { FileUploader } from 'baseui/file-uploader';
import { Button, Image, SpacedContainer } from 'revibe-ui';

import { useHistory, useToast } from 'shared/hooks';

export const SellerPhotoManager = ({ seller }: { seller: APISeller }) => {
  const [isEditingLogo, setIsEditingLogo] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [logo, setLogo] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const { errorToast, toast } = useToast();
  const history = useHistory();
  if (isEditingLogo) {
    return (
      <SpacedContainer>
        <h2>Seller main photo</h2>
        <SpacedContainer>
          <FileUploader
            accept="image/png, image/jpeg"
            multiple={false}
            onDrop={(acceptedFiles) => {
              setLogoPreview(null);
              const file = acceptedFiles[0];
              if (!file) {
                return errorToast();
              }
              setLogo(file);
              const reader = new FileReader();
              reader.onabort = () => errorToast();
              reader.onerror = () => errorToast();
              reader.onload = (e) => {
                const src = e.target?.result as string;
                if (src) {
                  setLogoPreview(src);
                }
              };
              reader.readAsDataURL(file);
            }}
          />
          {logoPreview && (
            <div>
              <span>
                <Image src={logoPreview} alt={seller.name} />
              </span>
            </div>
          )}
        </SpacedContainer>
        <SpacedContainer>
          <Button
            className="w-full"
            type="button"
            disabled={isUploadingLogo || logo === null}
            isLoading={isUploadingLogo}
            onClick={async () => {
              if (!logo) {
                return;
              }
              setIsUploadingLogo(true);
              const { error } = await updateSellerMainPhoto(seller.id, logo);
              if (error) {
                setIsUploadingLogo(false);
                return errorToast();
              }
              setIsUploadingLogo(false);
              setIsEditingLogo(false);
              toast('Photo uploaded!');
              history.go(0);
            }}
          >
            Upload photo
          </Button>
          <Button
            variant="subtle"
            className="w-full"
            type="button"
            onClick={() => {
              setLogo(null);
              setLogoPreview(null);
              setIsEditingLogo(false);
            }}
          >
            Cancel
          </Button>
        </SpacedContainer>
      </SpacedContainer>
    );
  }
  if (seller.mkt_image === null) {
    return (
      <Button onClick={() => setIsEditingLogo(true)}>
        {`Add seller main photo`}
      </Button>
    );
  }
  return (
    <SpacedContainer>
      <h2>Seller main photo</h2>
      <div>
        <span>
          <Image src={seller.mkt_image} alt={seller.name} />
        </span>
      </div>
      <Button onClick={() => setIsEditingLogo(true)}>Change photo</Button>
    </SpacedContainer>
  );
};
