export const ITEM_FILTERS = {
  STATUS: 'status',
  INCLUDE_OUT_OF_STOCK: 'includeOutOfStock',
  IN_PREORDER: 'inPreorder',
  SELLER: 'seller',
  SELLER_NAME: 'sellerName',
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
  SIZE: 'size',
  SEX: 'sex',
  SEARCH: 'search',
  LIST: 'list',
  CATEGORY: 'category',
  CATEGORY_NAME: 'categoryName',
  NOT_VERIFIED_ITEMS: 'notVerifiedItems',
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  ORDER: 'order',
  USER: 'userID',
  ONLY_FAVORITES: 'onlyFavorites',
  ONLY_SALES: 'onlySales',
  COLLECTION: 'collection',
  INCLUDE_UNRELEASED_COLLECTIONS: 'unreleasedCollections',
  HAS_RETOUCHED_PHOTOS: 'hasRetouchedPhotos',
};

export const ITEM_ORDER = {
  MOST_RELEVANT: 'most-relevant',
  NEW: 'new',
  HIGHEST_PRICE: 'highest-price',
  LOWEST_PRICE: 'lowest-price',
  HIGHEST_RATED: 'highest-rating',
  LOWEST_RATED: 'lowest-rating',
};
