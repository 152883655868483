import { editItemCollection } from 'api/collections.api';
import { APIItemsCollection } from 'api/collections.types';
import { Formik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { getCollection, useQuery } from 'revibe-api';
import {
  Button,
  FormControl,
  Input,
  SpacedContainer,
  Spinner,
  Tag,
  Textarea,
} from 'revibe-ui';
import { ROUTES } from 'routes';

import { ItemsTable } from 'modules/catalog/components';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { useUser } from 'modules/users/hooks';

import { LoggedInPage, Page } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory, useToast } from 'shared/hooks';

export const CollectionPage = () => {
  const { id: collectionID } = useParams<{ id: string }>();
  const history = useHistory();
  const { errorToast, toast } = useToast();
  const { user } = useUser();
  const [collection, { isLoading }] = useQuery(
    `collection-${collectionID}`,
    async () => getCollection(collectionID),
    { defaultValue: null }
  );

  const handleCollectionEditRequest = async (
    collectionData: Partial<APIItemsCollection>,
    stopFetching: () => void
  ) => {
    if (!collection) {
      stopFetching();
      return errorToast();
    }
    const { data, error } = await editItemCollection(
      collection.seller.id,
      collectionID,
      collectionData
    );
    if (data) {
      toast('Collection updated');
      stopFetching();
    } else {
      stopFetching();
      errorToast(error);
    }
  };

  if (isLoading || !collection) {
    return (
      <Page title="Collection">
        <SpacedContainer centered>
          <Spinner />
        </SpacedContainer>
      </Page>
    );
  }

  return (
    <LoggedInPage
      breadcrumbs={[
        {
          label: 'Collections',
          to: ROUTES.COLLECTIONS,
        },
        {
          label: collection.name,
        },
      ]}
      hideOverflow
      title="Collection"
    >
      <SpacedContainer>
        <Formik
          initialValues={{
            ...collection,
            fr: collection.translations.find(
              (t) => t.localization === 'fr'
            ) || {
              description: '',
              brand_description: '',
              name: '',
            },
            it: collection.translations.find(
              (t) => t.localization === 'it'
            ) || {
              description: '',
              brand_description: '',
              name: '',
            },
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await handleCollectionEditRequest(
              {
                description: values.description,
                translations: [
                  {
                    localization: 'fr',
                    ...values.fr,
                  },
                  {
                    localization: 'it',
                    ...values.it,
                  },
                ],
              },
              () => actions.setSubmitting(false)
            );
          }}
        >
          {({ setFieldValue, isSubmitting, dirty, values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <SpacedContainer>
                <PageHeading>{values.name}</PageHeading>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                >
                  Update collection
                </Button>
                <div className="flex grid-cols-2 flex-col gap-4 md:grid">
                  <div className="bg-gray-50 p-4" key={collection.id}>
                    <SpacedContainer>
                      <SpacedContainer type="horizontal">
                        <Tag
                          size="small"
                          color={collection.is_published ? 'green' : 'red'}
                        >
                          {collection.is_published
                            ? 'PUBLISHED'
                            : 'NOT PUBLISHED'}
                        </Tag>
                        {collection.collab.is_collab && (
                          <Tag size="small">
                            COLLAB WITH {collection.collab.brand_name}
                          </Tag>
                        )}
                      </SpacedContainer>
                      <div>
                        <h2>Collection name</h2>
                        <p>{collection.name}</p>
                      </div>
                      <div>
                        <h2>Seller</h2>
                        <Link
                          target="_blank"
                          to={`${
                            process.env.REACT_APP_SELLER_WEBSITE_URL
                          }/admin-session?sellerID=${
                            collection.seller.id
                          }&sellerName=${collection.seller.name}&adminEmail=${
                            user?.email || ''
                          }`}
                        >
                          {collection.seller.name}
                        </Link>
                      </div>
                      <div>
                        <h2>Release date</h2>
                        <SpacedContainer type="horizontal" centered>
                          <p>{collection.date}</p>
                          <Tag
                            size="small"
                            color={collection.is_released ? 'green' : 'red'}
                          >
                            {collection.is_released
                              ? 'RELEASED'
                              : 'NOT RELEASED'}
                          </Tag>
                        </SpacedContainer>
                      </div>
                      <div>
                        <h2>Collection description</h2>
                        <p>{collection.description}</p>
                      </div>
                      <Button
                        variant="subtle"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.pushBlank(
                            `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}/shop/collections/${collection.slug}?sellerView=true`
                          );
                        }}
                      >
                        Preview
                      </Button>
                    </SpacedContainer>
                  </div>
                  <div className="bg-gray-50 p-4">
                    <SpacedContainer>
                      <h2>Translations</h2>
                      <SpacedContainer type="horizontal">
                        <SpacedContainer>
                          <FormControl label="EN name 🇬🇧">
                            <Input
                              value={values.name || undefined}
                              onChange={(e) =>
                                setFieldValue('name', e.currentTarget.value)
                              }
                            />
                          </FormControl>
                          <FormControl label="EN Description 🇬🇧">
                            <Textarea
                              value={values.description || undefined}
                              onChange={(e) =>
                                setFieldValue(
                                  'description',
                                  e.currentTarget.value
                                )
                              }
                            />
                          </FormControl>
                          {collection.collab.is_collab && (
                            <FormControl label="EN Collab Brand Description 🇬🇧">
                              <Textarea
                                value={
                                  values.collab.brand_description || undefined
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    'collab.brand_description',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                          )}
                        </SpacedContainer>
                        <SpacedContainer>
                          <FormControl label="FR name 🇫🇷">
                            <Input
                              value={values.fr.name || undefined}
                              onChange={(e) =>
                                setFieldValue('fr.name', e.currentTarget.value)
                              }
                            />
                          </FormControl>
                          <FormControl label="FR Description 🇫🇷">
                            <Textarea
                              value={values.fr.description || undefined}
                              onChange={(e) =>
                                setFieldValue(
                                  'fr.description',
                                  e.currentTarget.value
                                )
                              }
                            />
                          </FormControl>
                          {collection.collab.is_collab && (
                            <FormControl label="FR Collab Brand Description 🇫🇷">
                              <Textarea
                                value={values.fr.brand_description || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'fr.brand_description',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                          )}
                        </SpacedContainer>
                        <SpacedContainer>
                          <FormControl label="IT name 🇮🇹">
                            <Input
                              value={values.it.name || undefined}
                              onChange={(e) =>
                                setFieldValue('it.name', e.currentTarget.value)
                              }
                            />
                          </FormControl>
                          <FormControl label="IT Description 🇮🇹">
                            <Textarea
                              value={values.it.description || undefined}
                              onChange={(e) =>
                                setFieldValue(
                                  'it.description',
                                  e.currentTarget.value
                                )
                              }
                            />
                          </FormControl>
                          {collection.collab.is_collab && (
                            <FormControl label="FR Collab Brand Description 🇮🇹">
                              <Textarea
                                value={values.it.brand_description || undefined}
                                onChange={(e) =>
                                  setFieldValue(
                                    'it.brand_description',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </FormControl>
                          )}
                        </SpacedContainer>
                      </SpacedContainer>
                    </SpacedContainer>
                  </div>
                </div>
                <div className="bg-gray-50 p-4">
                  <ItemsTable
                    filters={{
                      [ITEM_FILTERS.COLLECTION]: collection.id,
                      [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                      [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                      [ITEM_FILTERS.INCLUDE_UNRELEASED_COLLECTIONS]: true,
                    }}
                  />
                </div>
              </SpacedContainer>
            </form>
          )}
        </Formik>
      </SpacedContainer>
    </LoggedInPage>
  );
};
