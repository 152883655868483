import { APIDiscountCode, APIDiscountCodeCreation } from './marketing.types';
import { APIUserPromo, APIUserPromoCreation } from './users.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getPromotions = async () =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/promotions`,
    method: 'GET',
    reshaper: (data: APIUserPromo[]) => data,
  });

export const getAllDiscountCodes = async () =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/promotions/global`,
    method: 'GET',
    reshaper: (data: APIDiscountCode[]) => data,
  });

export const addPromotions = (promo: APIUserPromoCreation, emails: string[]) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/promotions`,
    method: 'POST',
    body: {
      ...promo,
      emails,
    },
    reshaper: (data: any) => data,
  });

export const addGlobalPromotion = async (promo: APIDiscountCodeCreation) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/promotions/global`,
    method: 'POST',
    body: promo,
    reshaper: (data: any) => data,
  });

export const updateGlobalPromotion = async (promo: APIDiscountCode) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/promotions/global/${promo.id}`,
    method: 'PUT',
    body: promo,
    reshaper: (data: any) => data,
  });

export const generateUserPromotionFromGlobalPromotion = async (
  code: string,
  email: string
) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/promotions/global/reedem`,
    method: 'POST',
    body: {
      code,
      email,
    },
    reshaper: (data: any) => data,
  });
