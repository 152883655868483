import { formatPrice } from 'modules/catalog/utils/formatPrice';
import React from 'react';

type Props = {
  price: number;
  sellingPrice?: number | null;
  shouldDisplayVAT?: boolean;
};

export const ItemPrice = ({ price, sellingPrice, shouldDisplayVAT }: Props) => {
  if (!sellingPrice || sellingPrice >= price) {
    return (
      <div className="flex w-fit flex-nowrap gap-4">
        <p className="whitespace-nowrap">&euro; {formatPrice(price)}</p>
        {shouldDisplayVAT && (
          <span className="mb-0.5 self-end text-base text-gray-400">
            VAT included
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-fit flex-nowrap gap-4">
      <p className="whitespace-nowrap">&euro; {formatPrice(sellingPrice)}</p>
      <p className="whitespace-nowrap text-gray-400 line-through">
        &euro; {formatPrice(price)}
      </p>
      {shouldDisplayVAT && (
        <span className="mb-0.5 self-end text-base text-gray-400">
          VAT included
        </span>
      )}
    </div>
  );
};
