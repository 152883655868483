import { useEffect, useState } from 'react';

import { getOrderCounters } from 'api/order.api';
import {
  APIOrderCounters,
  ItemOrderCounter,
  MarketplaceOrdersCounter,
  SellerOrderCounter,
} from 'api/orders.types';
import {
  Button,
  FormControl,
  Separator,
  SpacedContainer,
  Spinner,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tag,
} from 'revibe-ui';
import { ROUTES } from 'router';

import {
  ITEM_ORDER_STATUS,
  ORDER_STATUS,
  SELLER_ORDER_STATUS,
} from 'modules/orders/utils/orderStatus';
import {
  orderItemStatusToString,
  orderStatusToString,
  sellerOrderStatusToString,
} from 'modules/orders/utils/orderStatusToString';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';
import { useToast } from 'shared/hooks';
import { getDynamicRouteUrl } from 'shared/utils/routes';

type OrderType = 'group' | 'seller' | 'item';
type OrderStep = 'user' | 'admin' | 'seller';

type Props = {
  status: string;
  type: OrderType;
  step?: OrderStep;
  orderCounters: (
    | MarketplaceOrdersCounter
    | SellerOrderCounter
    | ItemOrderCounter
  )[];
};

const getStatusTagFromStep = (step?: OrderStep) => {
  if (step === 'user') {
    return <Tag>USER</Tag>;
  }
  if (step === 'seller') {
    return <Tag color="blue">SELLER</Tag>;
  }
  if (step === 'admin') {
    return <Tag color="yellow">ADMIN</Tag>;
  }
  return <Tag color="green">FINAL STATE</Tag>;
};

const getStatusFromType = (type: OrderType, status: string) => {
  if (type === 'seller') {
    return sellerOrderStatusToString(status);
  }
  if (type === 'group') {
    return orderStatusToString(status);
  }
  if (type === 'item') {
    return orderItemStatusToString(status);
  }
};

const OrdersListItem = ({ status, orderCounters, type, step }: Props) => {
  const history = useHistory();

  return (
    <li
      onClick={() =>
        history.push(
          `${getDynamicRouteUrl(ROUTES.ORDERS_BY_STATUS, {
            status,
          })}?type=${type}`
        )
      }
      className="block w-full cursor-pointer hover:bg-gray-50"
    >
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="text-purple truncate text-sm font-medium">
            {getStatusFromType(type, status)}
          </p>
          <div className="ml-2 flex flex-shrink-0">
            {getStatusTagFromStep(step)}
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500">
              {orderCounters.find((ogc) => ogc.status === status)?.count || 0}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export const OrdersPage = () => {
  const history = useHistory();
  const [orderCounters, setOrderCounters] = useState<APIOrderCounters | null>(
    null
  );
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { errorToast } = useToast();
  const fetchOrders = async () => {
    startFetching();
    const { data: countersData, error: countersError } =
      await getOrderCounters();
    if (countersData) {
      setOrderCounters(countersData);
    } else {
      errorToast(countersError);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <LoggedInPage title="Orders">
        <Spinner />
      </LoggedInPage>
    );
  }

  const ACTIVE_ORDERS: Props[] = orderCounters
    ? [
        {
          type: 'group',
          step: 'seller',
          status: ORDER_STATUS.IN_REVIEW,
          orderCounters: orderCounters.marketplaceOrdersCounters,
        },
        {
          type: 'group',
          step: 'admin',
          status: ORDER_STATUS.REVIEW_COMPLETED,
          orderCounters: orderCounters.marketplaceOrdersCounters,
        },
      ]
    : [];

  const COMPLETED_ORDERS: Props[] = orderCounters
    ? [
        {
          type: 'group',
          status: ORDER_STATUS.COMPLETED,
          orderCounters: orderCounters.marketplaceOrdersCounters,
        },
        {
          type: 'group',
          status: ORDER_STATUS.CANCELED,
          orderCounters: orderCounters.marketplaceOrdersCounters,
        },
      ]
    : [];

  const SELLER_ACTIVE_ORDERS: Props[] = orderCounters
    ? [
        {
          type: 'seller',
          step: 'seller',
          status: SELLER_ORDER_STATUS.IN_REVIEW,
          orderCounters: orderCounters.orderCounters,
        },
        {
          type: 'seller',
          step: 'admin',
          status: SELLER_ORDER_STATUS.REVIEW_COMPLETED,
          orderCounters: orderCounters.orderCounters,
        },
        {
          type: 'seller',
          step: 'admin',
          status: SELLER_ORDER_STATUS.DELIVERY_IN_PROGRESS,
          orderCounters: orderCounters.orderCounters,
        },
        {
          type: 'seller',
          step: 'admin',
          status: SELLER_ORDER_STATUS.PENDING_PAYOUT,
          orderCounters: orderCounters.orderCounters,
        },
      ]
    : [];

  const SELLER_COMPLETED_ORDERS: Props[] = orderCounters
    ? [
        {
          type: 'seller',
          status: SELLER_ORDER_STATUS.PAID_OUT,
          orderCounters: orderCounters.orderCounters,
        },
        {
          type: 'seller',
          status: SELLER_ORDER_STATUS.CANCELED,
          orderCounters: orderCounters.orderCounters,
        },
      ]
    : [];

  const ORDERS_ITEMS: Props[] = orderCounters
    ? [
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.IN_REVIEW,
          orderCounters: orderCounters.orderItemCounters,
          step: 'seller',
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.TO_PRODUCE,
          orderCounters: orderCounters.orderItemCounters,
          step: 'seller',
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.TO_SHIP,
          orderCounters: orderCounters.orderItemCounters,
          step: 'seller',
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.SHIPPED,
          orderCounters: orderCounters.orderItemCounters,
          step: 'admin',
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.DELIVERED,
          orderCounters: orderCounters.orderItemCounters,
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.TO_REIMBURSE,
          orderCounters: orderCounters.orderItemCounters,
          step: 'admin',
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.REIMBURSED,
          orderCounters: orderCounters.orderItemCounters,
        },
        {
          type: 'item',
          status: ITEM_ORDER_STATUS.CANCELED,
          orderCounters: orderCounters.orderItemCounters,
        },
      ]
    : [];

  return (
    <LoggedInPage title="Orders">
      <SpacedContainer>
        <PageHeading>Orders management</PageHeading>
        <Button
          variant="subtle"
          onClick={() =>
            history.push(
              getDynamicRouteUrl(ROUTES.ORDERS_BY_STATUS, {
                status: 'All',
              })
            )
          }
        >
          See all orders
        </Button>
        <Tabs defaultValue="orders">
          <TabsList>
            <TabsTrigger value="orders">Orders</TabsTrigger>
            <TabsTrigger value="orders-seller">Orders by seller</TabsTrigger>
            <TabsTrigger value="products">Products tracking</TabsTrigger>
          </TabsList>
          <TabsContent value="orders">
            <div className="bg-gray-50 p-4">
              <SpacedContainer>
                <h1>User orders</h1>
                <SpacedContainer>
                  <FormControl label="Active orders">
                    {orderCounters && (
                      <div className="overflow-hidden border border-gray-200 bg-white sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {ACTIVE_ORDERS.map((order) => (
                            <OrdersListItem key={order.status} {...order} />
                          ))}
                        </ul>
                      </div>
                    )}
                  </FormControl>
                  <FormControl label="Completed orders">
                    {orderCounters && (
                      <div className="overflow-hidden border border-gray-200 bg-white sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {COMPLETED_ORDERS.map((order) => (
                            <OrdersListItem key={order.status} {...order} />
                          ))}
                        </ul>
                      </div>
                    )}
                  </FormControl>
                </SpacedContainer>
              </SpacedContainer>
            </div>
          </TabsContent>
          <TabsContent value="orders-seller">
            <div className="bg-gray-50 p-4">
              <SpacedContainer>
                <h1>Orders by seller</h1>
                <SpacedContainer>
                  <FormControl label="Active orders">
                    {orderCounters && (
                      <div className="overflow-hidden border border-gray-200 bg-white sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {SELLER_ACTIVE_ORDERS.map((order) => (
                            <OrdersListItem key={order.status} {...order} />
                          ))}
                        </ul>
                      </div>
                    )}
                  </FormControl>
                  <FormControl label="Completed orders">
                    {orderCounters && (
                      <div className="overflow-hidden border border-gray-200 bg-white sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {SELLER_COMPLETED_ORDERS.map((order) => (
                            <OrdersListItem key={order.status} {...order} />
                          ))}
                        </ul>
                      </div>
                    )}
                  </FormControl>
                </SpacedContainer>
              </SpacedContainer>
            </div>
          </TabsContent>
          <TabsContent value="products">
            <div className="bg-gray-50 p-4">
              <SpacedContainer>
                <h1>Products tracking</h1>
                {orderCounters && (
                  <div className="overflow-hidden border border-gray-200 bg-white sm:rounded-md">
                    <ul className="divide-y divide-gray-200">
                      {ORDERS_ITEMS.map((order) => (
                        <OrdersListItem key={order.status} {...order} />
                      ))}
                    </ul>
                  </div>
                )}
              </SpacedContainer>
            </div>
          </TabsContent>
        </Tabs>

        <Separator />

        <div className="bg-gray-50 p-4">
          <SpacedContainer className="min-w-full">
            <h1>Guide</h1>
            <SpacedContainer type="horizontal" centered className="mr-auto">
              <Tag>USER</Tag>
              <p>
                The customer needs to perform an action in order for the order
                to move forward.
              </p>
            </SpacedContainer>
            <SpacedContainer type="horizontal" centered className="mr-auto">
              <Tag color="blue">SELLER</Tag>
              <p>
                The seller needs to perform an action in order for the order to
                move forward.
              </p>
            </SpacedContainer>
            <SpacedContainer type="horizontal" centered className="mr-auto">
              <Tag color="yellow">ADMIN</Tag>
              <p>
                The admin needs to perform an action in order for the order to
                move forward. This is for us!
              </p>
            </SpacedContainer>
            <SpacedContainer type="horizontal" centered className="mr-auto">
              <Tag color="green">DONE</Tag>
              <p>
                Nothing to do. Either the order is in a final state or is in a
                state not handled by Revibe (ex: in delivery).
              </p>
            </SpacedContainer>
          </SpacedContainer>
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
