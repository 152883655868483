import { MutationOptions, useMutation } from '../../../../hooks/useMutation';
import { QueryOptions, useQuery } from '../../../../hooks/useQuery';
import { apiCall } from '../../../../utils/apiCall';
import { POST } from './countries.schema';

const ROUTE = 'marketplace/delivery-fees/countries';

export type DeliveryCountry = {
  id: string;
  country_code: string;
  standard: number;
  express: number;
  free_delivery_threshold: boolean;
};

export const useMarketplaceDeliveryCountries = (
  options: QueryOptions<DeliveryCountry[]>
) =>
  useQuery(
    'get-marketplace-delivery-countries',
    async () =>
      apiCall({
        endpoint: ROUTE,
        method: 'GET',
        reshaper: (data: DeliveryCountry[]) => data,
      }),
    options
  );

export const useMarketplaceDeliveryCountryCreate = (
  options: MutationOptions<DeliveryCountry>
) =>
  useMutation(
    'create-marketplace-delivery-country',
    async ({ body }: { body: POST['body'] }) =>
      apiCall({
        endpoint: ROUTE,
        method: 'POST',
        body,
        reshaper: (data: DeliveryCountry) => data,
      }),
    options
  );
