import { useEffect, useMemo, useState } from 'react';

import { updateItem } from 'api/items.api';
import { APIItem } from 'api/items.types';
import { StarRating } from 'baseui/rating';
import {
  Button,
  Checkbox,
  Drawer,
  Image,
  Input,
  Label,
  MultiButton,
  RadioGroup,
  RadioGroupItem,
  SpacedContainer,
  Tag,
} from 'revibe-ui';
import { SHOP_ROUTES } from 'router';

import { ItemPrice } from 'modules/catalog/components';
import { getIsItemInStock } from 'modules/catalog/utils/itemStock';

import { useHistory, useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';
import { ItemTranslationsPreview } from './ItemTranslationsPreview';

type ItemPreviewProps = {
  item: APIItem;
  isSelectable?: boolean;
  isForcedSelected?: boolean;
  hideRating?: boolean;
  manageSex?: boolean;
  isDragDisabled?: boolean;
  index?: number;
  onItemSelected?: (item: APIItem) => void;
  onItemDeselected?: (item: APIItem) => void;
  onItemIndexChanged?: (oldIndex: number, newIndex: number) => void;
  onItemChange?: () => void;
};

async function downloadImage(imageSrc: string, name: string) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const ItemPreview = ({
  item,
  isSelectable = false,
  isForcedSelected = false,
  hideRating = false,
  manageSex = false,
  isDragDisabled = false,
  index,
  onItemSelected = () => {},
  onItemDeselected = () => {},
  onItemIndexChanged,
  onItemChange = () => {},
}: ItemPreviewProps) => {
  const history = useHistory();
  const { errorToast, promiseToast, toast } = useToast();
  const [i, setI] = useState(index);
  const [isSelected, setIsSelected] = useState(false);
  const [rating, setRating] = useState(item.quality_rating || 0);
  const [isTopProduct, setIsTopProduct] = useState(
    item.is_top_product || false
  );
  const [
    isTranslationsModalOpen,
    openTranslationsModal,
    closeTranslationsModal,
  ] = useBoolean();
  const itemIsInStock = getIsItemInStock(item.stock);
  const itemPhotoSrc = item.photos.length && item.photos[0]?.small_link;
  const handleToggleSelection = () => {
    if (!isSelectable) {
      return;
    }
    setIsSelected(!isSelected);
    if (!isSelected) {
      onItemSelected(item);
    } else {
      onItemDeselected(item);
    }
  };
  useEffect(() => {
    setIsSelected(isForcedSelected);
  }, [isForcedSelected]);
  useEffect(() => {
    setI(index);
  }, [index]);

  const secondaryActions = useMemo(() => {
    const actions: {
      label: string;
      onClick: () => void;
    }[] = [
      {
        label: 'Translations',
        onClick: () => openTranslationsModal(),
      },
      {
        label: 'Download images',
        onClick: () => {
          item.photos.forEach((photo, i) =>
            promiseToast(
              async () =>
                await downloadImage(photo.link, `${item.name} - ${i + 1}`),
              {
                loading: `Downloading photo #${i + 1}`,
                success: `Photo #${i + 1} downloaded`,
                error: `Error downloading photo #${i + 1}`,
              }
            )
          );
        },
      },
    ];

    if (!hideRating) {
      if (isTopProduct) {
        actions.push({
          label: 'Unmark as top product',
          onClick: async () => {
            setIsTopProduct(false);
            const { error } = await updateItem(item.id, {
              is_top_product: false,
            });
            if (error) {
              errorToast(error);
            } else {
              toast('Unmarked as top product');
            }
          },
        });
      } else {
        actions.push({
          label: 'Mark as top product',
          onClick: async () => {
            setIsTopProduct(true);
            setRating(5);
            const { error } = await updateItem(item.id, {
              is_top_product: true,
              quality_rating: 5,
            });
            if (error) {
              errorToast(error);
            } else {
              toast('Marked as top product');
            }
          },
        });
      }
    }

    return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideRating, isTopProduct, item]);

  return (
    <div className="grid grid-cols-1 bg-gray-50 p-4 sm:grid-cols-2">
      <SpacedContainer centered className="w-fit" type="horizontal">
        {isSelectable && (
          <Checkbox
            checked={isSelected}
            onCheckedChange={handleToggleSelection}
          />
        )}
        {itemPhotoSrc ? (
          <Image alt={item.name} w={64} src={itemPhotoSrc} />
        ) : (
          <div className="flex h-[64px] w-[64px] flex-col items-center justify-center bg-white">
            <p>No photos</p>
          </div>
        )}
        <SpacedContainer spacing="small">
          <div>
            <strong>{item.name}</strong>
            <p className="text-ellipsis text-sm text-gray-600">
              {item.seller.name}
            </p>
            <ItemPrice price={item.price} sellingPrice={item.selling_price} />
          </div>
          <SpacedContainer type="horizontal">
            {!itemIsInStock && !item.available_for_preorder && (
              <Tag color="red">Out of stock</Tag>
            )}
            {item.photos[0]?.is_retouched ? (
              <Tag color="green">First photo retouched</Tag>
            ) : (
              <Tag color="red">First photo not retouched</Tag>
            )}
          </SpacedContainer>
        </SpacedContainer>
      </SpacedContainer>
      {isDragDisabled ? (
        <Input
          className="justify-end"
          value={(index || 0) + 1}
          disabled
          type="number"
          pattern="[0-9]+([\.,][0-9]+)?"
          step={1}
          min={1}
          onChange={(e) => {
            if (e.currentTarget.validity.valid) {
              setI(Number(e.currentTarget.value) - 1);
            }
          }}
        />
      ) : (
        <SpacedContainer className="w-full justify-end">
          <MultiButton
            mainAction={{
              label: 'Preview',
              onClick: () => {
                history.pushBlank(
                  `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}${SHOP_ROUTES.ITEM}/${item.slug}`
                );
              },
            }}
            secondaryActions={secondaryActions}
          />
          <Drawer
            position="right"
            className="p-4"
            onOpenChange={closeTranslationsModal}
            open={isTranslationsModalOpen}
          >
            <ItemTranslationsPreview item={item} />
          </Drawer>
          <SpacedContainer centered type="horizontal">
            {!isTopProduct && !hideRating && (
              <div className="w-[128px]">
                <StarRating
                  numItems={5}
                  onChange={async (data) => {
                    setRating(data.value);
                    const { error } = await updateItem(item.id, {
                      quality_rating: data.value,
                    });
                    if (error) {
                      errorToast(error);
                    } else {
                      toast('Rating updated');
                    }
                  }}
                  size={14}
                  value={rating}
                />
              </div>
            )}
            {isTopProduct && <Tag>top product</Tag>}
            {i !== undefined &&
              index !== undefined &&
              (onItemIndexChanged ? (
                <SpacedContainer
                  centered
                  type="horizontal"
                  className="w-[96px]"
                >
                  <Input
                    value={i + 1 > 0 ? i + 1 : undefined}
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step={1}
                    min={1}
                    onChange={(e) => {
                      if (e.currentTarget.validity.valid) {
                        setI(Number(e.currentTarget.value) - 1);
                      }
                    }}
                  />
                  <Button
                    variant="subtle"
                    onClick={() => {
                      onItemIndexChanged(index, Number(i));
                    }}
                  >
                    Set position
                  </Button>
                </SpacedContainer>
              ) : (
                <Tag>{index + 1}</Tag>
              ))}
          </SpacedContainer>
          {manageSex && (
            <RadioGroup
              className="ml-auto w-64"
              defaultValue={item.sex || undefined}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  value="female"
                  id="female"
                  onClick={async () => {
                    await updateItem(item.id, {
                      sex: 'female',
                    });
                    toast('Item sex updated');
                    onItemChange();
                  }}
                />
                <Label htmlFor="female">Woman</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  value="male"
                  id="male"
                  onClick={async () => {
                    await updateItem(item.id, {
                      sex: 'male',
                    });
                    toast('Item sex updated');
                    onItemChange();
                  }}
                />
                <Label htmlFor="male">Man</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  value="unisex"
                  id="unisex"
                  onClick={async () => {
                    await updateItem(item.id, {
                      sex: 'unisex',
                    });
                    toast('Item sex updated');
                    onItemChange();
                  }}
                />
                <Label htmlFor="unisex">Unisex</Label>
              </div>
            </RadioGroup>
          )}
        </SpacedContainer>
      )}
    </div>
  );
};
