import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';

import { Button } from '../Button';

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const TablePagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: TablePaginationProps) => {
  return (
    <nav className="flex items-center justify-between px-2 py-3">
      <div className="-mt-px flex w-0 flex-1">
        <Button
          variant="ghost"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-slate-600"
            aria-hidden="true"
          />
          <div className="sr-only">Previous</div>
        </Button>
      </div>
      <div className="hidden sm:block">
        <p className="text-sm text-slate-700">
          Page <span className="font-medium">{currentPage + 1}</span> of{' '}
          <span className="font-medium">{totalPages}</span>
        </p>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <Button
          variant="ghost"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
        >
          <div className="sr-only">Next</div>
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-slate-600"
            aria-hidden="true"
          />
        </Button>
      </div>
    </nav>
  );
};
