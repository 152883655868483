import { Category } from 'revibe-api';

export const getSizeLabelFromSize = (size: string, category: Category) => {
  if (!category || category.slug !== 'shoes') {
    return size;
  }
  switch (size) {
    case 'xxs':
      return '35';
    case 'xs':
      return '36';
    case 's':
      return '37';
    case 'm':
      return '38';
    case 'l':
      return '39';
    case 'xl':
      return '40';
    case 'xxl':
      return '41';
    case '3xl':
      return '42';
    case '4xl':
      return '43';
    case '5xl':
      return '44';
    case '6xl':
      return '45';
    case '7xl':
      return '46';
    case '8xl':
      return '47';
    default:
      return 'unisize';
  }
};
