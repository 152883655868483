import { useEffect } from 'react';

import {
  UseQueryOptions,
  UseQueryResult,
  useQuery as useReactQueryLib,
} from '@tanstack/react-query';

import { APIResponse } from '../utils/apiCall';

export type QueryData<P, Q> = {
  params: P;
  query: Q;
};

export type QueryOptions<TRes> = {
  id?: string | number;
  defaultValue: TRes;
  onError?: (err: string | null) => void;
  onSuccess?: (err: TRes) => void;
} & Omit<
  UseQueryOptions<APIResponse<TRes>, any, any, any>,
  'queryKey' | 'queryFn' | 'initialData' | 'onError' | 'onSuccess'
>;

export const useQuery = <T>(
  key: string,
  fetcher: () => Promise<APIResponse<T>>,
  {
    defaultValue,
    onError,
    onSuccess,
    ...options
  }: {
    defaultValue: T;
    onError?: (err: any) => void;
    onSuccess?: (data: T) => void;
  } & Omit<
    UseQueryOptions<APIResponse<T>, any, any, any>,
    'queryKey' | 'queryFn' | 'initialData' | 'onError' | 'onSuccess'
  >
): [T, Omit<UseQueryResult, 'data'>] => {
  const { data, ...infos } = useReactQueryLib([key], fetcher, options);

  const fetchedData = data?.data || defaultValue;

  useEffect(() => {
    if (data?.data && onSuccess) {
      onSuccess(data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  useEffect(() => {
    if (!onError) {
      return;
    }
    if (infos.error) {
      onError(infos.error);
    }
    if (data?.error) {
      onError(data?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos.error, data?.error]);

  return [fetchedData, { ...infos }];
};
