import React, { useState } from 'react';

import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

import { cn } from '../helpers/classnames';

interface Props {
  className?: string;
  content: React.ReactNode;
  isOpen?: boolean;
  label: React.ReactNode;
  openClassName?: string;
}

export const Collapsible = ({
  label,
  content,
  openClassName,
  className,
  isOpen: isInitiallyOpen = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  return (
    <CollapsiblePrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
      <CollapsiblePrimitive.Trigger
        className={cn(
          'group flex w-full select-none items-center justify-between',
          className,
          isOpen && openClassName
        )}
      >
        <div className="overflow-hidden">{label}</div>
        <svg
          className={cn(
            isOpen ? 'text-purple rotate-90' : 'text-gray-300',
            'group-hover:text-purple mx-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out'
          )}
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
      </CollapsiblePrimitive.Trigger>
      <CollapsiblePrimitive.Content className="flex flex-col">
        {content}
      </CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  );
};
