import React, { useEffect, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { cn } from '../../helpers/classnames';
import { Spinner } from '../Spinner';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableContainer } from './TableContainer';
import { TableHead } from './TableHead';
import { TableHeadCell } from './TableHeadCell';
import { TablePagination } from './TablePagination';
import { TableRow } from './TableRow';

type Props = {
  className?: string;
  columns: string[];
  data: React.ReactNode[][];
  hasSortableHeadings?: boolean;
  isLoading?: boolean;
  maxRows?: number;
};

export const Table = ({
  columns,
  data,
  hasSortableHeadings = false,
  isLoading,
  maxRows,
}: Props) => {
  const [rows, setRows] = useState<React.ReactNode[][]>([]);
  const [pages, setPages] = useState<React.ReactNode[][][]>([]);
  const [sortedColumn, setSortedColumn] = useState(-1);
  const [currentPage, setCurrentPage] = useState(0);

  const splitIntoPages = (rowsToSplit: React.ReactNode[][]) => {
    if (maxRows) {
      const allData = [...rowsToSplit];
      const dataByPages: React.ReactNode[][][] = [];
      while (allData.length) {
        dataByPages.push(allData.splice(0, maxRows));
      }
      setPages(dataByPages);
    } else {
      setPages([data]);
    }
  };

  const sortByHeading = (i: number) => {
    if (i === sortedColumn) {
      setCurrentPage(0);
      splitIntoPages(data);
      setRows(data);
      setSortedColumn(-1);
      return;
    }
    const sortedRows = [...rows].sort((a, b) => {
      const first = a[i];
      const second = b[i];

      if (typeof first === 'number' && typeof second === 'number') {
        return second - first;
      }

      if (typeof first === 'string' && typeof second === 'string') {
        return first.localeCompare(second);
      }

      return 0;
    });
    setCurrentPage(0);
    setSortedColumn(i);
    setRows(sortedRows);
    splitIntoPages(sortedRows);
  };

  useEffect(() => {
    splitIntoPages(data);
    setRows(data);
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, maxRows]);

  if (isLoading) {
    return <Spinner />;
  }

  const rowsToDisplay = maxRows ? pages[currentPage] || [] : rows;

  return (
    <div>
      <TableContainer>
        <TableHead>
          <TableRow className="sticky inset-0">
            {columns.map((c, i) => (
              <TableHeadCell
                key={c + i}
                onClick={() =>
                  hasSortableHeadings ? sortByHeading(i) : undefined
                }
              >
                <p className="group inline-flex cursor-pointer">
                  {c}{' '}
                  {hasSortableHeadings && (
                    <span
                      className={cn(
                        sortedColumn !== i ? 'invisible' : 'bg-purple-100',
                        'ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible'
                      )}
                    >
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </p>
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsToDisplay.map((r, i) => (
            <TableRow key={i}>
              {r.map((e, i) => (
                <TableCell key={i}>{e}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      {maxRows && (
        <TablePagination
          currentPage={currentPage}
          totalPages={pages.length}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};
