import React from 'react';

import { cn } from '../../helpers/classnames';

interface TableProps
  extends React.TableHTMLAttributes<HTMLTableSectionElement> {}

export const TableBody = React.forwardRef<HTMLTableSectionElement, TableProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <tbody
        className={cn('divide-y overflow-x-auto align-top', className)}
        ref={ref}
        {...props}
      >
        {children}
      </tbody>
    );
  }
);
TableBody.displayName = 'TableBody';
