import { updateSeller } from 'api/sellers.api';
import { APISeller } from 'api/sellers.types';
import { Formik } from 'formik';
import { Button, FormControl, SpacedContainer, Textarea } from 'revibe-ui';

import { useToast } from 'shared/hooks';
import { useBoolean } from 'revibe-ui';

type Props = {
  seller: APISeller;
};

export const SellerTranslations = ({ seller }: Props) => {
  const [isUpdating, startToUpdate, stopToUpdate] = useBoolean();
  const { errorToast, toast } = useToast();

  return (
    <Formik
      initialValues={{
        ...seller,
        fr: seller.translations.find((t) => t.localization === 'fr') || {
          description: '',
        },
        it: seller.translations.find((t) => t.localization === 'it') || {
          description: '',
        },
      }}
      onSubmit={async (values) => {
        startToUpdate();
        const { error } = await updateSeller(seller.id, {
          description: values.description,
          translations: [
            {
              localization: 'fr',
              ...values.fr,
            },
            {
              localization: 'it',
              ...values.it,
            },
          ],
        });
        if (error) {
          errorToast(error);
        } else {
          toast('Seller translations updated');
        }
        stopToUpdate();
      }}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <SpacedContainer>
            <h2>Seller translations</h2>
            <SpacedContainer type="horizontal">
              <FormControl label="EN Description 🇬🇧">
                <Textarea
                  value={values.description || undefined}
                  onChange={(e) =>
                    setFieldValue('description', e.currentTarget.value)
                  }
                />
              </FormControl>
              <FormControl label="FR Description 🇫🇷">
                <Textarea
                  value={values.fr.description || undefined}
                  onChange={(e) =>
                    setFieldValue('fr.description', e.currentTarget.value)
                  }
                />
              </FormControl>
              <FormControl label="IT Description 🇮🇹">
                <Textarea
                  value={values.it.description || undefined}
                  onChange={(e) =>
                    setFieldValue('it.description', e.currentTarget.value)
                  }
                />
              </FormControl>
            </SpacedContainer>
            <Button type="submit" isLoading={isUpdating}>
              Update seller
            </Button>
          </SpacedContainer>
        </form>
      )}
    </Formik>
  );
};
