import { useState } from 'react';

import { APIItem } from 'api/items.types';
import { useAuthentication } from 'auth/hooks';
import { arrayRemove } from 'revibe-ui';
import { useLocation } from 'react-router-dom';
import {
  Button,
  FormControl,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  RadioGroup,
  RadioGroupItem,
  SpacedContainer,
  Tabs,
  TabsList,
  TabsTrigger,
} from 'revibe-ui';
import { ROUTES } from 'router';

import { ItemsGrid, NoItems } from 'modules/catalog/components';
import { CategorySelector } from 'modules/catalog/components/CategorySelector';
import { ItemPreview } from 'modules/catalog/components/ItemPreview';
import { ItemStatusUpdater } from 'modules/catalog/components/ItemStatusUpdater';
import { ITEM_FILTERS, ITEM_ORDER } from 'modules/catalog/utils/itemFilter';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';
import { SellerSelector } from 'modules/sellers/components/SellerSelector';

import { LoggedInPage } from 'shared/components';
import { PageHeading } from 'shared/components/PageHeading';
import { useHistory, useQueryStringParameters } from 'shared/hooks';
import { useToken } from 'shared/hooks/useToken';
import { convertObjectToQueryParams } from 'shared/utils';
import { ObjectType } from 'shared/utils/types';

import { CloseIcon } from 'ui/icons';

export const ItemsPage = () => {
  const history = useHistory();
  const { userID } = useAuthentication();
  const [selectedItems, setSelectedItems] = useState<APIItem[]>([]);
  const query = useQueryStringParameters();
  const filtersToken = useLocation().search;
  let filters: ObjectType<any> = {
    [ITEM_FILTERS.STATUS]: ITEM_STATUSES.PENDING_APPROVAL,
  };

  for (const pair of query.entries()) {
    filters[pair[0]] = pair[1];
  }

  const [resetToken, toggleResetToken] = useToken();

  const handleItemSelected = (item: APIItem) => {
    setSelectedItems([...selectedItems, item]);
  };
  const handleItemDeselected = (deselectedItem: APIItem) => {
    const index = selectedItems.findIndex(
      (item) => item.id === deselectedItem.id
    );
    if (index > -1) {
      setSelectedItems(arrayRemove(selectedItems, index));
    }
  };
  const renderItems = (items: APIItem[]) =>
    items.length ? (
      <div className="flex flex-col gap-1">
        {items.map((item) => (
          <ItemPreview
            key={item.id}
            item={item}
            onItemSelected={handleItemSelected}
            onItemDeselected={handleItemDeselected}
            isForcedSelected={selectedItems.includes(item)}
            isSelectable
            hideRating={filters[ITEM_FILTERS.STATUS] !== ITEM_STATUSES.APPROVED}
          />
        ))}
      </div>
    ) : (
      <NoItems />
    );

  if (!userID) {
    return null;
  }

  return (
    <LoggedInPage title="Items" breadcrumbs={[{ label: 'Catalog' }]}>
      <SpacedContainer>
        <PageHeading>Catalog management</PageHeading>
        <Tabs defaultValue={filters[ITEM_FILTERS.STATUS]}>
          <TabsList>
            <TabsTrigger
              onClick={() => {
                setSelectedItems([]);
                const url = `${ROUTES.ITEMS}?${convertObjectToQueryParams({
                  [ITEM_FILTERS.STATUS]: ITEM_STATUSES.PENDING_APPROVAL,
                })}`;
                history.push(url);
              }}
              value={ITEM_STATUSES.PENDING_APPROVAL}
            >
              To Approve
            </TabsTrigger>
            <TabsTrigger
              onClick={() => {
                setSelectedItems([]);
                const url = `${ROUTES.ITEMS}?${convertObjectToQueryParams({
                  [ITEM_FILTERS.STATUS]: ITEM_STATUSES.APPROVED,
                })}`;
                history.push(url);
              }}
              value={ITEM_STATUSES.APPROVED}
            >
              Approved
            </TabsTrigger>
            <TabsTrigger
              onClick={() => {
                setSelectedItems([]);
                const url = `${ROUTES.ITEMS}?${convertObjectToQueryParams({
                  [ITEM_FILTERS.STATUS]: ITEM_STATUSES.REJECTED,
                })}`;
                history.push(url);
              }}
              value={ITEM_STATUSES.REJECTED}
            >
              Rejected
            </TabsTrigger>
            <TabsTrigger
              onClick={() => {
                setSelectedItems([]);
                const url = `${ROUTES.ITEMS}?${convertObjectToQueryParams({
                  [ITEM_FILTERS.STATUS]: ITEM_STATUSES.ARCHIVED,
                })}`;
                history.push(url);
              }}
              value={ITEM_STATUSES.ARCHIVED}
            >
              Archived
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div className="w-full">
          <SpacedContainer className="relative">
            <SpacedContainer>
              <div
                className="sticky top-16 z-10 mt-8 min-w-full rounded-md border border-gray-200 bg-white p-4 lg:top-12"
                key={filtersToken + resetToken}
              >
                <SpacedContainer>
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <FormControl label="Seller">
                      <div className="flex items-center gap-2">
                        <SellerSelector
                          initialSelectedSeller={
                            filters[ITEM_FILTERS.SELLER] || null
                          }
                          onChange={(seller) => {
                            const url = `${
                              ROUTES.ITEMS
                            }?${convertObjectToQueryParams({
                              ...filters,
                              [ITEM_FILTERS.SELLER]: seller?.id,
                            })}`;
                            history.push(url);
                          }}
                        />
                        {filters[ITEM_FILTERS.SELLER] && (
                          <CloseIcon
                            onClick={() => {
                              const url = `${
                                ROUTES.ITEMS
                              }?${convertObjectToQueryParams({
                                ...filters,
                                [ITEM_FILTERS.SELLER]: undefined,
                              })}`;
                              history.push(url);
                            }}
                            className="h-5 w-5 cursor-pointer"
                          />
                        )}
                      </div>
                    </FormControl>
                    <FormControl label="Category">
                      <div className="flex items-center gap-2">
                        <CategorySelector
                          initialSelectedCategory={
                            filters[ITEM_FILTERS.CATEGORY] || null
                          }
                          onChange={(cat) => {
                            const url = `${
                              ROUTES.ITEMS
                            }?${convertObjectToQueryParams({
                              ...filters,
                              [ITEM_FILTERS.CATEGORY]: cat?.slug,
                            })}`;
                            history.push(url);
                          }}
                        />
                        {filters[ITEM_FILTERS.CATEGORY] && (
                          <CloseIcon
                            onClick={() => {
                              const url = `${
                                ROUTES.ITEMS
                              }?${convertObjectToQueryParams({
                                ...filters,
                                [ITEM_FILTERS.CATEGORY]: undefined,
                              })}`;
                              history.push(url);
                            }}
                            className="h-5 w-5 cursor-pointer"
                          />
                        )}
                      </div>
                    </FormControl>
                    {filters[ITEM_FILTERS.STATUS] ===
                      ITEM_STATUSES.APPROVED && (
                      <>
                        <FormControl label="Sorting">
                          <div className="flex items-center gap-2">
                            <Select
                              value={filters[ITEM_FILTERS.ORDER]}
                              onValueChange={(value) => {
                                const url = `${
                                  ROUTES.ITEMS
                                }?${convertObjectToQueryParams({
                                  ...filters,
                                  [ITEM_FILTERS.ORDER]: value,
                                })}`;
                                history.push(url);
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select a sorting option" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value={ITEM_ORDER.HIGHEST_RATED}>
                                  Top rated
                                </SelectItem>
                                <SelectItem value={ITEM_ORDER.LOWEST_RATED}>
                                  Worst rated
                                </SelectItem>
                              </SelectContent>
                            </Select>

                            {filters[ITEM_FILTERS.ORDER] && (
                              <CloseIcon
                                onClick={() => {
                                  const url = `${
                                    ROUTES.ITEMS
                                  }?${convertObjectToQueryParams({
                                    ...filters,
                                    [ITEM_FILTERS.ORDER]: undefined,
                                  })}`;
                                  history.push(url);
                                }}
                                className="h-5 w-5 cursor-pointer"
                              />
                            )}
                          </div>
                        </FormControl>
                        <FormControl label="Photos">
                          <RadioGroup
                            defaultValue={String(
                              filters[ITEM_FILTERS.HAS_RETOUCHED_PHOTOS]
                            )}
                          >
                            {[
                              {
                                value: undefined,
                                id: String(undefined),
                                label: 'All items',
                              },
                              {
                                value: true,
                                id: String(true),
                                label: 'With retouched photos',
                              },
                              {
                                value: false,
                                id: String(false),
                                label: 'Without retouched photos',
                              },
                            ].map((filter) => (
                              <div
                                key={filter.id}
                                className="flex items-center space-x-2"
                              >
                                <RadioGroupItem
                                  value={filter.id}
                                  id={filter.id}
                                  onClick={() => {
                                    const url = `${
                                      ROUTES.ITEMS
                                    }?${convertObjectToQueryParams({
                                      ...filters,
                                      [ITEM_FILTERS.HAS_RETOUCHED_PHOTOS]:
                                        filter.value,
                                    })}`;
                                    history.push(url);
                                  }}
                                />
                                <Label htmlFor={filter.id}>
                                  {filter.label}
                                </Label>
                              </div>
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                  </div>
                  <FormControl label={`${selectedItems.length} items selected`}>
                    <SpacedContainer type="horizontal" className="min-w-full">
                      <ItemStatusUpdater
                        onUpdate={() => {
                          setSelectedItems([]);
                          toggleResetToken();
                        }}
                        status={filters[ITEM_FILTERS.STATUS]}
                        items={selectedItems}
                      />
                      {filters[ITEM_FILTERS.STATUS] ===
                        ITEM_STATUSES.APPROVED && (
                        <Button
                          variant="subtle"
                          disabled={!filters[ITEM_FILTERS.CATEGORY]}
                          onClick={() =>
                            history.push(
                              `${
                                ROUTES.ITEMS_CATEGORY
                              }?${convertObjectToQueryParams(filters)}`
                            )
                          }
                        >
                          Manage selected category
                        </Button>
                      )}
                      <Button
                        variant="error"
                        onClick={() => setSelectedItems([])}
                        disabled={selectedItems.length === 0}
                      >
                        Clear selection
                      </Button>
                    </SpacedContainer>
                  </FormControl>
                </SpacedContainer>
              </div>
              <div className="mt-8 grid gap-1">
                <ItemsGrid
                  filters={{
                    ...filters,
                    [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                    [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                  }}
                  renderItems={renderItems}
                  pageSize={24}
                  key={filtersToken + resetToken}
                />
              </div>
            </SpacedContainer>
          </SpacedContainer>
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
