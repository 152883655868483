import { useAuthentication } from 'auth/hooks';
import { AUTH_ROUTES } from 'router';
import { ROUTES } from 'routes';

import { useHistory } from 'shared/hooks';

import { Logo } from 'ui/icons';

export const NavBar = () => {
  const history = useHistory();
  const { isLoggedIn, logout } = useAuthentication();

  return (
    <div className="mx-auto w-screen px-4 sm:px-6 lg:px-8">
      <div className="flex h-16 w-full justify-between">
        <div className="flex">
          <div className="flex flex-shrink-0 items-center">
            <Logo
              className="block h-8 w-auto cursor-pointer"
              onClick={() => history.push(ROUTES.HOME)}
            />
          </div>
        </div>
        <div className="flex items-center">
          {!isLoggedIn && (
            <a
              href={AUTH_ROUTES.LOGIN}
              className={
                'text-purple hidden rounded-md px-3 py-2 text-sm font-medium hover:bg-purple-100 lg:inline'
              }
            >
              Login
            </a>
          )}
          {isLoggedIn && (
            <a
              onClick={logout}
              href={AUTH_ROUTES.LOGIN}
              className={
                'text-purple hidden rounded-md px-3 py-2 text-sm font-medium hover:bg-purple-100 lg:inline'
              }
            >
              Logout
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
