import { Button } from 'revibe-ui';
import { ROUTES } from 'routes';

import { useHistory } from 'shared/hooks';

export const Page404 = () => {
  const history = useHistory();
  return (
    <>
      <div className="h-screen">
        <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <p className="text-purple text-4xl font-extrabold sm:text-5xl">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                    Not Found
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    The resource or page you&apos;re requesting does not exist.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Button onClick={() => history.push(ROUTES.HOME)}>
                    Go back home
                  </Button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
