'use client';

import * as React from 'react';
import * as DrawerPrimitive from '@radix-ui/react-dialog';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../helpers/classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ScrollArea } from './ScrollArea';

const portalVariants = cva('fixed inset-0 z-50 flex', {
  variants: {
    position: {
      top: 'items-start',
      bottom: 'items-end',
      left: 'justify-start',
      right: 'justify-end',
    },
  },
  defaultVariants: { position: 'right' },
});

interface DrawerPortalProps
  extends DrawerPrimitive.DialogPortalProps,
    VariantProps<typeof portalVariants> {}

const DrawerPortal = ({
  position,
  className,
  children,
  ...props
}: DrawerPortalProps) => (
  <DrawerPrimitive.Portal className={cn(className)} {...props}>
    <div className={portalVariants({ position })}>{children}</div>
  </DrawerPrimitive.Portal>
);
DrawerPortal.displayName = DrawerPrimitive.Portal.displayName;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, children: _, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    className={cn(
      'data-[state=closed]:animate-out data-[state=open]:fade-in data-[state=closed]:fade-out fixed inset-0 z-50 bg-black/50 backdrop-blur-sm transition-all duration-100',
      className
    )}
    {...props}
    ref={ref}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerVariants = cva('fixed z-50 scale-100 gap-4 bg-white opacity-100', {
  variants: {
    position: {
      top: 'animate-in slide-in-from-top w-full duration-300',
      bottom: 'animate-in slide-in-from-bottom w-full duration-300',
      left: 'animate-in slide-in-from-left h-full duration-300',
      right: 'animate-in slide-in-from-right h-full duration-300',
    },
    size: {
      content: '',
      default: '',
      sm: '',
      lg: '',
      xl: '',
      full: '',
    },
  },
  compoundVariants: [
    {
      position: ['top', 'bottom'],
      size: 'content',
      class: 'max-h-screen',
    },
    {
      position: ['top', 'bottom'],
      size: 'default',
      class: 'h-1/3',
    },
    {
      position: ['top', 'bottom'],
      size: 'sm',
      class: 'h-1/4',
    },
    {
      position: ['top', 'bottom'],
      size: 'lg',
      class: 'h-1/2',
    },
    {
      position: ['top', 'bottom'],
      size: 'xl',
      class: 'h-5/6',
    },
    {
      position: ['top', 'bottom'],
      size: 'full',
      class: 'h-screen',
    },
    {
      position: ['right', 'left'],
      size: 'content',
      class: 'max-w-screen',
    },
    {
      position: ['right', 'left'],
      size: 'default',
      class: 'w-[380px] lg:w-[512px]',
    },
    {
      position: ['right', 'left'],
      size: 'full',
      class: 'w-screen',
    },
  ],
  defaultVariants: {
    position: 'right',
    size: 'default',
  },
});

interface DialogProps
  extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Root>,
    VariantProps<typeof DrawerVariants> {
  className?: string;
  scrollable?: boolean;
}

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>,
    VariantProps<typeof DrawerVariants> {
  scrollable?: boolean;
}

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  DialogContentProps
>(
  (
    { position, size, className, children, scrollable = false, ...props },
    ref
  ) => (
    <DrawerPortal position={position}>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          DrawerVariants({ position, size }),
          'font-sans',
          className
        )}
        {...props}
      >
        {scrollable ? (
          <ScrollArea className="h-screen">{children}</ScrollArea>
        ) : (
          children
        )}
        <DrawerPrimitive.Close className="absolute top-4 right-4 rounded-full bg-slate-100 p-1 opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100">
          <XMarkIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </DrawerPrimitive.Close>
      </DrawerPrimitive.Content>
    </DrawerPortal>
  )
);
DrawerContent.displayName = DrawerPrimitive.Content.displayName;

const Drawer = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Root>,
  DialogProps
>(({ position, size, className, children, scrollable, ...props }, ref) => (
  <DrawerPrimitive.Root {...props}>
    <DrawerContent
      position={position}
      size={size}
      className={className}
      ref={ref}
      scrollable={scrollable}
    >
      {children}
    </DrawerContent>
  </DrawerPrimitive.Root>
));
Drawer.displayName = DrawerPrimitive.Root.displayName;

export { Drawer };
