import { CookiePayload, USER_AUTH } from 'auth/components/AuthProvider';

type ApiCallConfig<T> = {
  endpoint: string;
  method: string;
  reshaper: (data: any) => T;
  body?: any;
  additionalHeaders?: any;
  rawBody?: boolean;
};

export type APIResponse<T> = {
  data: T | null;
  status: number;
  error: string | null;
};

export const apiCall = async <T>({
  endpoint,
  method,
  body,
  additionalHeaders,
  reshaper,
  rawBody = false,
}: ApiCallConfig<T>): Promise<APIResponse<T>> => {
  let errorMessage = 'unknown';
  let errorSatus = 0;
  const userAsString =
    sessionStorage.getItem(USER_AUTH) || localStorage.getItem(USER_AUTH);
  const user: CookiePayload | null =
    userAsString !== null ? JSON.parse(userAsString) : null;
  const headers = {
    Authorization: 'Bearer ' + user?.token,
    ...additionalHeaders,
  };
  if (!rawBody) {
    headers['Content-Type'] = 'application/json';
  }

  try {
    const res = await fetch(endpoint, {
      method,
      body: rawBody ? body : JSON.stringify(body),
      headers,
      redirect: 'follow',
      credentials: 'include',
    });
    if (res.status === 401) {
      return {
        data: null,
        status: res.status,
        error: 'unauthorized',
      };
    }
    if (res.ok) {
      const data = await res.json();
      return {
        data: reshaper(data),
        status: res.status,
        error: null,
      };
    }
    try {
      const error: { message: string } = await res.json();
      return {
        data: null,
        status: res.status,
        error: error.message,
      };
    } catch (_) {
      return {
        data: null,
        status: res.status,
        error: errorMessage,
      };
    }
  } catch (_) {
    return {
      data: null,
      status: errorSatus,
      error: errorMessage,
    };
  }
};
