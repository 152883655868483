import React from 'react';

import { cn } from '../../helpers/classnames';

interface TableProps extends React.TableHTMLAttributes<HTMLTableCellElement> {}

export const TableHeadCell = React.forwardRef<HTMLTableCellElement, TableProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <th
        className={cn(
          'whitespace-nowrap px-4 py-3 text-sm font-semibold',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </th>
    );
  }
);
TableHeadCell.displayName = 'TableHeadCell';
