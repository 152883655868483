import React from 'react';

import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '../helpers/classnames';

const containerVariants = cva('', {
  variants: {
    type: {
      vertical: 'flex flex-col',
      horizontal: 'flex',
      fluid: 'flex flex-col md:flex-row',
    },
    spacing: {
      'x-small': 'gap-1',
      small: 'gap-2',
      medium: 'gap-4',
      large: 'gap-8',
    },
  },
  defaultVariants: {
    type: 'vertical',
    spacing: 'medium',
  },
});

interface Props extends VariantProps<typeof containerVariants> {
  centered?: boolean;
  children: React.ReactNode | JSX.Element;
  className?: string;
  fullWidth?: boolean;
}

export const SpacedContainer = ({
  children,
  className,
  type,
  spacing,
  centered = false,
  fullWidth = false,
}: Props) => {
  return (
    <div
      className={cn(
        containerVariants({ type, spacing }),
        centered ? 'items-center justify-center' : undefined,
        fullWidth ? 'w-full' : undefined,
        className
      )}
    >
      {children}
    </div>
  );
};
