import React, { createContext, useEffect, useReducer } from 'react';
import { loginAdminWithCredentials } from 'revibe-api';

import { useToast } from 'shared/hooks';

export const USER_AUTH = 'revibe-user';

type AuthContextType = {
  isLoading: boolean;
  userID: string | null;
  login: (
    email: string,
    password: string,
    permanentLogin: boolean,
    cb?: (id: string) => void
  ) => Promise<void>;
  forceLogin: (token: string, id: string) => void;
  logout: () => Promise<void>;
};
export type CookiePayload = {
  token: string;
  id: string;
};
export type AuthContextPayload = {
  isLoading: boolean;
  userID: string | null;
};

const reducer = (
  authContext: AuthContextType,
  newAuthContext: AuthContextType
): AuthContextType => {
  return { ...authContext, ...newAuthContext };
};

const userAsString =
  sessionStorage.getItem(USER_AUTH) || localStorage.getItem(USER_AUTH);
const user: CookiePayload | null =
  userAsString !== null ? JSON.parse(userAsString) : null;

const initialContext: AuthContextType = {
  isLoading: true,
  userID: user?.id || null,
  login: async () => {},
  forceLogin: () => {},
  logout: async () => {},
};

const AuthContext = createContext(initialContext);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authContext, setAuthContext] = useReducer(reducer, initialContext);
  const { errorToast, toast } = useToast();

  const setIsLoading = (isLoading: boolean) =>
    setAuthContext({
      ...authContext,
      isLoading,
    });
  const setUser = (id: string) => {
    setAuthContext({
      ...authContext,
      isLoading: false,
      userID: id,
    });
  };
  const clearUser = () => {
    sessionStorage.removeItem(USER_AUTH);
    localStorage.removeItem(USER_AUTH);
    setAuthContext({
      ...authContext,
      isLoading: false,
      userID: null,
    });
  };
  const handleError = (text: string | null) => {
    errorToast(text);
    clearUser();
  };
  const login = async (
    email: string,
    password: string,
    permanentLogin: boolean,
    cb?: (id: string) => void
  ) => {
    setIsLoading(true);
    const { data, error } = await loginAdminWithCredentials({
      email,
      password,
    });
    if (data) {
      if (permanentLogin) {
        localStorage.setItem(USER_AUTH, JSON.stringify(data));
      } else {
        sessionStorage.setItem(USER_AUTH, JSON.stringify(data));
      }
      setUser(data.id);
      toast('logged-in');
      cb && cb(data.id);
    } else {
      handleError(error);
    }
  };
  const forceLogin = (token: string, id: string) => {
    localStorage.setItem(
      USER_AUTH,
      JSON.stringify({
        token,
        id,
      })
    );
    setUser(id);
    toast('logged-in');
  };

  useEffect(() => {
    const userAsString =
      sessionStorage.getItem(USER_AUTH) || localStorage.getItem(USER_AUTH);
    const user: CookiePayload | null =
      userAsString !== null ? JSON.parse(userAsString) : null;
    if (user) {
      setUser(user.id);
    } else {
      clearUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...authContext,
        login,
        forceLogin,
        logout: async () => clearUser(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
