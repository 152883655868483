import { SUPPORTED_LOCALES } from '../i18n/locales';
import { REVIBE_API_URL } from './endpoints';

export interface ApiCallConfig
  extends Partial<{
    token?: string;
    language?: string;
    domain?: string;
  }> {}

interface ApiCallData<T> extends ApiCallConfig {
  body?: any;
  endpoint: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  rawBody?: boolean;
  reshaper: (data: any) => T;
}

export type APIResponse<T> = {
  data: T | null;
  error: string | null;
};

export const apiCall = async <T>({
  endpoint,
  domain,
  method,
  body,
  reshaper,
  rawBody = false,
  token,
  language = SUPPORTED_LOCALES.EN,
}: ApiCallData<T>): Promise<APIResponse<T>> => {
  const url = new URL(`${domain || REVIBE_API_URL}/${endpoint}`);
  if (token) {
    url.searchParams.append('token', token);
  }
  if (language) {
    url.searchParams.append('lang', language);
  }

  try {
    const res = await fetch(url.toString(), {
      method,
      body: rawBody ? body : JSON.stringify(body),
      headers: rawBody ? {} : { 'Content-Type': 'application/json' },
      redirect: 'follow',
      credentials: 'include',
    });

    if (res.ok) {
      const data = await res.json();
      return {
        data: reshaper(data),
        error: null,
      };
    }

    const error: { message: string } = await res.json();

    return {
      data: null,
      error: error.message,
    };
  } catch (err: any) {
    return {
      data: null,
      error: err || 'unknown',
    };
  }
};
