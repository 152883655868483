import { AuthProvider } from 'auth/components';
import { BaseProvider, LightTheme, ThemeProvider } from 'baseui';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { RevibeApiProvider } from 'revibe-api';
import { RevibeRouter } from 'router';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { Toaster } from 'sonner';
import { ErrorBoundary, ScrollToTop } from 'shared/components';

import { theme } from 'ui/theme';

import { store } from './redux/store';

const engine = new Styletron() as any;

export const App = () => {
  return (
    <div className="App">
      <ReduxProvider store={store}>
        <RevibeApiProvider>
          <AuthProvider>
            <StyletronProvider value={engine}>
              <BaseProvider theme={LightTheme}>
                <ThemeProvider theme={theme}>
                  <Router>
                    <ErrorBoundary>
                      <ScrollToTop>
                        <Toaster
                          position="bottom-center"
                          richColors
                          closeButton
                        />
                        <RevibeRouter />
                      </ScrollToTop>
                    </ErrorBoundary>
                  </Router>
                </ThemeProvider>
              </BaseProvider>
            </StyletronProvider>
          </AuthProvider>
        </RevibeApiProvider>
      </ReduxProvider>
    </div>
  );
};
