import React from 'react';

import { cn } from '../helpers/classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  hasDecoration?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  rel?: string;
  target?: string;
  to?: string;
};

export const Link = (props: Props) => {
  const linkProps = { ...props };
  delete linkProps.hasDecoration;

  if (props.onClick !== undefined) {
    return (
      <span
        onClick={props.onClick}
        className={cn(
          'text-purple text-sm font-medium hover:text-purple-800',
          props.className,
          props.hasDecoration && 'underline'
        )}
      >
        {props.children}
      </span>
    );
  }

  return (
    <a
      target={props.target}
      href={props.to}
      className={cn(
        'text-purple text-sm font-medium hover:text-purple-800',
        props.className,
        props.hasDecoration && 'underline'
      )}
    >
      {props.children}
    </a>
  );
};
