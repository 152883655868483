import { Button } from 'revibe-ui';

interface Props {
  heading: string;
  subheading?: React.ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
}

export const SectionHeading = ({ heading, subheading, action }: Props) => {
  return (
    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {heading}
        </h3>
        {subheading && (
          <div className="mt-2 max-w-4xl text-sm text-gray-500">
            {subheading}
          </div>
        )}
      </div>
      {action && (
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <Button onClick={action.onClick}>{action.label}</Button>
        </div>
      )}
    </div>
  );
};
