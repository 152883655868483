import { SpacedContainer } from 'revibe-ui';

type Props = {
  icon: React.ReactNode;
  heading: string;
  text: string;
  closeButton?: React.ReactNode;
};

export const NoData = ({ icon, heading, text, closeButton }: Props) => {
  return (
    <SpacedContainer
      spacing="small"
      className="h-full max-w-xl rounded-md bg-gray-50 p-4"
      centered
    >
      {icon}
      <div className="text-center">
        <h1 className="font-medium text-gray-900">{heading}</h1>
        <p className="text-xs text-gray-500">{text}</p>
      </div>
      {closeButton}
    </SpacedContainer>
  );
};
