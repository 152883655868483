import {
  Collection,
  CollectionEdit,
  CollectionPhoto,
  CollectionPhotoUpdate,
} from '../types/collections.types';
import { PaginationResponse } from '../types/pagination.types';
import { apiCall } from '../utils/apiCall';

export const getCollections = async () => {
  return await apiCall({
    endpoint: `collections`,
    method: 'GET',
    reshaper: ({ res }: PaginationResponse<Collection>) => res,
  });
};

export const getCollection = async (collectionID: string) => {
  return await apiCall({
    endpoint: `collections/${collectionID}`,
    method: 'GET',
    reshaper: (res: Collection) => res,
  });
};

export const createItemCollection = async (
  sellerID: string,
  collection: CollectionEdit
) => {
  const data = new FormData();
  const stringifiedCollection = JSON.stringify(collection);
  data.append('collection', stringifiedCollection);
  return await apiCall({
    endpoint: `sellers/${sellerID}/collections`,
    body: data,
    rawBody: true,
    method: 'POST',
    reshaper: (res: Collection) => res,
  });
};

export const editItemCollection = async (
  sellerID: string,
  collectionID: string,
  collection: CollectionEdit,
  items: string[]
) => {
  return await apiCall({
    endpoint: `sellers/${sellerID}/collections/${collectionID}`,
    body: {
      ...collection,
      items,
    },
    method: 'PUT',
    reshaper: (res: Collection) => res,
  });
};

export const addCollectionPhotos = async (
  sellerID: string,
  collectionID: string,
  photos: File[]
) => {
  const data = new FormData();
  photos.forEach((f) => data.append('files', f));
  return await apiCall({
    endpoint: `sellers/${sellerID}/collections/${collectionID}/photos`,
    method: 'POST',
    body: data,
    rawBody: true,
    reshaper: (res: CollectionPhoto[]) => res,
  });
};

export const updateCollectionPhoto = async (
  sellerID: string,
  collectionID: string,
  photoID: string,
  update: CollectionPhotoUpdate
) =>
  await apiCall({
    endpoint: `sellers/${sellerID}/collections/${collectionID}/photos/${photoID}`,
    method: 'PUT',
    body: update,
    reshaper: (res: any) => res,
  });

export const deleteCollectionPhoto = async (
  sellerID: string,
  collectionID: string,
  photoID: string
) =>
  await apiCall({
    endpoint: `sellers/${sellerID}/collections/${collectionID}/photos/${photoID}`,
    method: 'DELETE',
    reshaper: (res: any) => res,
  });
