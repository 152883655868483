import { useEffect, useState } from 'react';

import {
  generateUserPromotionFromGlobalPromotion,
  getAllDiscountCodes,
  updateGlobalPromotion,
} from 'api/marketing.api';
import { APIDiscountCode } from 'api/marketing.types';
import {
  Button,
  Input,
  Drawer,
  Separator,
  SpacedContainer,
  Table,
} from 'revibe-ui';

import { LoggedInPage } from 'shared/components';
import { SectionHeading } from 'shared/components/SectionHeading';
import { useBoolean } from 'revibe-ui';
import { useToast } from 'shared/hooks';

import { AddGlobalPromotionSection } from '../components/AddGlobalPromotionSection';

export const PromotionsPage = () => {
  const [userEmails, setUserEmails] = useState('');
  const [discountCodes, setDiscountCodes] = useState<APIDiscountCode[]>([]);
  const [selectedDiscountCode, setSelectedDiscountCode] =
    useState<APIDiscountCode | null>(null);
  const [isNewPromoDrawerOpen, openNewPromoDrawer, closeNewPromoDrawer] =
    useBoolean();
  const [isFetchingCodes, startFetchingCodes, stopFetchingCodes] = useBoolean();
  const [isUpdatingCode, startUpdatingCode, stopUpdatingCode] = useBoolean();
  const { errorToast, toast } = useToast();
  const fetchPromotions = async () => {
    startFetchingCodes();
    const { data: discountCodesData, error: discountCodesError } =
      await getAllDiscountCodes();
    if (discountCodesData) {
      setDiscountCodes(discountCodesData);
    } else {
      errorToast(discountCodesError);
    }
    stopFetchingCodes();
  };
  const addUserToPromotion = async () => {
    if (!userEmails) {
      return errorToast('Please enter at least one user email');
    }
    if (!selectedDiscountCode) {
      return;
    }
    const emails = userEmails.split(',');
    startUpdatingCode();
    const promises = await Promise.all(
      emails.map(
        async (email) =>
          await generateUserPromotionFromGlobalPromotion(
            selectedDiscountCode.code,
            email
          )
      )
    );
    const promosNotAdded = promises.filter((p) => p.data && !p.error).length;
    const promosAdded = emails.length - promosNotAdded;
    toast(`${promosNotAdded} Promos added`);
    toast(`${promosAdded} Promos not added`);
    setSelectedDiscountCode(null);
    await fetchPromotions();
    stopUpdatingCode();
  };
  const updatePromotionStatus = async (isActive: boolean) => {
    if (!selectedDiscountCode) {
      return;
    }
    startUpdatingCode();
    const { error } = await updateGlobalPromotion({
      ...selectedDiscountCode,
      is_active: isActive,
    });
    if (error) {
      errorToast(error);
    } else {
      await fetchPromotions();
    }
    setSelectedDiscountCode(null);
    stopUpdatingCode();
  };

  useEffect(() => {
    fetchPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoggedInPage title="Promotions">
      <SpacedContainer>
        <SectionHeading
          heading="Revibe promotions"
          subheading="Select a discount code to edit its status or apply it to users."
          action={{
            label: 'Create new promotion',
            onClick: openNewPromoDrawer,
          }}
        />

        <Table
          maxRows={25}
          isLoading={isFetchingCodes}
          columns={[
            'Name',
            'Code',
            'Type',
            'Amount',
            'Usage count',
            'Is Active',
            '',
          ]}
          data={discountCodes.map((discountCode) => [
            discountCode.name,
            discountCode.code,
            discountCode.type.toUpperCase(),
            discountCode.type === 'cash'
              ? `${discountCode.amount} EUR`
              : `${discountCode.amount}%`,
            discountCode.promos.length,
            discountCode.is_active ? 'YES' : 'NO',
            <Button
              variant="subtle"
              onClick={() => setSelectedDiscountCode(discountCode)}
            >
              Details
            </Button>,
          ])}
        />
        <Drawer
          scrollable
          open={selectedDiscountCode !== null}
          onOpenChange={() => setSelectedDiscountCode(null)}
        >
          {selectedDiscountCode && (
            <SpacedContainer>
              <SpacedContainer>
                <SpacedContainer spacing="small">
                  <h1>{selectedDiscountCode.name}</h1>
                  <p>Code: {selectedDiscountCode.code}</p>
                  <p>Type: {selectedDiscountCode.type.toUpperCase()}</p>
                  <p>
                    Amount:{' '}
                    {selectedDiscountCode.type === 'cash'
                      ? `${selectedDiscountCode.amount} EUR`
                      : `${selectedDiscountCode.amount}%`}
                  </p>
                  <p>Usage Count: {selectedDiscountCode.promos.length}</p>
                </SpacedContainer>
                <SpacedContainer>
                  {!selectedDiscountCode.is_active && (
                    <Button
                      disabled={isUpdatingCode}
                      isLoading={isUpdatingCode}
                      onClick={() => updatePromotionStatus(true)}
                    >
                      Reactivate code
                    </Button>
                  )}
                  {selectedDiscountCode.is_active && (
                    <Button
                      variant="error"
                      disabled={isUpdatingCode}
                      isLoading={isUpdatingCode}
                      onClick={() => updatePromotionStatus(false)}
                    >
                      Deactivate code
                    </Button>
                  )}
                  <p>
                    Add the promotion code to users' wallet. Provide the users
                    emails comma separated.
                  </p>
                  <SpacedContainer spacing="small" type="horizontal">
                    <Input
                      value={userEmails}
                      onChange={(e) => setUserEmails(e.currentTarget.value)}
                      placeholder="email1@example.com,email2@example.com,..."
                    />
                    <Button
                      onClick={addUserToPromotion}
                      disabled={isUpdatingCode}
                      isLoading={isUpdatingCode}
                    >
                      Add
                    </Button>
                  </SpacedContainer>
                </SpacedContainer>
              </SpacedContainer>
              <Separator />
              <p>Users who added the code to their wallet:</p>
              <Table
                isLoading={isFetchingCodes}
                columns={['Email', 'Is Reedemed']}
                data={selectedDiscountCode.promos.map((promo) => [
                  promo.email,
                  promo.reedemed ? 'YES' : 'NO',
                ])}
              />
            </SpacedContainer>
          )}
        </Drawer>
        <Drawer
          scrollable
          open={isNewPromoDrawerOpen}
          onOpenChange={closeNewPromoDrawer}
        >
          <AddGlobalPromotionSection />
        </Drawer>
      </SpacedContainer>
    </LoggedInPage>
  );
};
